import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import Icon from 'components/Icons';
import styles from './MultiStepQuizModal.module.scss';
import Button from '@root/components/common/Button';
import axios from 'axios';
import SelectDropdown from '@root/components/common/SelectDropdown';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import HubspotFrame from '@root/components/common/HubspotFrame';

type Props = {
    id: string;
    language: string;
    country: string;
    opened: boolean;
    attachTo?: string;
    onClose?: () => void;
};

const DEFAULT_HUBSPOT_OBJECT = {
    hubspotFormId: null,
    hubspotPortalId: null,
};

export const MultiStepQuizModal = React.forwardRef<HTMLDivElement, Props>(
    ({ id, country, language, opened, attachTo = 'body', onClose }, ref) => {
        const $modal = useRef<HTMLDivElement | null>(null);
        useEffect(() => {
            Modal.setAppElement(attachTo);
        }, [attachTo]);

        const [quiz, setQuiz] = useState(null);

        const [isLoading, setIsLoading] = useState(true);
        const [countries, setCountries] = useState([]);
        const [activeStepIndex, setActiveStepIndex] = useState(0);
        const [selectedForm, setSelectedForm] = useState(DEFAULT_HUBSPOT_OBJECT);

        const handleClose = useCallback(() => {
            if (onClose && typeof onClose === 'function') {
                onClose();
            }
        }, [onClose]);

        const [answers, setAnswers] = useState([]);

        const updateAnswer = useCallback(
            (stepIndex: number, questionIndex: number, answer: string) => {
                setAnswers(prevState =>
                    prevState.map((step, oldStepIndex) =>
                        oldStepIndex === stepIndex
                            ? step.map((question, oldQuestionIndex) =>
                                  oldQuestionIndex === questionIndex ? answer : question
                              )
                            : step
                    )
                );
            },
            []
        );

        const fetchData = useCallback(() => {
            if (id && language && country) {
                axios({
                    method: 'GET',
                    url: '/api/quiz',
                    params: {
                        id,
                        language,
                        country,
                    },
                })
                    .then(results => {
                        const quizData = results?.data ?? null;
                        setQuiz(quizData);
                        const initialAnswers = quizData?.steps?.map(({ questions }) =>
                            questions?.map(() => null)
                        );
                        setAnswers(initialAnswers);
                    })
                    .catch(error => {
                        console.warn('Failed to fetch the quiz data!');
                        console.error(error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
                axios({
                    method: 'GET',
                    url: '/api/nationalities',
                    params: { language },
                })
                    .then(results => {
                        const filtered = results?.data?.list?.map(item => ({
                            value: item?.countryCode,
                            label: item?.country,
                        }));
                        setCountries(filtered);
                    })
                    .catch(error => {
                        console.warn('Failed to fetch countries data!');
                        console.error(error);
                    });
            }
        }, [country, id, language]);

        // load data
        useEffect(() => {
            if (opened && isLoading) {
                fetchData();
            }
        }, [opened, isLoading, fetchData]);

        // reset active index and any remembered questions
        const resetData = useCallback(() => {
            setActiveStepIndex(0);
            setSelectedForm(DEFAULT_HUBSPOT_OBJECT);
            setAnswers([]);
        }, []);

        return (
            <Modal
                isOpen={opened}
                closeTimeoutMS={300}
                className={cx(styles.main, {
                    [styles['main--opened']]: opened,
                    [styles['main--closed']]: !opened,
                })}
                overlayClassName={cx(styles.overlay, {
                    [styles['overlay--opened']]: opened,
                    [styles['overlay--closed']]: !opened,
                })}
                onAfterOpen={() => {
                    if ($modal?.current) {
                        disableBodyScroll($modal.current as HTMLElement);
                    }
                }}
                onAfterClose={() => {
                    clearAllBodyScrollLocks();
                    resetData();
                }}
                contentRef={node => ($modal.current = node)}
            >
                <span
                    className={cx(styles.loader, {
                        [`${styles.loaderOpened}`]: isLoading,
                    })}
                >
                    <svg
                        width="160"
                        height="160"
                        viewBox="0 0 160 160"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M80 150C118.66 150 150 118.66 150 80C150 41.3401 118.66 10 80 10C41.3401 10 10 41.3401 10 80C10 118.66 41.3401 150 80 150ZM80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M75 5C75 2.23858 77.2386 0 80 0C124.183 0 160 35.8172 160 80C160 82.7614 157.761 85 155 85C152.239 85 150 82.7614 150 80C150 41.3401 118.66 10 80 10C77.2386 10 75 7.76142 75 5Z"
                            fill="#CD0039"
                        />
                    </svg>
                </span>
                <div
                    className={cx(styles.container, {
                        [`${styles.containerOpened}`]: !isLoading,
                    })}
                >
                    <button className={styles.btnClose} onClick={() => handleClose()}>
                        <Icon name="close" className={styles.btnIcon} />
                    </button>
                    {quiz?.steps?.map(({ stepTitle, questions }, stepIndex) => (
                        <div
                            key={`step-${stepIndex}`}
                            className={cx(styles.stepContainer, {
                                [styles.stepContainerActive]: stepIndex === activeStepIndex,
                            })}
                        >
                            <h2 className={cx('u-a4', styles.title)}>{stepTitle}</h2>
                            <div className={styles.stepContent}>
                                {questions?.map(
                                    ({ questionType, questionLabel, choices }, questionIndex) => (
                                        <div
                                            className={styles.questionContainer}
                                            key={`step-${stepIndex}-question-${questionIndex}`}
                                        >
                                            {questionLabel && (
                                                <p className={cx(styles.questionLabel, 'u-a1')}>
                                                    {questionLabel}
                                                </p>
                                            )}
                                            {questionType === 'text' && (
                                                <div className={styles.textInput}>
                                                    <input
                                                        className={'u-form__input'}
                                                        type={'text'}
                                                        id={`step-${stepIndex}-question-${questionIndex}`}
                                                        onChange={event =>
                                                            updateAnswer(
                                                                stepIndex,
                                                                questionIndex,
                                                                event.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {questionType === 'dropdown' && (
                                                <SelectDropdown
                                                    options={choices?.map(
                                                        ({ choiceLabel }, choiceIndex) => ({
                                                            id: `step-${stepIndex}-question-${questionIndex}-choice-${choiceIndex}`,
                                                            label: choiceLabel,
                                                            value: choiceLabel,
                                                        })
                                                    )}
                                                    onChange={data =>
                                                        updateAnswer(
                                                            stepIndex,
                                                            questionIndex,
                                                            data[0].value
                                                        )
                                                    }
                                                    selectedValue={
                                                        answers?.[stepIndex]?.[questionIndex] ?? ''
                                                    }
                                                    showAllInList={false}
                                                    showSearch={false}
                                                    allOptionLabel={'––'}
                                                    filled
                                                />
                                            )}
                                            {questionType === 'countryDropdown' && (
                                                <SelectDropdown
                                                    options={countries}
                                                    onChange={data =>
                                                        updateAnswer(
                                                            stepIndex,
                                                            questionIndex,
                                                            data[0].value
                                                        )
                                                    }
                                                    selectedValue={
                                                        answers?.[stepIndex]?.[questionIndex] ?? ''
                                                    }
                                                    showAllInList={false}
                                                    showSearch={true}
                                                    allOptionLabel={'––'}
                                                    filled
                                                />
                                            )}
                                            {(questionType === 'multipleChoice' ||
                                                questionType === 'scoredMultipleChoice') && (
                                                <div>
                                                    <ul className={styles.radioList}>
                                                        {choices?.map(
                                                            (
                                                                {
                                                                    choiceLabel,
                                                                    hubspotFormId,
                                                                    hubspotPortalId,
                                                                },
                                                                choiceIndex
                                                            ) => (
                                                                <li
                                                                    key={`step-${stepIndex}-question-${questionIndex}-choice-${choiceIndex}`}
                                                                >
                                                                    <div
                                                                        className={cx(
                                                                            styles.radioItem,
                                                                            {
                                                                                [`${styles.radioItemActive}`]:
                                                                                    answers?.[
                                                                                        stepIndex
                                                                                    ]?.[
                                                                                        questionIndex
                                                                                    ] ===
                                                                                    choiceLabel,
                                                                            }
                                                                        )}
                                                                    >
                                                                        <input
                                                                            type="radio"
                                                                            name={`step-${stepIndex}-question-${questionIndex}-radio`}
                                                                            id={`step-${stepIndex}-question-${questionIndex}-choice-${choiceIndex}`}
                                                                            onChange={() => {
                                                                                updateAnswer(
                                                                                    stepIndex,
                                                                                    questionIndex,
                                                                                    choiceLabel
                                                                                );
                                                                                if (
                                                                                    questionType ===
                                                                                        'scoredMultipleChoice' &&
                                                                                    hubspotFormId &&
                                                                                    hubspotPortalId
                                                                                ) {
                                                                                    setSelectedForm(
                                                                                        {
                                                                                            hubspotFormId,
                                                                                            hubspotPortalId,
                                                                                        }
                                                                                    );
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label
                                                                            className={
                                                                                styles.radioItemLabel
                                                                            }
                                                                            htmlFor={`step-${stepIndex}-question-${questionIndex}-choice-${choiceIndex}`}
                                                                        >
                                                                            <span
                                                                                className={
                                                                                    styles.radioItemBullet
                                                                                }
                                                                            />
                                                                            <span className="u-a2">
                                                                                {choiceLabel}
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )
                                )}
                            </div>
                            {quiz?.nextButtonLabel && activeStepIndex < quiz?.steps?.length && (
                                <div className={styles.nextButtonContainer}>
                                    <Button
                                        className="u-b0"
                                        disabled={
                                            !answers?.[activeStepIndex]?.every(answer =>
                                                Boolean(answer)
                                            ) ?? true
                                        }
                                        onClick={() => setActiveStepIndex(prev => prev + 1)}
                                    >
                                        {quiz?.nextButtonLabel}
                                    </Button>
                                </div>
                            )}
                        </div>
                    ))}
                    {activeStepIndex === quiz?.steps?.length && (
                        <div className={cx(styles.stepContainer, styles.stepContainerActive)}>
                            <div className={styles.stepContent}>
                                {selectedForm?.hubspotFormId && selectedForm?.hubspotPortalId && (
                                    <HubspotFrame
                                        formId={selectedForm.hubspotFormId}
                                        portalId={selectedForm.hubspotPortalId}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
);
