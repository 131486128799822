import React from 'react';
import { CountryFitline } from 'types/wordpress';
import { useAppContext } from 'context/App';
import { getMatchingCountryModel } from 'utils/getMatchingCountryModel';

type PageModel = {
    id?: string;
    slug?: string;
    options: {
        title: string;
        countries: readonly CountryFitline[];
    };
    [key: string]: any;
};

export const LocalizedPageCollection: React.FunctionComponent<{
    records: readonly PageModel[];
    render: (filteredRecords) => React.ReactElement;
}> = ({ records, render }) => {
    const { countryCode, language } = useAppContext();
    const recordsWithMatchingLocalizationData =
        records?.filter(record =>
            getMatchingCountryModel(record.options.countries, countryCode, language)
        ) || [];

    return render(recordsWithMatchingLocalizationData);
};
