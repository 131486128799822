import Template from './Template';
import Default from 'templates/Default/Default';
import Modules from 'templates/Modules/Modules';
import FAQ from 'templates/FAQ/FAQ';
import Contact from 'templates/Contact/Contact';
import PresentationalProductList from 'templates/Presentational/PresentationalProductList';
import MagazinesArchive from 'templates/Magazines/Magazines';
import AthletesArchive from 'templates/Athletes/Athletes';
import TestimonialsArchive from 'templates/Testimonials/Testimonials';
import Sport from 'templates/Sport/Sport';
import MagazineSingle from 'templates/Magazine/Magazine';
import RecipesArchive from 'templates/Recipes/Recipes';

export {
    Modules,
    Default,
    FAQ,
    Contact,
    PresentationalProductList,
    MagazinesArchive,
    AthletesArchive,
    Sport,
    MagazineSingle,
    TestimonialsArchive,
    RecipesArchive,
};

export default Template;
