import React from 'react';
import cx from 'classnames';
import { LocalizedButton } from 'context/LocalizedButton';
import { getImageSize } from 'utils/getImageSize';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import Button from 'components/common/Button';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxElement from 'components/Layout/ParallaxElement';
import styles from './BigHeadline.module.scss';

type Props = any;

export const BigHeadlineModule = React.forwardRef<HTMLDivElement, Props>(
    ({ layout, eyebrow, title, description, button, image }, ref) => (
        <Section ref={ref} modifier="" alignment="" bgColor={layout?.backgroundColor}>
            <Container type="wide">
                <div className={styles.bigHeadlineModule}>
                    {image?.sourceUrl && (
                        <div className={cx(styles.leftCol)}>
                            <figure className={cx(styles.picture)}>
                                <span
                                    className={cx(
                                        styles.pictureBg,
                                        styles[`pictureBg${layout?.imageBackground}`]
                                    )}
                                />
                                <ParallaxElement
                                    percentage={10}
                                    start={'top center'}
                                    className={cx(styles.pictureBgParallax, 'u-lazy js-lazy')}
                                    data-bg={getImageSize(
                                        image.sourceUrl,
                                        image.mediaDetails.sizes,
                                        'large'
                                    )}
                                />
                            </figure>
                        </div>
                    )}
                    <div className={cx(styles.rightCol)}>
                        <RevealElement>
                            <h2 className={cx('u-b2', styles.eyebrow)}>{eyebrow}</h2>
                        </RevealElement>
                        <RevealElement>
                            <h1 className={cx('u-a6', styles.title)}>{title}</h1>
                        </RevealElement>
                        <RevealElement>
                            <p className={cx('u-a2', styles.description)}>{description}</p>
                        </RevealElement>
                        <LocalizedButton
                            data={button}
                            render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                <RevealElement>
                                    <div className={cx(styles.btn)}>
                                        <Button
                                            to={btnUrl}
                                            target={btnTarget}
                                            tpLink={dataTp}
                                            styleType="transparent"
                                            className={cx('u-b0 u-fw-500')}
                                        >
                                            {btnLabel}
                                        </Button>
                                    </div>
                                </RevealElement>
                            )}
                        />
                    </div>
                </div>
            </Container>
        </Section>
    )
);
