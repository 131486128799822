import LazyLoad from 'vanilla-lazyload';

const reloadLazyImages = (element: string) => {
    const list = Array.from(document.querySelectorAll(element));
    if (list.length > 0) {
        list.forEach((item: HTMLElement) => {
            if (item.getAttribute('data-ll-status') === 'loaded') {
                LazyLoad.load(item, {});
            }
        });
    }
};

export const lazyLoadImages = (element: string = '.js-lazy') => {
    new LazyLoad({
        elements_selector: element,
    });
    reloadLazyImages(element);
};
