import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Moment from 'react-moment';
import Sticky from 'react-sticky-el';
import ShareBox from 'components/common/ShareBox';
import { imagesLoaded } from 'utils/imagesLoaded';
import { getImageSize } from 'utils/getImageSize';
import RevealElement from 'components/Layout/RevealElement';
import { ContentModule } from 'components/Modules/content/ContentModuleBlocks.enums';
import { ContentModuleBlocks } from 'components/Modules/content/ContentModuleBlocks';
import { GeneralModuleBlocks } from 'components/Modules/general/GeneralModuleBlocks';
import { lazyLoadImages } from 'utils/lazyLoad';
import styles from './Magazine.module.scss';

const MagazineSingle = ({
    date,
    options,
    magazineSingleContent,
    magazineCategories,
    contentModulesGroup,
    generalModulesGroup,
}) => {
    const { headlineImage: image, headlineImageCrop, titleOverride } = magazineSingleContent;

    const categoryNames = useMemo(() => {
        if (magazineCategories?.nodes?.length > 0) {
            return magazineCategories?.nodes?.map(item => item?.name)?.join(', ');
        }
        return null;
    }, [magazineCategories]);

    useEffect(() => {
        lazyLoadImages();
    }, [generalModulesGroup, contentModulesGroup, magazineSingleContent]);

    return (
        <>
            <header className={cx(styles.header)}>
                <RevealElement>
                    <div className={cx(styles.headerTop)}>
                        <div className={cx(styles.headerTopLeft)}>
                            {categoryNames && (
                                <span className={cx('u-b2', styles.category)}>{categoryNames}</span>
                            )}
                            {date && (
                                <Moment
                                    className={cx('u-b1', styles.date)}
                                    format="MMMM D, YYYY"
                                    date={date}
                                />
                            )}
                        </div>
                        <div className={cx(styles.headerTopRight)}>
                            <h1 className={cx(styles.title, 'u-a5 u-fw-700')}>
                                {magazineSingleContent?.titleoverride || options?.title}
                            </h1>
                        </div>
                    </div>
                </RevealElement>
                {(headlineImageCrop || image?.sourceUrl) && (
                    <RevealElement>
                        <figure className={cx(styles.figure)}>
                            <div className={cx(styles.figureShapeTop)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1200 120"
                                    preserveAspectRatio="none"
                                >
                                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" />
                                </svg>
                            </div>
                            <picture onLoad={imagesLoaded}>
                                {!headlineImageCrop && image?.sourceUrl && (
                                    <>
                                        <source
                                            media="(min-width:2000px)"
                                            srcSet={getImageSize(
                                                image.sourceUrl,
                                                image.mediaDetails.sizes,
                                                'custom-2400x'
                                            )}
                                        />
                                        <source
                                            media="(min-width:1600px)"
                                            srcSet={getImageSize(
                                                image.sourceUrl,
                                                image.mediaDetails.sizes,
                                                'custom-1920x'
                                            )}
                                        />
                                        <source
                                            media="(min-width:800px)"
                                            srcSet={getImageSize(
                                                image.sourceUrl,
                                                image.mediaDetails.sizes,
                                                'custom-1440x'
                                            )}
                                        />
                                        <source
                                            media="(min-width:501px)"
                                            srcSet={getImageSize(
                                                image.sourceUrl,
                                                image.mediaDetails.sizes,
                                                'custom-800x'
                                            )}
                                        />
                                        <source
                                            media="(max-width:500px)"
                                            srcSet={getImageSize(
                                                image.sourceUrl,
                                                image.mediaDetails.sizes,
                                                'custom-500x'
                                            )}
                                        />
                                    </>
                                )}

                                <img
                                    src={
                                        headlineImageCrop ||
                                        getImageSize(
                                            image?.sourceUrl,
                                            image?.mediaDetails.sizes,
                                            'custom-1920x'
                                        )
                                    }
                                    alt={image?.altText || titleOverride || options?.title || ''}
                                    onLoad={imagesLoaded}
                                />
                            </picture>
                        </figure>
                    </RevealElement>
                )}
            </header>

            <section className={cx(styles.section)}>
                <div className={cx(styles.sectionInner)}>
                    <aside className={cx(styles.share, 'shareBoxScrollArea')}>
                        <Sticky boundaryElement=".shareBoxScrollArea">
                            <div className={cx(styles.shareInner)}>
                                <ShareBox direction="vertical" />
                            </div>
                        </Sticky>
                    </aside>
                    <article className={cx(styles.article)}>
                        {contentModulesGroup?.contentModules?.map((block, index) => {
                            let fullsize = false;
                            if (block && ContentModule.MediaModule === block.__typename)
                                fullsize = true;
                            return (
                                <div
                                    key={index}
                                    className={cx(styles.articleBlock, {
                                        [`${styles.articleBlockFull}`]: fullsize,
                                    })}
                                >
                                    <RevealElement>
                                        <ContentModuleBlocks layoutData={block as unknown} />
                                    </RevealElement>
                                </div>
                            );
                        })}
                    </article>
                </div>
            </section>

            {generalModulesGroup?.modules?.map((block, index) => (
                <GeneralModuleBlocks key={index} layoutData={block as unknown} />
            ))}
        </>
    );
};

MagazineSingle.defaultProps = {
    date: null,
    options: {},
    magazineSingleContent: {},
    contentModulesGroup: {},
    generalModulesGroup: {},
};

MagazineSingle.propTypes = {
    date: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.object]),
    magazineSingleContent: PropTypes.oneOfType([PropTypes.object]),
    contentModulesGroup: PropTypes.oneOfType([PropTypes.object]),
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
};

export default MagazineSingle;
