import React from 'react';
import cx from 'classnames';
import CountUp from 'react-countup';
import { InView } from 'react-intersection-observer';
import Container from 'components/Layout/Container';
import Section from 'components/Layout/Section';
import RevealElement from 'components/Layout/RevealElement';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import styles from './Statistics.module.scss';

export type backgroundColors = 'white' | 'beige';

export type waveTopColors = 'white' | 'beige';

export type StatisticsModuleProps = any;

export const StatisticsModule = React.forwardRef<HTMLDivElement, StatisticsModuleProps>(
    ({ layout, title, description, statistics, disclaimer }, ref) => (
        <Section ref={ref} modifier="" alignment="" bgColor={layout?.backgroundColor}>
            <TopWave color={layout?.waveTopColor as TopWaveColors} />
            <Container>
                <div className={styles.statisticsModule}>
                    <div className={cx(styles.header, 'u-text-center')}>
                        <RevealElement>
                            <h2 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h2>
                        </RevealElement>
                        <RevealElement>
                            <p className={cx(styles.description, 'u-b0')}>{description}</p>
                        </RevealElement>
                    </div>
                    <div className={cx(styles.content)}>
                        {statistics.map((statistic, index) => (
                            <div className={styles.statistic} key={index}>
                                <div
                                    className={cx(styles.statisticInner, {
                                        [styles.lightBeige]: layout.backgroundColor === 'beige',
                                    })}
                                >
                                    <h4 className={cx(styles.statisticNumber, 'u-a6 u-fw-700')}>
                                        <InView>
                                            {({ inView, ref }) => (
                                                <span ref={ref}>
                                                    {inView && (
                                                        <CountUp
                                                            duration={1}
                                                            end={statistic.number}
                                                        />
                                                    )}
                                                </span>
                                            )}
                                        </InView>
                                        <span className={cx(styles.statisticUnit, 'u-a4')}>
                                            {statistic.unit}
                                        </span>
                                    </h4>
                                    <p className={cx(styles.statisticExplanation, 'u-b0')}>
                                        {statistic.explanation}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    {disclaimer && (
                        <p className={cx(styles.disclaimer, 'u-b3 u-text-right')}>{disclaimer}</p>
                    )}
                </div>
            </Container>
        </Section>
    )
);
