import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import axios from 'axios';
import Modal from 'react-modal';
import Icon from 'components/Icons';
import Button from 'components/common/Button';
import UnderlineLink from 'components/common/UnderlineLink';
import TranslationKey from 'context/TranslationKey';
import styles from './QuizProductModal.module.scss';

type Props = {
    id: string;
    opened: boolean;
    language: string;
    country: string;
    attachTo?: string;
    onClose?: () => void;
};

export const QuizProductModal = React.forwardRef<HTMLDivElement, Props>(
    ({ id, opened, language, country, attachTo = 'body', onClose }, ref) => {
        useEffect(() => {
            Modal.setAppElement(attachTo);
        }, [attachTo]);

        const [selected, setSelected] = useState(null);
        const [submitted, setSubmitted] = useState(false);
        const [isLoading, setLoading] = useState(true);

        const [quizData, setQuizData] = useState({
            items: [],
            title: '',
            button: '',
            retryBtn: '',
        });

        const handleClose = useCallback(() => {
            if (onClose && typeof onClose === 'function') {
                onClose();
            }
        }, [onClose]);

        const handleSelect = useCallback((id, answer) => {
            setSelected({
                id,
                ...answer,
            });
        }, []);

        const handleSubmit = useCallback(() => {
            if (selected?.id) {
                setSubmitted(true);
            }
        }, [selected]);

        const handleReset = useCallback(ev => {
            ev?.preventDefault();
            setSelected(null);
            setSubmitted(false);
        }, []);

        const fetchData = useCallback(() => {
            if (id && language && country) {
                axios({
                    method: 'GET',
                    url: '/api/quiz',
                    params: {
                        id,
                        language,
                        country,
                    },
                })
                    .then(results => {
                        setQuizData(
                            results?.data || { items: [], title: '', button: '', retryBtn: '' }
                        );
                    })
                    .catch(error => {
                        console.warn('Failed to fetch the quiz data!');
                        console.error(error);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        }, [id, language, country, setLoading]);

        useEffect(() => {
            if (opened && isLoading) {
                fetchData();
            }
        }, [opened, isLoading, fetchData]);

        return (
            <Modal
                isOpen={opened}
                closeTimeoutMS={300}
                className={cx(styles.main, {
                    [styles['main--opened']]: opened,
                    [styles['main--closed']]: !opened,
                })}
                overlayClassName={cx(styles.overlay, {
                    [styles['overlay--opened']]: opened,
                    [styles['overlay--closed']]: !opened,
                })}
            >
                <span
                    className={cx(styles.loader, {
                        [`${styles.loaderOpened}`]: isLoading,
                    })}
                >
                    <svg
                        width="160"
                        height="160"
                        viewBox="0 0 160 160"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M80 150C118.66 150 150 118.66 150 80C150 41.3401 118.66 10 80 10C41.3401 10 10 41.3401 10 80C10 118.66 41.3401 150 80 150ZM80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M75 5C75 2.23858 77.2386 0 80 0C124.183 0 160 35.8172 160 80C160 82.7614 157.761 85 155 85C152.239 85 150 82.7614 150 80C150 41.3401 118.66 10 80 10C77.2386 10 75 7.76142 75 5Z"
                            fill="#CD0039"
                        />
                    </svg>
                </span>
                <div
                    className={cx(styles.container, {
                        [`${styles.containerOpened}`]: !isLoading,
                    })}
                >
                    <button className={styles.btnClose} onClick={() => handleClose()}>
                        <Icon name="close" className={styles.btnIcon} />
                    </button>
                    {quizData?.items?.length > 0 ? (
                        <>
                            {!submitted && (
                                <>
                                    {quizData.title && (
                                        <h2 className={cx('u-a4', styles.title)}>
                                            {quizData.title}
                                        </h2>
                                    )}
                                    <ul className={styles.radioList}>
                                        {quizData.items.map((question, index) => (
                                            <li key={`${id}__${index}`}>
                                                <div
                                                    className={cx(styles.radioItem, {
                                                        [`${styles.radioItemActive}`]:
                                                            selected?.id === `${id}__${index}`,
                                                    })}
                                                >
                                                    <input
                                                        type="radio"
                                                        name={id}
                                                        id={`${id}__${index}`}
                                                        onChange={() =>
                                                            handleSelect(
                                                                `${id}__${index}`,
                                                                question?.answer
                                                            )
                                                        }
                                                    />
                                                    <label
                                                        className={styles.radioItemLabel}
                                                        htmlFor={`${id}__${index}`}
                                                    >
                                                        <span className={styles.radioItemBullet} />
                                                        <span className="u-a2">
                                                            {question.label}
                                                        </span>
                                                    </label>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    {quizData.button && (
                                        <Button
                                            className="u-b0"
                                            disabled={!selected?.id}
                                            onClick={() => handleSubmit()}
                                        >
                                            {quizData.button}
                                        </Button>
                                    )}
                                </>
                            )}
                            {submitted && selected?.id && (
                                <div>
                                    <h2 className={cx('u-a4', styles.title)}>{selected?.title}</h2>
                                    {selected?.product?.image && (
                                        <figure className={styles.image}>
                                            <img
                                                src={selected?.product?.image}
                                                alt={selected?.title}
                                            />
                                        </figure>
                                    )}
                                    <h3 className={cx('u-a3', styles.productTitle)}>
                                        {selected.product.title}
                                    </h3>
                                    {selected?.product?.description && (
                                        <p className={cx('u-b1', styles.productDescription)}>
                                            {selected.product.description}
                                        </p>
                                    )}
                                    <div className={styles.productLinks}>
                                        {selected?.product?.internal &&
                                            selected?.product?.external && (
                                                <ul className={styles.productButtons}>
                                                    <li>
                                                        <Button
                                                            tpLink="internal"
                                                            to={selected.product.internal}
                                                            styleType="transparent"
                                                            color="red"
                                                            buttonWidth="xsmall"
                                                            className={cx(
                                                                'u-b0',
                                                                styles.productBtnLeft
                                                            )}
                                                        >
                                                            <TranslationKey name="viewProduct" />
                                                        </Button>
                                                    </li>
                                                    <li>
                                                        <Button
                                                            tpLink="external"
                                                            target="_blank"
                                                            to={selected.product.external}
                                                            styleType="filled"
                                                            color="red"
                                                            buttonWidth="xsmall"
                                                            className={cx(
                                                                'u-b0',
                                                                styles.productBtnRight
                                                            )}
                                                        >
                                                            <TranslationKey name="shopNow" />
                                                        </Button>
                                                    </li>
                                                </ul>
                                            )}
                                        {selected?.product?.internal &&
                                            !selected?.product?.external && (
                                                <Button
                                                    tpLink="internal"
                                                    to={selected.product.internal}
                                                    styleType="transparent"
                                                    color="red"
                                                    buttonWidth="xsmall"
                                                    className="u-b0"
                                                >
                                                    <TranslationKey name="viewProduct" />
                                                </Button>
                                            )}
                                        {!selected?.product?.internal &&
                                            selected?.product?.external && (
                                                <Button
                                                    tpLink="external"
                                                    target="_blank"
                                                    to={selected.product.external}
                                                    styleType="filled"
                                                    color="red"
                                                    buttonWidth="xsmall"
                                                    className="u-b0"
                                                >
                                                    <TranslationKey name="shopNow" />
                                                </Button>
                                            )}
                                    </div>
                                    {quizData?.retryBtn && (
                                        <UnderlineLink
                                            tpLink={false}
                                            className="u-b1"
                                            onClick={ev => handleReset(ev)}
                                        >
                                            {quizData.retryBtn}
                                        </UnderlineLink>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <span>Sorry, can&apos;t fetch the quiz data!</span>
                    )}
                </div>
            </Modal>
        );
    }
);
