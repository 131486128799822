import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import cx from 'classnames';
import { gsap } from 'gsap';
import LazyLoad from 'vanilla-lazyload';
import { MediaSize } from 'types/wordpress';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import SwiperCore, { Autoplay, Controller, EffectFade, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LocalizedButton } from 'context/LocalizedButton';
import { getImageSize } from 'utils/getImageSize';
import { BottomWave, BottomWaveColors } from 'components/Layout/BottomWave/BottomWave';
import ParallaxElement from 'components/Layout/ParallaxElement';
import Button from 'components/common/Button';
import Section from 'components/Layout/Section';
import Icon from 'components/Icons';
import RevealElement from 'components/Layout/RevealElement';
import SubscriptionModal from '@root/components/common/SubscriptionModal';
import styles from './MediaHeader.module.scss';

SwiperCore.use([Controller, EffectFade, Autoplay]);

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
}

export type waveBottomColors = 'white' | 'beige';

export type MediaHeaderModuleProps = any;

export const MediaHeaderModule = React.forwardRef<HTMLDivElement, MediaHeaderModuleProps>(
    (
        {
            layout,
            eyebrow,
            title,
            description,
            button,
            modalIframe,
            mediaType,
            images,
            video,
            videoPoster,
        },
        ref
    ) => (
        <Section ref={ref} bgColor="">
            {mediaType === 'images' ? (
                <MediaHeaderImageSlider images={images} />
            ) : (
                <MediaHeaderVideo video={video} videoPoster={videoPoster} />
            )}
            <div className={styles.mediaHeader}>
                <div className={styles.content}>
                    <RevealElement>
                        <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>{eyebrow}</p>
                    </RevealElement>
                    <RevealElement>
                        <h1 className={cx(styles.title, 'u-a7 u-fw-700')}>{title}</h1>
                    </RevealElement>
                    {description && (
                        <RevealElement>
                            <p className={cx(styles.description, 'u-b0')}>{description}</p>
                        </RevealElement>
                    )}
                    {modalIframe?.button ? (
                        <SubscriptionModal
                            label={modalIframe.button}
                            src={modalIframe?.iframeSource}
                            hubspot={{
                                portalId: modalIframe?.hubspotPortalid,
                                formId: modalIframe?.hubspotFormid,
                            }}
                            render={(label, open) => (
                                <RevealElement>
                                    <div className={styles.button}>
                                        <Button
                                            onClick={() => open(true)}
                                            className={cx('u-b0', 'u-fw-500')}
                                        >
                                            {label}
                                        </Button>
                                    </div>
                                </RevealElement>
                            )}
                        />
                    ) : (
                        <LocalizedButton
                            data={button}
                            render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                <RevealElement>
                                    <div className={styles.button}>
                                        <Button
                                            to={btnUrl}
                                            target={btnTarget}
                                            tpLink={dataTp}
                                            className={cx('u-b0', 'u-fw-500')}
                                        >
                                            {btnLabel}
                                            {btnType === 'external' && btnTarget === '_blank' && (
                                                <Icon
                                                    className={styles.buttonIcon}
                                                    name="external"
                                                />
                                            )}
                                        </Button>
                                    </div>
                                </RevealElement>
                            )}
                        />
                    )}
                </div>
            </div>
            <BottomWave color={layout?.waveBottomColor as BottomWaveColors} />
        </Section>
    )
);

const MediaHeaderImageSlider = ({ images }) => {
    const imageSliderParams: SwiperOptions = useMemo(
        () => ({
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            speed: 1000,
            loop: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
        }),
        []
    );

    useEffect(() => {
        new LazyLoad({
            elements_selector: '.js-lazy',
        });
    }, []);

    return images?.length > 0 ? (
        <Swiper {...imageSliderParams} className={styles.imageSlider}>
            {images.map((image, index) => (
                <SwiperSlide key={index} className={styles.imageSliderSlide}>
                    <ParallaxElement
                        percentage={10}
                        start={'top top'}
                        className={cx(styles.sliderImage, 'u-lazy js-lazy')}
                        data-bg={getImageSize(
                            image.sourceUrl,
                            image.mediaDetails.sizes,
                            'custom-1920x'
                        )}
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    ) : null;
};

interface MediaHeaderVideoProps {
    video: string;
    videoPoster?: {
        sourceUrl: string;
        mediaDetails?: {
            sizes: readonly Pick<MediaSize, 'name' | 'sourceUrl'>[];
        };
    };
}

const MediaHeaderVideo = ({ video, videoPoster }: MediaHeaderVideoProps) => {
    const $video = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (video && $video?.current) {
            const { current: $element } = $video;
            gsap.to($element, {
                scrollTrigger: {
                    start: 'top center',
                    trigger: $element || '',
                    onEnter: () => {
                        if ($element && $element.paused) {
                            $element.play().catch(() => {
                                console.warn('MediaHeaderModule video autoplay error!');
                            });
                        }
                    },
                    onEnterBack: () => {
                        if ($element && $element.paused) {
                            $element.play().catch(() => {
                                console.warn('MediaHeaderModule video autoplay error!');
                            });
                        }
                    },
                    onLeaveBack: () => {
                        if ($element && !$element.paused) $element.pause();
                    },
                    onLeave: () => {
                        if ($element && !$element.paused) $element.pause();
                    },
                },
            });
        }
    }, [video, $video]);

    const toggleVideoPlaying = useCallback(() => {
        if ($video?.current) {
            if ($video?.current.paused) {
                $video?.current.play();
            } else {
                $video?.current.pause();
            }
        }
    }, [$video]);

    return video ? (
        <video
            loop
            ref={$video}
            preload="auto"
            playsInline
            muted
            autoPlay={false}
            poster={
                videoPoster
                    ? getImageSize(
                          videoPoster?.sourceUrl,
                          videoPoster?.mediaDetails?.sizes,
                          'custom-1920x'
                      )
                    : ''
            }
            className={styles.video}
            onClick={toggleVideoPlaying}
        >
            <source src={video} type="video/mp4" />
        </video>
    ) : null;
};
