import React from 'react';
import cx from 'classnames';
import { getImageSize } from 'utils/getImageSize';
import { getViewportSize } from 'utils/getViewportSize';
import Section from 'components/Layout/Section';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import styles from './Benefits.module.scss';

export type backgroundColors = 'white' | 'beige';

export type waveTopColors = 'white' | 'beige';

export type BenefitsModuleProps = any;

export const BenefitsModule = React.forwardRef<HTMLDivElement, BenefitsModuleProps>(
    ({ layout, eyebrow, title, benefits }, ref) => (
        <Section ref={ref} modifier="" alignment="" bgColor={layout.backgroundColor}>
            <TopWave color={layout?.waveTopColor as TopWaveColors} />
            <Container>
                <div className={styles.benefitsModule}>
                    <div className={cx(styles.header, 'u-text-center')}>
                        <RevealElement>
                            {eyebrow && (
                                <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>{eyebrow}</p>
                            )}
                        </RevealElement>
                        <RevealElement>
                            <h2 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h2>
                        </RevealElement>
                    </div>
                    <div className={cx(styles.content)}>
                        {benefits.map((benefit, index) => (
                            <div className={styles.benefit} key={index}>
                                <div className={cx(styles.benefitInner)}>
                                    <RevealElement
                                        delay={
                                            getViewportSize() <= 600 ? 0 : Number(`0.${2 * index}`)
                                        }
                                    >
                                        {benefit.image && (
                                            <div
                                                className={cx(
                                                    styles.benefitImage,
                                                    'u-lazy js-lazy'
                                                )}
                                                data-bg={getImageSize(
                                                    benefit.image.sourceUrl,
                                                    benefit.image.mediaDetails.sizes,
                                                    'medium'
                                                )}
                                            />
                                        )}
                                        <h3 className="u-a3 u-fw-700">{benefit.title}</h3>
                                    </RevealElement>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </Section>
    )
);
