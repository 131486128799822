import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { imagesLoaded } from 'utils/imagesLoaded';
import Icon from 'components/Icons';
import Section from 'components/Layout/Section';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import ParallaxElement from 'components/Layout/ParallaxElement';
import styles from './FullWidthMedia.module.scss';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
}

type Props = any;

export const FullWidthMediaModule = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            layout,
            mediaType,
            image,
            video,
            videoPoster,
            parallaxEffect = true,
            audio = false,
            autoplay,
        },
        ref
    ) => (
        <Section modifier="" alignment="" bgColor="" ref={ref} className={cx(styles.main)}>
            {layout && layout.waveTopColor !== 'transparent' && (
                <TopWave color={layout?.waveTopColor as TopWaveColors} />
            )}
            {mediaType === 'image' ? (
                <FullWidthMediaImage image={image} parallax={parallaxEffect} />
            ) : (
                <FullWidthMediaVideo
                    audio={audio}
                    video={video}
                    autoplay={autoplay}
                    videoPoster={videoPoster}
                    parallax={parallaxEffect}
                    waveColor={layout?.waveTopColor}
                />
            )}
        </Section>
    )
);

const FullWidthMediaImage = ({ image, alt = '', parallax }) =>
    image ? (
        <figure
            className={cx(styles.mediaWrapper, {
                [`${styles.noParallax}`]: !parallax,
            })}
        >
            {parallax ? (
                <ParallaxElement percentage={10} className={styles.media}>
                    <picture onLoad={imagesLoaded}>
                        <img alt={alt} src={image} onLoad={imagesLoaded} />
                    </picture>
                </ParallaxElement>
            ) : (
                <div className={styles.media}>
                    <picture onLoad={imagesLoaded}>
                        <img alt={alt} src={image} onLoad={imagesLoaded} />
                    </picture>
                </div>
            )}
        </figure>
    ) : null;

const FullWidthMediaVideo = ({ autoplay, audio, video, videoPoster = '', parallax, waveColor }) => {
    const $video = useRef<HTMLVideoElement>(null);
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [videoPaused, setVideoPaused] = useState(false);
    const [showVideoBtn, setShowVideoBtn] = useState(false);

    useEffect(() => {
        if (video && $video?.current && autoplay) {
            const { current: $element } = $video;
            gsap.to($element, {
                scrollTrigger: {
                    start: 'top bottom',
                    trigger: $element || '',
                    onEnter: () => {
                        if ($element && $element.paused) {
                            setVideoPaused(false);
                            $element.play().catch(() => {
                                setShowVideoBtn(true);
                            });
                        }
                    },
                    onEnterBack: () => {
                        if ($element && $element.paused) {
                            setVideoPaused(false);
                            $element.play().catch(() => {
                                setShowVideoBtn(true);
                            });
                        }
                    },
                    onLeaveBack: () => {
                        if ($element && !$element.paused) {
                            $element.pause();
                            setVideoPaused(true);
                        }
                    },
                    onLeave: () => {
                        if ($element && !$element.paused) {
                            $element.pause();
                            setVideoPaused(true);
                        }
                    },
                },
            });
        }
    }, [video, $video, setVideoPaused, setShowVideoBtn, autoplay]);

    useEffect(() => {
        if ($video?.current) {
            $video.current.muted = !audioPlaying;
        }
    }, [audioPlaying, $video]);

    const toggleVideoPlaying = useCallback(() => {
        if ($video?.current) {
            if ($video?.current.paused) {
                $video?.current.play();
                setVideoPaused(false);
                if (!autoplay) setAudioPlaying(true);
            } else {
                $video?.current.pause();
                setVideoPaused(true);
                if (!autoplay) setAudioPlaying(false);
            }
        }
    }, [setVideoPaused, setAudioPlaying, $video, autoplay]);

    useEffect(() => {
        if (!autoplay) {
            setVideoPaused(true);
            setShowVideoBtn(true);
        }
    }, [autoplay, setShowVideoBtn]);

    return video ? (
        <figure
            className={cx(styles.mediaWrapper, {
                [`${styles.noParallax}`]: !parallax,
            })}
        >
            {parallax ? (
                <ParallaxElement percentage={10} className={styles.media}>
                    <video
                        loop
                        ref={$video}
                        preload="auto"
                        playsInline
                        muted
                        autoPlay={autoplay}
                        poster={videoPoster || ''}
                        onClick={toggleVideoPlaying}
                    >
                        <source src={video} type="video/mp4" />
                    </video>
                    {audio && autoplay && (
                        <AudioBtn
                            waveColor={waveColor}
                            disabled={audioPlaying}
                            onClick={() => setAudioPlaying(!audioPlaying)}
                        />
                    )}
                    <div
                        className={cx(
                            styles.playBtnWrapper,
                            styles[`playBtnWrapper--${waveColor}`],
                            {
                                [`${styles.playBtnWrapperActive}`]: videoPaused && showVideoBtn,
                            }
                        )}
                    >
                        <button onClick={toggleVideoPlaying}>
                            <Icon name="play" />
                        </button>
                    </div>
                </ParallaxElement>
            ) : (
                <div className={styles.media}>
                    <video
                        loop
                        ref={$video}
                        preload="auto"
                        playsInline
                        muted
                        autoPlay={autoplay}
                        poster={videoPoster || ''}
                        onClick={toggleVideoPlaying}
                    >
                        <source src={video} type="video/mp4" />
                    </video>
                    {audio && autoplay && (
                        <AudioBtn
                            waveColor={waveColor}
                            disabled={audioPlaying}
                            onClick={() => setAudioPlaying(!audioPlaying)}
                        />
                    )}
                    <div
                        className={cx(
                            styles.playBtnWrapper,
                            styles[`playBtnWrapper--${waveColor}`],
                            {
                                [`${styles.playBtnWrapperActive}`]: videoPaused && showVideoBtn,
                            }
                        )}
                    >
                        <button onClick={toggleVideoPlaying}>
                            <Icon name="play" />
                        </button>
                    </div>
                </div>
            )}
        </figure>
    ) : null;
};

const AudioBtn = ({ onClick = null, waveColor = 'transparent', disabled }) => (
    <button
        className={cx(styles.audioBtn, styles[`audioBtn--${waveColor}`], {
            [`${styles['audioBtn--disabled']}`]: !disabled,
        })}
        onClick={() => {
            if (onClick && typeof onClick === 'function') onClick();
        }}
    >
        <Icon name="sound" />
    </button>
);
