import Icon from 'components/Icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './TestimonialModal.module.scss';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import TopWave from '@root/components/Layout/TopWave';
import CountryFlag from '../CountryFlag';
import UnderlineLink from '../UnderlineLink';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import TranslationKey from '@root/context/TranslationKey';
import ShareBox from '@root/components/common/ShareBox';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(CustomEase);
    CustomEase.create('customDefault', 'M0,0 C0.25,0.1 0.25,1 1,1');
}

interface Props {
    closeModal: Function;
    testimonialId: string;
    title: string;
    country?: {
        code: string;
        name: string;
    };
    quote: string;
    product: {
        name: string;
        sourceUrl?: string;
        url?: string;
    };
    imageUrl: string;
    video?: string;
}

export const TestimonialModal = React.forwardRef<HTMLDivElement, Props>(
    ({ title, country, quote, product, imageUrl, closeModal, testimonialId, video }, ref) => {
        const $modal = useRef<HTMLDivElement | null>(null);

        useEffect(() => {
            if ($modal?.current) {
                disableBodyScroll($modal.current.firstChild as HTMLElement);
                gsap.to($modal.current, {
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'customDefault',
                });
            }

            return () => clearAllBodyScrollLocks();
        }, [$modal]);

        const { shareTitle, shareUrl } = useMemo(() => {
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                const shareTitle = `${title} - ${document.title}`;
                const shareUrl = new URL(window.location.href);
                shareUrl.searchParams.set('testimonialId', testimonialId);

                return { shareTitle, shareUrl: shareUrl.toString() };
            } else {
                return { shareTitle: title, shareUrl: '' };
            }
        }, [title, testimonialId]);

        // region Video modal
        const $video = useRef<HTMLVideoElement>(null);
        const [videoPlaying, setVideoPlaying] = useState(false);

        const playHandler = useCallback(() => {
            setVideoPlaying(true);
        }, []);
        const pauseHandler = useCallback(() => {
            setVideoPlaying(false);
        }, []);

        useEffect(() => {
            if ($video?.current) {
                const videoElement = $video.current;
                videoPlaying ? videoElement.play() : videoElement.pause();

                videoElement.addEventListener('play', playHandler);
                videoElement.addEventListener('pause', pauseHandler);

                return () => {
                    videoElement.removeEventListener('play', playHandler);
                    videoElement.removeEventListener('pause', pauseHandler);
                };
            }
        }, [videoPlaying, $video, playHandler, pauseHandler]);

        if (video) {
            return (
                <div className={cx(styles.container, styles.isVideo)} ref={$modal}>
                    <div className={cx(styles.modal, { [styles.isVideoPlaying]: videoPlaying })}>
                        <div className={styles.header}>
                            <div>
                                <button className={styles.btnClose} onClick={ev => closeModal(ev)}>
                                    <span className={cx('u-b2 u-uppercase')}>Close</span>
                                    <Icon name="close" className={styles.btnIcon} />
                                </button>
                            </div>
                        </div>
                        <div className={styles.videoContainer}>
                            <div className={styles.videoOverlay}>
                                <div className={styles.videoText}>
                                    <p className={cx('u-b1', styles.eyebrow)}>{product.name}</p>
                                    <h2 className={cx('u-a5', styles.title)}>{title}</h2>
                                </div>
                                {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
                                <div
                                    className={styles.videoPlay}
                                    role="button"
                                    onClick={() =>
                                        setVideoPlaying(wasVideoPlaying => !wasVideoPlaying)
                                    }
                                >
                                    <Icon name={'video-play'} className={styles.videoPlay} />
                                </div>
                                <div className={styles.share}>
                                    <ShareBox
                                        direction="horizontal"
                                        transparent
                                        title={shareTitle}
                                        url={shareUrl}
                                    />
                                </div>
                            </div>
                            <video
                                ref={$video}
                                preload="auto"
                                playsInline
                                muted={false}
                                autoPlay={true}
                                controls
                                className={cx(styles.video)}
                                onClick={() => setVideoPlaying(wasVideoPlaying => !wasVideoPlaying)}
                            >
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            );
        }
        // endregion

        return (
            <div className={styles.container} ref={$modal}>
                <div className={styles.modal}>
                    <div className={styles.header}>
                        <div>
                            <button className={styles.btnClose} onClick={ev => closeModal(ev)}>
                                <span className={cx('u-b2 u-uppercase')}>Close</span>
                                <Icon name="close" className={styles.btnIcon} />
                            </button>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <TopWave color="beige" />
                        <div className={styles.share}>
                            <ShareBox
                                direction="vertical"
                                transparent
                                title={shareTitle}
                                url={shareUrl}
                            />
                        </div>
                        <div>
                            <ParallaxOvalImage
                                lazy={false}
                                parallax={false}
                                hasBorderRadius
                                parallaxBackground={imageUrl}
                            />
                        </div>
                        <div>
                            <p className={cx('u-b1', styles.eyebrow)}>{product.name}</p>
                            <h2 className={cx('u-a5', styles.title)}>{title}</h2>

                            {country && country.code && country.name && (
                                <div className={styles.country}>
                                    <CountryFlag
                                        code={country.code}
                                        name={country.name}
                                        align="default"
                                        variation="small"
                                    />
                                </div>
                            )}
                            <blockquote className={cx('u-a2', styles.quote)}>
                                <div>
                                    <Icon name="quote" className={styles.quoteIcon} />
                                </div>
                                <div>{quote}</div>
                            </blockquote>
                            <div className={styles.product}>
                                {product?.sourceUrl && (
                                    <figure className={styles.productFigure}>
                                        <img src={product?.sourceUrl} alt={product.name} />
                                    </figure>
                                )}
                                <div>
                                    <p className={cx('u-b2 u-uppercase', styles.productEyebrow)}>
                                        Product
                                    </p>
                                    <h3 className={cx('u-a1', styles.productName)}>
                                        {product.name}
                                    </h3>
                                    {product?.url && (
                                        <UnderlineLink
                                            to={product.url}
                                            tpLink="internal"
                                            className="u-b1 u-fw-500"
                                        >
                                            <TranslationKey name="shopNow" />
                                        </UnderlineLink>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
