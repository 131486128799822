import React from 'react';
import cx from 'classnames';
import { getImageSize } from 'utils/getImageSize';
import { imagesLoaded } from 'utils/imagesLoaded';
import { LocalizedButton } from 'context/LocalizedButton';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxOvalImageGroup from 'components/Layout/ParallaxOvalImageGroup';
import UnderlineLink from 'components/common/UnderlineLink';
import SubscriptionModal from '@root/components/common/SubscriptionModal';
import styles from './VerticalImageFeaturedParallax.module.scss';

export type VerticalImageFeaturedParallaxModuleProps = any;

export type backgroundColors = 'white' | 'beige';

export type alignments = 'content-left' | 'content-right';

export const VerticalImageFeaturedParallaxModule = React.forwardRef<
    HTMLDivElement,
    VerticalImageFeaturedParallaxModuleProps
>(
    (
        {
            layout,
            logos,
            eyebrow,
            title,
            description,
            button,
            modalIframe,
            visual,
            parallax,
            backgrounds,
        },
        ref
    ) => {
        const { vertical: bgVertical, horizontal: bgHorizontal } = backgrounds;
        const { topLeft: parallaxTopLeft, bottomRight: parallaxBottomRight } = parallax;

        return (
            <Section ref={ref} alignment={layout?.alignment} bgColor={layout?.backgroundColor}>
                <Container>
                    <div
                        className={cx(styles.verticalImageFeaturedParallaxModule, {
                            [styles.contentRight]: layout?.alignment === 'content-right',
                        })}
                    >
                        <div className={styles.content}>
                            <div className={styles.contentInner}>
                                {logos?.length > 0 && (
                                    <RevealElement>
                                        <ul className={cx(styles.logos)}>
                                            {logos.map((logo, logoIndex) => (
                                                <li key={`logo-${logoIndex}`}>
                                                    <img
                                                        src={logo.sourceUrl}
                                                        alt={logo?.altText || ''}
                                                        onLoad={imagesLoaded}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </RevealElement>
                                )}
                                <RevealElement>
                                    <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>
                                        {eyebrow}
                                    </p>
                                </RevealElement>
                                <RevealElement>
                                    <h3 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h3>
                                </RevealElement>
                                <RevealElement>
                                    <div
                                        className={cx(
                                            styles.description,
                                            'u-content-editor u-content-editor--module'
                                        )}
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </RevealElement>
                                {button && !modalIframe?.button && (
                                    <LocalizedButton
                                        data={button}
                                        render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                            <RevealElement>
                                                <UnderlineLink
                                                    to={btnUrl}
                                                    tpLink={dataTp}
                                                    target={btnTarget}
                                                    className="u-b1 u-fw-500"
                                                >
                                                    {btnLabel}
                                                </UnderlineLink>
                                            </RevealElement>
                                        )}
                                    />
                                )}
                                {modalIframe?.button && modalIframe?.iframeSource && (
                                    <SubscriptionModal
                                        label={modalIframe.button}
                                        src={modalIframe.iframeSource}
                                        render={(label, open) => (
                                            <RevealElement>
                                                <UnderlineLink
                                                    className="u-b1 u-fw-500"
                                                    onClick={() => open(true)}
                                                >
                                                    {label}
                                                </UnderlineLink>
                                            </RevealElement>
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={styles.images}>
                            <ParallaxOvalImageGroup
                                parallaxBackground={bgVertical}
                                parallaxBackgroundMobile={bgHorizontal}
                                parallaxInnerImage={visual}
                                parallaxImageTopLeft={getImageSize(
                                    parallaxTopLeft?.sourceUrl,
                                    parallaxTopLeft?.mediaDetails?.sizes,
                                    'medium'
                                )}
                                parallaxImageBottomRight={getImageSize(
                                    parallaxBottomRight?.sourceUrl,
                                    parallaxBottomRight?.mediaDetails?.sizes,
                                    'medium'
                                )}
                            />
                        </div>
                    </div>
                </Container>
            </Section>
        );
    }
);
