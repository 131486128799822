import React from 'react';

type Props = any;

export const TextEditorModule = React.forwardRef<HTMLDivElement, Props>(({ content }, ref) => (
    <div
        ref={ref}
        className="u-content-editor"
        dangerouslySetInnerHTML={{
            __html: content,
        }}
    />
));
