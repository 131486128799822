import React, { useCallback } from 'react';
import { MediaSize } from 'types/wordpress';
import { imagesLoaded } from 'utils/imagesLoaded';
import { getImageSize } from 'utils/getImageSize';
import styles from './Media.module.scss';

type Props = any;

export const MediaModule = React.forwardRef<HTMLDivElement, Props>(
    ({ mediaType, image, youtube }, ref) => (
        <div className={styles.main} ref={ref}>
            {mediaType === 'youtube' ? (
                <MediaVideoYT youtube={youtube} />
            ) : (
                <MediaImage image={image} />
            )}
        </div>
    )
);

const MediaVideoYT = ({ youtube }) => {
    const extractYTid = useCallback((link: string) => {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = link.match(regExp);
        return match && match[7].length === 11 ? match[7] : false;
    }, []);

    return youtube ? (
        <div className={styles.iframe}>
            <iframe
                title="youtube_video"
                src={`https://www.youtube.com/embed/${extractYTid(youtube)}`}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
        </div>
    ) : null;
};

interface MediaImageProps {
    image: {
        sourceUrl: string;
        altText?: string;
        mediaDetails?: {
            sizes: readonly Pick<MediaSize, 'name' | 'sourceUrl'>[];
        };
    };
}

const MediaImage = ({ image }: MediaImageProps) =>
    image && image?.sourceUrl ? (
        <figure className={styles.figure}>
            <picture onLoad={imagesLoaded}>
                <source
                    media="(min-width:2000px)"
                    srcSet={getImageSize(image.sourceUrl, image.mediaDetails.sizes, 'custom-2400x')}
                />
                <source
                    media="(min-width:1600px)"
                    srcSet={getImageSize(image.sourceUrl, image.mediaDetails.sizes, 'custom-1920x')}
                />
                <source
                    media="(min-width:800px)"
                    srcSet={getImageSize(image.sourceUrl, image.mediaDetails.sizes, 'custom-1440x')}
                />
                <source
                    media="(min-width:501px)"
                    srcSet={getImageSize(image.sourceUrl, image.mediaDetails.sizes, 'custom-800x')}
                />
                <source
                    media="(max-width:500px)"
                    srcSet={getImageSize(image.sourceUrl, image.mediaDetails.sizes, 'custom-500x')}
                />
                <img
                    src={getImageSize(image.sourceUrl, image.mediaDetails.sizes, 'custom-1920x')}
                    alt={image.altText || ''}
                    onLoad={imagesLoaded}
                />
            </picture>
        </figure>
    ) : null;
