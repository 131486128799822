import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import SwiperCore, { A11y, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import Icon from 'components/Icons';
import ArrowButton from 'components/common/ArrowButton';
import VideoModal from 'components/common/VideoModal';
import styles from './VerticalImageTestimonialDoubleSlider.module.scss';

SwiperCore.use([A11y]);

export type backgroundColors = 'white' | 'beige';

export type alignments = 'content-left' | 'content-right';

export type waveTopColors = 'white' | 'beige';

export type VerticalImageTestimonialDoubleSliderModuleProps = any;

export const VerticalImageTestimonialDoubleSliderModule = React.forwardRef<
    HTMLDivElement,
    VerticalImageTestimonialDoubleSliderModuleProps
>(({ layout, eyebrow, title, testimonials, ovalImages = true }, ref) => {
    const [testimonialSlider, setTestimonialSlider] = useState<SwiperCore>();
    const [imageSlider, setImageSlider] = useState<SwiperCore>();

    const testimonialSliderParams: SwiperOptions = {
        loop: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
    };

    const imageSliderParams: SwiperOptions = {
        effect: 'fade',
        loop: true,
        fadeEffect: {
            crossFade: true,
        },
    };

    const nextSlide = useCallback(() => {
        if (testimonialSlider) testimonialSlider.slideNext();
    }, [testimonialSlider]);

    const prevSlide = useCallback(() => {
        if (testimonialSlider) testimonialSlider.slidePrev();
    }, [testimonialSlider]);

    return (
        <Section ref={ref} alignment="" bgColor={layout.backgroundColor}>
            <TopWave color={layout?.waveTopColor as TopWaveColors} />
            <Container>
                <div
                    className={cx(styles.verticalImageTestimonialDoubleSliderModule, {
                        [styles.contentRight]: layout?.alignment === 'content-right',
                    })}
                >
                    <div className={styles.content}>
                        <div className={styles.contentInner}>
                            <div className={styles.desktopTitle}>
                                <RevealElement>
                                    {eyebrow && (
                                        <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>
                                            {eyebrow}
                                        </p>
                                    )}
                                    {title && (
                                        <h3 className={cx(styles.title, 'u-a5 u-fw-700')}>
                                            {title}
                                        </h3>
                                    )}
                                </RevealElement>
                            </div>
                            <div className={styles.testimonialSliderWrapper}>
                                <RevealElement>
                                    <Icon name="quote" className={styles.icon} />
                                    {testimonials?.length > 1 ? (
                                        <Swiper
                                            {...testimonialSliderParams}
                                            onSwiper={setTestimonialSlider}
                                            controller={{
                                                control: imageSlider,
                                            }}
                                        >
                                            {testimonials.map((testimonial, index) => (
                                                <SwiperSlide
                                                    key={`testimonialContent__${index}`}
                                                    className={styles.testimonialSliderSlide}
                                                >
                                                    <h4
                                                        className={cx(
                                                            styles.testimonialSliderTitle,
                                                            'u-a3 u-fw-700'
                                                        )}
                                                    >
                                                        {testimonial.title}
                                                    </h4>
                                                    <div className={styles.testimonialSliderHeader}>
                                                        <p className={'u-b2 u-uppercase'}>
                                                            {testimonial.subtitle}
                                                        </p>
                                                        <p className={'u-b2 u-uppercase'}>
                                                            {index < 9
                                                                ? `0${index + 1}`
                                                                : `${index + 1}`}
                                                            /
                                                            {testimonials.length < 9
                                                                ? `0${testimonials.length}`
                                                                : `${testimonials.length}`}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="u-content-editor u-content-editor--module"
                                                        dangerouslySetInnerHTML={{
                                                            __html: testimonial.testimonial,
                                                        }}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    ) : (
                                        <>
                                            {testimonials?.map((testimonial, index) => (
                                                <div key={`testimonial__${index}`}>
                                                    <h4
                                                        className={cx(
                                                            styles.testimonialSliderTitle,
                                                            'u-a3 u-fw-700'
                                                        )}
                                                    >
                                                        {testimonial.title}
                                                    </h4>
                                                    <div className={styles.testimonialSliderHeader}>
                                                        <p className={'u-b2 u-uppercase'}>
                                                            {testimonial.subtitle}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="u-content-editor u-content-editor--module"
                                                        dangerouslySetInnerHTML={{
                                                            __html: testimonial.testimonial,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </RevealElement>
                            </div>
                            {testimonials?.length > 1 && (
                                <div className={styles.arrows}>
                                    <RevealElement>
                                        <ArrowButton
                                            type="left"
                                            onClick={prevSlide}
                                            className={styles.arrow}
                                        />
                                        <ArrowButton
                                            type="right"
                                            onClick={nextSlide}
                                            className={styles.arrow}
                                        />
                                    </RevealElement>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.imageSlider}>
                        <div className={styles.mobileTitle}>
                            <RevealElement>
                                {eyebrow && (
                                    <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>
                                        {eyebrow}
                                    </p>
                                )}
                                {title && (
                                    <h3 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h3>
                                )}
                            </RevealElement>
                        </div>
                        {testimonials?.length > 1 ? (
                            <Swiper
                                {...imageSliderParams}
                                onSwiper={setImageSlider}
                                controller={{ control: testimonialSlider }}
                            >
                                {testimonials?.map((slide, index) => (
                                    <SwiperSlide
                                        key={`testimonialImages__${index}`}
                                        className={styles.imageSliderSlide}
                                    >
                                        <div className={styles.imageSliderSlideDesktop}>
                                            <ParallaxOvalImage
                                                hasBorderRadius={!ovalImages}
                                                parallaxBackground={slide?.images?.vertical}
                                            />
                                        </div>
                                        <div className={styles.imageSliderSlideMobile}>
                                            <ParallaxOvalImage
                                                hasBorderRadius={!ovalImages}
                                                parallaxBackground={slide?.images?.horizontal}
                                            />
                                        </div>
                                        {slide?.video && (
                                            <div className={cx(styles.videoBtn)}>
                                                <VideoModal
                                                    key={`videoModal__${index}`}
                                                    button="Play video"
                                                    title={slide.title}
                                                    video={slide.video}
                                                />
                                            </div>
                                        )}
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            <>
                                {testimonials?.map((slide, index) => (
                                    <div key={`testimonialImages__${index}`}>
                                        <div className={styles.imageSliderSlideDesktop}>
                                            <ParallaxOvalImage
                                                hasBorderRadius={!ovalImages}
                                                parallaxBackground={slide?.images?.vertical}
                                            />
                                        </div>
                                        <div className={styles.imageSliderSlideMobile}>
                                            <ParallaxOvalImage
                                                hasBorderRadius={!ovalImages}
                                                parallaxBackground={slide?.images?.horizontal}
                                            />
                                        </div>
                                        {slide?.video && (
                                            <div className={cx(styles.videoBtn)}>
                                                <VideoModal
                                                    key={`videoModal__${index}`}
                                                    button="Play video"
                                                    title={slide.title}
                                                    video={slide.video}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </Section>
    );
});
