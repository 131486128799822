import React from 'react';
import cx from 'classnames';
import { LocalizedButton } from 'context/LocalizedButton';
import { getImageSize } from 'utils/getImageSize';
import { imagesLoaded } from 'utils/imagesLoaded';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import Button from 'components/common/Button';
import UnderlineLink from 'components/common/UnderlineLink';
import { AccordionGroup } from 'components/common/Accordion';
import styles from './VerticalImageFeatured.module.scss';
import VideoModal from '@root/components/common/VideoModal';

export type VerticalImageFeaturedModuleProps = any;

export type backgroundColors = 'white' | 'beige';

export type waveTopColors = 'white' | 'beige';

export type alignments = 'content-left' | 'content-right';

export type buttonTypes = 'link' | 'button';

export const VerticalImageFeaturedModule = React.forwardRef<
    HTMLDivElement,
    VerticalImageFeaturedModuleProps
>(
    (
        {
            layout,
            logos,
            eyebrow,
            title,
            description,
            accordion,
            buttons,
            images,
            ovalImage = true,
            video,
            videoButtonLabel,
            showVideo,
        },
        ref
    ) => (
        <Section ref={ref} alignment={layout?.alignment} bgColor={layout?.backgroundColor}>
            <TopWave color={layout?.waveTopColor as TopWaveColors} />
            <Container>
                <div
                    className={cx(styles.verticalImageFeaturedModule, {
                        [styles.contentRight]: layout?.alignment === 'content-right',
                    })}
                >
                    <div className={styles.content}>
                        <div className={styles.contentInner}>
                            {logos?.length > 0 && (
                                <RevealElement>
                                    <ul className={cx(styles.logos)}>
                                        {logos.map((logo, logoIndex) => (
                                            <li key={`logo-${logoIndex}`}>
                                                <img
                                                    src={getImageSize(
                                                        logo.sourceUrl,
                                                        logo.mediaDetails.sizes,
                                                        'medium'
                                                    )}
                                                    alt={logo.altText || ''}
                                                    onLoad={imagesLoaded}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </RevealElement>
                            )}
                            <RevealElement>
                                <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>{eyebrow}</p>
                            </RevealElement>
                            <RevealElement>
                                <h3 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h3>
                            </RevealElement>
                            <RevealElement>
                                <div
                                    className={cx(
                                        styles.description,
                                        'u-content-editor u-content-editor--module'
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            </RevealElement>
                            {accordion?.length > 0 && (
                                <RevealElement>
                                    <div className={styles.accordion}>
                                        <AccordionGroup
                                            content={accordion}
                                            color={
                                                layout?.backgroundColor === 'beige'
                                                    ? 'white'
                                                    : 'beige'
                                            }
                                        />
                                    </div>
                                </RevealElement>
                            )}
                            {buttons?.items?.length > 0 && (
                                <RevealElement>
                                    <ul className={styles.buttons}>
                                        {buttons.items.map((item, index) => (
                                            <LocalizedButton
                                                key={index}
                                                data={item.button}
                                                render={(
                                                    btnLabel,
                                                    btnUrl,
                                                    btnTarget,
                                                    btnType,
                                                    dataTp
                                                ) =>
                                                    buttons.buttonType === 'link' ? (
                                                        <li>
                                                            <UnderlineLink
                                                                to={btnUrl}
                                                                target={btnTarget}
                                                                tpLink={dataTp}
                                                                className={cx(
                                                                    styles.link,
                                                                    'u-b1 u-fw-500'
                                                                )}
                                                            >
                                                                {btnLabel}
                                                            </UnderlineLink>
                                                        </li>
                                                    ) : (
                                                        <li>
                                                            <Button
                                                                to={btnUrl}
                                                                target={btnTarget}
                                                                tpLink={dataTp}
                                                                styleType="transparent"
                                                                className={cx(
                                                                    styles.link,
                                                                    'u-b0 u-fw-500'
                                                                )}
                                                            >
                                                                {btnLabel}
                                                            </Button>
                                                        </li>
                                                    )
                                                }
                                            />
                                        ))}
                                    </ul>
                                </RevealElement>
                            )}
                        </div>
                    </div>
                    {images?.vertical && (
                        <div className={cx(styles.images, styles.imagesDesktop)}>
                            <ParallaxOvalImage
                                parallaxBackground={images.vertical}
                                hasBorderRadius={!ovalImage}
                            />
                            {showVideo && video && (
                                <div className={styles.video}>
                                    <VideoModal button={videoButtonLabel} video={video} />
                                </div>
                            )}
                        </div>
                    )}
                    {images?.horizontal && (
                        <div className={cx(styles.images, styles.imagesMobile)}>
                            <ParallaxOvalImage
                                parallaxBackground={images.horizontal}
                                hasBorderRadius={!ovalImage}
                            />
                            {video && (
                                <div className={styles.video}>
                                    <VideoModal button={videoButtonLabel} video={video} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Container>
        </Section>
    )
);
