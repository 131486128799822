import React, { useState, useCallback } from 'react';
import cx from 'classnames';
import SwiperCore, { Controller, EffectFade, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LocalizedButton } from 'context/LocalizedButton';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import ArrowButton from 'components/common/ArrowButton';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './VerticalImageFeaturedSlider.module.scss';

SwiperCore.use([Controller, EffectFade]);

export type VerticalImageFeaturedSliderModuleProps = any;

export type backgroundColors = 'white' | 'beige';

export type alignments = 'content-left' | 'content-right';

export const VerticalImageFeaturedSliderModule = React.forwardRef<
    HTMLDivElement,
    VerticalImageFeaturedSliderModuleProps
>(({ layout, slider, ovalImages = true }, ref) => {
    const [contentSlider, setContentSlider] = useState<SwiperCore>();
    const [imageSlider, setImageSlider] = useState<SwiperCore>();

    const contentSliderParams: SwiperOptions = {
        loop: true,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
    };

    const imageSliderParams: SwiperOptions = {
        effect: 'fade',
        loop: true,
        fadeEffect: {
            crossFade: true,
        },
    };

    const nextSlide = useCallback(() => {
        if (contentSlider) contentSlider.slideNext();
    }, [contentSlider]);

    const prevSlide = useCallback(() => {
        if (contentSlider) contentSlider.slidePrev();
    }, [contentSlider]);

    return (
        <Section ref={ref} alignment={layout?.alignment} bgColor={layout?.backgroundColor}>
            <Container>
                <div
                    className={cx(styles.verticalImageFeaturedSlider, {
                        [styles.contentRight]: layout?.alignment === 'content-right',
                    })}
                >
                    <div className={styles.contentSlider}>
                        {slider?.length > 1 ? (
                            <>
                                <RevealElement>
                                    <Swiper
                                        {...contentSliderParams}
                                        onSwiper={setContentSlider}
                                        controller={{ control: imageSlider }}
                                    >
                                        {slider.map((slide, index) => (
                                            <SwiperSlide
                                                key={`contentSlider__${index}`}
                                                className={styles.contentSliderSlide}
                                            >
                                                <div className={styles.contentSliderHeader}>
                                                    <p
                                                        className={cx(
                                                            styles.contentSliderEyebrow,
                                                            'u-b2 u-uppercase'
                                                        )}
                                                    >
                                                        {slide.eyebrow}
                                                    </p>
                                                    <p
                                                        className={cx(
                                                            styles.contentSliderFraction,
                                                            'u-b2 u-uppercase'
                                                        )}
                                                    >
                                                        {index < 9
                                                            ? `0${index + 1}`
                                                            : `${index + 1}`}
                                                        /
                                                        {slider.length < 9
                                                            ? `0${slider.length}`
                                                            : `${slider.length}`}
                                                    </p>
                                                </div>
                                                <h2
                                                    className={cx(
                                                        styles.contentSliderTitle,
                                                        'u-a5 u-fw-700'
                                                    )}
                                                >
                                                    {slide.title}
                                                </h2>
                                                <div
                                                    className={cx(
                                                        styles.contentSliderDescription,
                                                        'u-content-editor u-content-editor--module'
                                                    )}
                                                    dangerouslySetInnerHTML={{
                                                        __html: slide.description,
                                                    }}
                                                />
                                                {slide?.button && (
                                                    <LocalizedButton
                                                        data={slide.button}
                                                        render={(
                                                            btnLabel,
                                                            btnUrl,
                                                            btnTarget,
                                                            btnType,
                                                            dataTp
                                                        ) => (
                                                            <div
                                                                className={styles.contentSliderLink}
                                                            >
                                                                <UnderlineLink
                                                                    to={btnUrl}
                                                                    tpLink={dataTp}
                                                                    target={btnTarget}
                                                                    className="u-b1 u-fw-500"
                                                                >
                                                                    {btnLabel}
                                                                </UnderlineLink>
                                                            </div>
                                                        )}
                                                    />
                                                )}
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </RevealElement>
                                <div className={styles.arrows}>
                                    <RevealElement>
                                        <ArrowButton
                                            type="left"
                                            onClick={prevSlide}
                                            className={styles.arrow}
                                        />
                                        <ArrowButton
                                            type="right"
                                            onClick={nextSlide}
                                            className={styles.arrow}
                                        />
                                    </RevealElement>
                                </div>
                            </>
                        ) : (
                            <RevealElement>
                                {slider?.map((slide, index) => (
                                    <div key={`revealSlider__${index}`}>
                                        <div className={styles.contentSliderHeader}>
                                            <p
                                                className={cx(
                                                    styles.contentSliderEyebrow,
                                                    'u-b2 u-uppercase'
                                                )}
                                            >
                                                {slide.eyebrow}
                                            </p>
                                        </div>
                                        <h2
                                            className={cx(
                                                styles.contentSliderTitle,
                                                'u-a5 u-fw-700'
                                            )}
                                        >
                                            {slide.title}
                                        </h2>
                                        <div
                                            className={cx(
                                                styles.contentSliderDescription,
                                                'u-content-editor u-content-editor--module'
                                            )}
                                            dangerouslySetInnerHTML={{
                                                __html: slide.description,
                                            }}
                                        />
                                        {slide?.button && (
                                            <LocalizedButton
                                                data={slide.button}
                                                render={(
                                                    btnLabel,
                                                    btnUrl,
                                                    btnTarget,
                                                    btnType,
                                                    dataTp
                                                ) => (
                                                    <div className={styles.contentSliderLink}>
                                                        <UnderlineLink
                                                            to={btnUrl}
                                                            tpLink={dataTp}
                                                            target={btnTarget}
                                                            className="u-b1 u-fw-500"
                                                        >
                                                            {btnLabel}
                                                        </UnderlineLink>
                                                    </div>
                                                )}
                                            />
                                        )}
                                    </div>
                                ))}
                            </RevealElement>
                        )}
                    </div>
                    <div className={styles.imageSlider}>
                        {slider?.length > 1 ? (
                            <Swiper
                                {...imageSliderParams}
                                onSwiper={setImageSlider}
                                controller={{ control: contentSlider }}
                            >
                                {slider.map((slide, index) => (
                                    <SwiperSlide
                                        key={`imageSlider-01__${index}`}
                                        className={styles.imageSliderSlide}
                                    >
                                        <div className={styles.imageSliderSlideDesktop}>
                                            <ParallaxOvalImage
                                                parallaxBackground={slide?.images?.vertical}
                                                hasBorderRadius={!ovalImages}
                                            />
                                        </div>
                                        <div className={styles.imageSliderSlideMobile}>
                                            <ParallaxOvalImage
                                                parallaxBackground={slide?.images?.horizontal}
                                                hasBorderRadius={!ovalImages}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            <>
                                {slider?.map((slide, index) => (
                                    <>
                                        <div
                                            key={`imageSlider-02__${index}`}
                                            className={styles.imageSliderSlideDesktop}
                                        >
                                            <ParallaxOvalImage
                                                parallaxBackground={slide?.images?.vertical}
                                                hasBorderRadius={!ovalImages}
                                            />
                                        </div>
                                        <div className={styles.imageSliderSlideMobile}>
                                            <ParallaxOvalImage
                                                parallaxBackground={slide?.images?.horizontal}
                                                hasBorderRadius={!ovalImages}
                                            />
                                        </div>
                                    </>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </Container>
        </Section>
    );
});
