import { CountryFitline } from 'types/wordpress';

export function getMatchingCountryModel(
    countries: readonly CountryFitline[],
    countryCode,
    language
) {
    const matchingCountryModel = countries?.find(
        countryModel =>
            countryModel.countryCode?.toLowerCase() === countryCode?.toLowerCase() &&
            countryModel.language?.toLowerCase() === language?.toLowerCase()
    );

    if (!matchingCountryModel) {
        return undefined;
    }

    return matchingCountryModel;
}
