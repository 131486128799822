import React from 'react';
import { HeaderModules } from './HeaderModuleBlocks.enums';
import { useAppContext } from 'context/App';
import { isAvailableInCountry } from 'utils/isAvailableInCountry';
import BigHeadlineModule from './BigHeadline';
import MediaHeaderModule from './MediaHeader';
import HomepageHeaderModule from './HomepageHeader';
import CustomBackgroundTwoColumnModule from './CustomBackgroundTwoColumn';

type Layout = {
    [key in HeaderModules]: React.ReactNode;
};

export type LayoutData = any;

interface Props {
    layoutData: LayoutData;
}

export const HeaderModuleBlocks: React.FC<Props> = ({ layoutData }) => {
    const { countryCode: currentCountryCode } = useAppContext();
    const layoutType = layoutData?.__typename as HeaderModules;

    const moduleCountries = layoutData?.generalSettings?.moduleCountries;
    const moduleAvailableInCountry = isAvailableInCountry(currentCountryCode, moduleCountries);

    if (!moduleAvailableInCountry) {
        return null;
    }

    const Default = () => (
        <p>
            In <strong>HeaderModuleBlocks</strong> the mapping of component{' '}
            <strong>{layoutType || 'unknown'}</strong> is missing.
        </p>
    );

    /**
     * Mapping the fieldGroupName(s) to our components
     */
    const layouts: Layout = {
        [HeaderModules.BigHeadlineModule]: BigHeadlineModule,
        [HeaderModules.MediaHeaderModule]: MediaHeaderModule,
        [HeaderModules.HomepageHeaderModule]: HomepageHeaderModule,
        [HeaderModules.CustomBackgroundTwoColumnModule]: CustomBackgroundTwoColumnModule,
    };

    const ComponentTag = layouts[layoutType] ? (layouts[layoutType] as React.ElementType) : Default;

    return <ComponentTag {...layoutData} />;
};
