import React, { useState } from 'react';
import cx from 'classnames';
import { useAppContext } from 'context';
import { LocalizedButton } from 'context/LocalizedButton';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxElement from 'components/Layout/ParallaxElement';
import Button from 'components/common/Button';
import QuizProductModal from 'components/common/QuizProductModal';
import SubscriptionModal from '@root/components/common/SubscriptionModal';
import styles from './FeaturedTopicCta.module.scss';
import BottomWave, { BottomWaveColors } from '@root/components/Layout/BottomWave';
import MultiStepQuizModal from '@root/components/common/MultiStepQuizModal';

export type FeaturedTopicCtaModuleProps = any;

export const FeaturedTopicCtaModule = React.forwardRef<HTMLDivElement, FeaturedTopicCtaModuleProps>(
    (
        {
            layout,
            eyebrow,
            title,
            description,
            button,
            buttonType,
            quizButton,
            quiz,
            parallaxImages,
            preEyebrowImage,
            modalIframe,
            customBackground,
        },
        ref
    ) => {
        const { left: imageLeft, right: imageRight } = parallaxImages;

        const { language, countryCode } = useAppContext();

        const [quizOpened, setQuizOpened] = useState(false);
        const [multiStepQuizOpened, setMultiStepQuizOpened] = useState(false);

        return (
            <Section
                ref={ref}
                alignment=""
                bgColor="red"
                style={!!customBackground ? { background: customBackground } : {}}
            >
                <div
                    className={cx(styles.sectionBackgrounds, {
                        [styles.backgroundsMobile]: description && preEyebrowImage,
                    })}
                >
                    <ParallaxElement
                        percentage={-15}
                        className={cx(styles.sectionBackground, 'u-lazy js-lazy', {
                            [styles.alignBottom]: description,
                        })}
                        data-bg={imageLeft}
                    />
                    <ParallaxElement
                        percentage={15}
                        className={cx(styles.sectionBackground, 'u-lazy js-lazy')}
                        data-bg={imageRight}
                    />
                </div>
                {!description && !preEyebrowImage && (
                    <TopWave color={layout?.waveTopColor as TopWaveColors} />
                )}
                <Container type="full-width">
                    <div className={styles.featuredTopicCta}>
                        {preEyebrowImage && (
                            <figure className={styles.eyebrowImage}>
                                <RevealElement>
                                    <img src={preEyebrowImage} alt={title || ''} />
                                </RevealElement>
                            </figure>
                        )}
                        <RevealElement>
                            <p
                                className={cx(
                                    styles.eyebrow,
                                    'u-uppercase',
                                    preEyebrowImage ? 'u-a2' : 'u-b2'
                                )}
                            >
                                {eyebrow}
                            </p>
                        </RevealElement>
                        <RevealElement>
                            <h3
                                className={cx(styles.title, 'u-a6', 'u-fw-700', {
                                    [styles.hasDescription]: description && preEyebrowImage,
                                })}
                            >
                                {title}
                            </h3>
                        </RevealElement>
                        {description && (
                            <RevealElement>
                                <p className={cx(styles.description, 'u-a2')}>{description}</p>
                            </RevealElement>
                        )}
                        {buttonType === 'default' && !modalIframe?.button && (
                            <LocalizedButton
                                data={button}
                                render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                    <RevealElement>
                                        <Button
                                            to={btnUrl}
                                            target={btnTarget}
                                            styleType={
                                                description && preEyebrowImage
                                                    ? 'transparent'
                                                    : 'filled'
                                            }
                                            color="white"
                                            className="u-b0 u-fw-500"
                                            tpLink={dataTp}
                                        >
                                            {btnLabel}
                                        </Button>
                                    </RevealElement>
                                )}
                            />
                        )}
                        {buttonType === 'default' &&
                            modalIframe?.button &&
                            modalIframe?.iframeSource && (
                                <SubscriptionModal
                                    label={modalIframe.button}
                                    src={modalIframe.iframeSource}
                                    render={(label, open) => (
                                        <RevealElement>
                                            <Button
                                                color="white"
                                                styleType="filled"
                                                className="u-b0 u-fw-500"
                                                onClick={() => open(true)}
                                            >
                                                {label}
                                            </Button>
                                        </RevealElement>
                                    )}
                                />
                            )}
                        {buttonType === 'quiz' && quizButton && !!quiz && (
                            <>
                                <RevealElement>
                                    <Button
                                        color="white"
                                        styleType="filled"
                                        className="u-b0 u-fw-500"
                                        onClick={() => {
                                            setQuizOpened(true);
                                        }}
                                    >
                                        {quizButton}
                                    </Button>
                                </RevealElement>
                                <QuizProductModal
                                    id={quiz?.databaseId}
                                    opened={quizOpened}
                                    language={language}
                                    country={countryCode}
                                    onClose={() => setQuizOpened(false)}
                                />
                            </>
                        )}
                        {buttonType === 'multistepQuiz' && quizButton && !!quiz && (
                            <>
                                <RevealElement>
                                    <Button
                                        color="white"
                                        styleType="filled"
                                        className="u-b0 u-fw-500"
                                        onClick={() => {
                                            setMultiStepQuizOpened(true);
                                        }}
                                    >
                                        {quizButton}
                                    </Button>
                                </RevealElement>
                                <MultiStepQuizModal
                                    opened={multiStepQuizOpened}
                                    id={quiz?.databaseId}
                                    language={language}
                                    country={countryCode}
                                    onClose={() => setMultiStepQuizOpened(false)}
                                />
                            </>
                        )}
                    </div>
                </Container>
                {description && preEyebrowImage && (
                    <BottomWave color={layout?.waveBottomColor as BottomWaveColors} />
                )}
            </Section>
        );
    }
);
