import React from 'react';
import cx from 'classnames';
import ParallaxElement from 'components/Layout/ParallaxElement';
import styles from './ParallaxOvalImageGroup.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    parallaxBackground?: string;
    parallaxBackgroundMobile?: string;
    parallaxInnerImage?: string;
    parallaxImageTopLeft?: string;
    parallaxImageBottomRight?: string;
}

export const ParallaxOvalImageGroup = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            parallaxBackground,
            parallaxBackgroundMobile,
            parallaxInnerImage,
            parallaxImageTopLeft,
            parallaxImageBottomRight,
            className,
            ...rest
        },
        ref
    ) => (
        <div ref={ref} className={cx(styles.parallaxOvalImageGroup, className)} {...rest}>
            <div className={styles.shape}>
                {parallaxBackgroundMobile ? (
                    <>
                        <ParallaxElement
                            percentage={10}
                            className={cx(
                                styles.parallaxBackground,
                                styles.parallaxBackgroundMobile,
                                'u-lazy js-lazy'
                            )}
                            data-bg={parallaxBackgroundMobile}
                        />
                        {parallaxBackground && (
                            <ParallaxElement
                                percentage={10}
                                className={cx(
                                    styles.parallaxBackground,
                                    styles.parallaxBackgroundDesktop,
                                    'u-lazy js-lazy'
                                )}
                                data-bg={parallaxBackground}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {parallaxBackground && (
                            <ParallaxElement
                                percentage={10}
                                className={cx(styles.parallaxBackground, 'u-lazy js-lazy')}
                                data-bg={parallaxBackground}
                            />
                        )}
                    </>
                )}

                {parallaxInnerImage && (
                    <ParallaxElement
                        percentage={15}
                        className={cx(styles.parallaxInnerImage, 'u-lazy js-lazy')}
                        data-bg={parallaxInnerImage}
                    />
                )}
            </div>
            {parallaxImageTopLeft && (
                <ParallaxElement
                    percentage={-70}
                    className={cx(styles.parallaxImageTopLeft, 'u-lazy js-lazy')}
                    data-bg={parallaxImageTopLeft}
                />
            )}
            {parallaxImageBottomRight && (
                <ParallaxElement
                    percentage={90}
                    className={cx(styles.parallaxImageBottomRight, 'u-lazy js-lazy')}
                    data-bg={parallaxImageBottomRight}
                />
            )}
        </div>
    )
);
