import React from 'react';
import cx from 'classnames';
import Icon from 'components/Icons';
import styles from './ShareBox.module.scss';

interface Props extends React.HTMLAttributes<HTMLUListElement> {
    direction?: 'horizontal' | 'vertical';
    url?: string;
    title?: string;
    transparent?: boolean;
}

const ShareBox = React.forwardRef<HTMLUListElement, Props>(
    ({ direction, url, title, transparent }, ref) => {
        let _url = url || '';
        let _title = title || '';

        const shareLink = (
            ev,
            engine: 'facebook' | 'twitter' | 'pinterest' | 'linkedin' | 'whatsapp'
        ) => {
            ev.preventDefault();
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                let url = '';
                _url = _url || window.location.href;
                _title = _title || document.title;

                const currentUrl = encodeURIComponent(_url);
                const documentTitle = encodeURIComponent(_title);
                const whatsAppMsg = encodeURIComponent(`${_title} - ${_url}`);

                if (engine === 'facebook')
                    url = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
                else if (engine === 'twitter')
                    url = `https://twitter.com/intent/tweet?url=${currentUrl}&text=${documentTitle}`;
                else if (engine === 'pinterest')
                    url = `http://pinterest.com/pin/create/button/?url=${currentUrl}&description=${documentTitle}`;
                else if (engine === 'linkedin')
                    url = `http://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${documentTitle}`;
                else if (engine === 'whatsapp') url = `whatsapp://send?text=${whatsAppMsg}`;

                window.open(url, '_blank');
            }
        };

        return (
            <ul
                ref={ref}
                className={cx(styles.box, styles[`${direction || 'vertical'}`], {
                    [styles.transparent]: transparent,
                })}
            >
                <li>
                    <button
                        aria-label="Share on Facebook"
                        className={styles.btn}
                        onClick={ev => shareLink(ev, 'facebook')}
                    >
                        <Icon name="facebook" />
                    </button>
                </li>
                <li>
                    <button
                        aria-label="Share on Twitter"
                        className={styles.btn}
                        onClick={ev => shareLink(ev, 'twitter')}
                    >
                        <Icon name="twitter" />
                    </button>
                </li>
                <li>
                    <button
                        aria-label="Share on Pinterest"
                        className={styles.btn}
                        onClick={ev => shareLink(ev, 'pinterest')}
                    >
                        <Icon name="pinterest" />
                    </button>
                </li>
                <li>
                    <button
                        aria-label="Share on LinkedIn"
                        className={styles.btn}
                        onClick={ev => shareLink(ev, 'linkedin')}
                    >
                        <Icon name="linkedin" />
                    </button>
                </li>
                <li>
                    <button
                        aria-label="Share on WhatsApp"
                        className={styles.btn}
                        onClick={ev => shareLink(ev, 'whatsapp')}
                    >
                        <Icon name="whatsapp" />
                    </button>
                </li>
            </ul>
        );
    }
);

export default ShareBox;
