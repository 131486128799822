import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LazyLoad from 'vanilla-lazyload';
import TranslationKey from 'context/TranslationKey';
import RevealElement from 'components/Layout/RevealElement';
import ArticleItem from 'components/common/ArticleItem';
import styles from './MagazineArticles.module.scss';

export const MagazineArticles = ({ articles, loading }) => {
    const data = useMemo(() => {
        const items = { large: [], small: [] };
        articles.forEach((item, index) => {
            if (index < 2) {
                items.large.push({ ...item });
            } else {
                items.small.push({ ...item });
            }
        });
        return items;
    }, [articles]);

    useEffect(() => {
        new LazyLoad({
            elements_selector: '.js-lazy',
        });
    }, [articles]);

    return data?.large?.length > 0 ? (
        <div
            className={cx(styles.articles, {
                [styles.isLoading]: loading,
            })}
        >
            <div className={styles.articlesBig}>
                <div className={styles.articlesRow}>
                    {data?.large?.map(article => (
                        <div key={article.id} className={styles.articlesBigCol}>
                            <RevealElement>
                                <ArticleItem
                                    addTp
                                    link={article.url}
                                    eyebrow={article.category}
                                    title={article.title}
                                    date={article.date}
                                    image={article.image}
                                    variation="big"
                                />
                            </RevealElement>
                        </div>
                    ))}
                </div>
            </div>
            {data?.small?.length > 0 && (
                <div className={styles.articlesSmall}>
                    <div className={styles.articlesRow}>
                        {data?.small?.map(article => (
                            <div key={article.id} className={styles.articlesSmallCol}>
                                <RevealElement>
                                    <ArticleItem
                                        addTp
                                        link={article.url}
                                        eyebrow={article.category}
                                        title={article.title}
                                        date={article.date}
                                        image={article.image}
                                        variation="small"
                                    />
                                </RevealElement>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    ) : (
        <p className={cx(styles.emptyTitle, 'u-a3')}>
            <TranslationKey name="noResults" />
        </p>
    );
};

MagazineArticles.defaultProps = {
    loading: false,
    articles: [],
};

MagazineArticles.propTypes = {
    loading: PropTypes.bool,
    articles: PropTypes.oneOfType([PropTypes.array]),
};

export default MagazineArticles;
