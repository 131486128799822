import React, { useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';
import Cookies from 'js-cookie';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { mergeRefs } from 'utils/mergeRefs';
import { getImageSize } from 'utils/getImageSize';
import { imagesLoaded } from 'utils/imagesLoaded';
import Section from 'components/Layout/Section';
import DoubleCta from 'components/common/DoubleCta';
import ParallaxElement from 'components/Layout/ParallaxElement';
import BottomWave from 'components/Layout/BottomWave';
import styles from './HomepageHeader.module.scss';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(CustomEase);
    CustomEase.create('customEaseBoth', 'M0,0 C0.42,0 0.58,1 1,1');
    CustomEase.create('customDefault', 'M0,0 C0.25,0.1 0.25,1 1,1');
}

type Props = any;

export const HomepageHeaderModule = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            eyebrow,
            logo,
            title,
            buttons,
            parallaxImages,
            introAnimationImage,
            introAnimationImageMobile,
            introAnimationCookieExpire,
            layout,
        },
        ref
    ) => {
        const moduleWrapperRef = useRef(null);
        const introWrapperRef = useRef(null);
        const introBackgroundRef = useRef(null);
        const introBackgroundMobileRef = useRef(null);
        const headerWrapperRef = useRef(null);
        const headerEyebrowRef = useRef(null);
        const headerTitleRef = useRef(null);
        const headerCtaRef = useRef(null);

        const parallaxElementsRefs = useRef(null);
        parallaxElementsRefs.current = [];

        const addToParallaxRefs = useCallback(
            el => {
                if (parallaxElementsRefs?.current) {
                    if (el && !parallaxElementsRefs.current.includes(el)) {
                        parallaxElementsRefs.current.push(el);
                    }
                }
            },
            [parallaxElementsRefs]
        );

        const showParallaxImages = useCallback(() => {
            if (parallaxElementsRefs?.current) {
                gsap.to(parallaxElementsRefs.current, {
                    duration: 1.25,
                    autoAlpha: 1,
                    delay: 0.15,
                    ease: 'customEaseBoth',
                });
            }
        }, [parallaxElementsRefs]);

        useEffect(() => {
            if (Cookies.get('introAnimation') !== 'true') {
                const inFifteenMinutes = new Date(
                    new Date().getTime() + introAnimationCookieExpire * 60 * 1000
                );
                Cookies.set('introAnimation', 'true', {
                    expires: inFifteenMinutes,
                });
                const tl = gsap.timeline({
                    delay: 0.3,
                });
                tl.add('start');
                tl.to(
                    introWrapperRef.current,
                    {
                        duration: 2.4,
                        y: '-100%',
                        ease: 'customEaseBoth',
                    },
                    'start'
                );
                tl.to(
                    introBackgroundRef.current,
                    {
                        duration: 2.4,
                        y: '20%',
                        ease: 'customEaseBoth',
                    },
                    'start'
                );
                tl.to(
                    introBackgroundRef.current,
                    {
                        duration: 2.4,
                        scale: 1.2,
                        ease: 'customDefault',
                    },
                    'start'
                );
                if (introBackgroundMobileRef?.current) {
                    tl.to(
                        introBackgroundMobileRef.current,
                        {
                            duration: 2.4,
                            scale: 1.2,
                            ease: 'customDefault',
                        },
                        'start'
                    );
                }
                tl.to(
                    headerWrapperRef.current,
                    {
                        duration: 2.4,
                        y: '0%',
                        ease: 'customEaseBoth',
                    },
                    'start'
                );
                tl.add('text', 1.2);
                tl.to(
                    headerEyebrowRef.current,
                    {
                        duration: 1.5,
                        y: '0px',
                        autoAlpha: 1,
                        ease: 'customDefault',
                        onComplete: () => {
                            imagesLoaded();
                            showParallaxImages();
                        },
                    },
                    'text'
                );
                tl.to(
                    headerTitleRef.current,
                    {
                        duration: 1.5,
                        y: '0px',
                        autoAlpha: 1,
                        ease: 'customDefault',
                        delay: 0.2,
                    },
                    'text'
                );
                tl.to(
                    headerCtaRef.current,
                    {
                        duration: 1.5,
                        y: '0px',
                        autoAlpha: 1,
                        ease: 'customDefault',
                        delay: 0.45,
                    },
                    'text'
                );
            } else {
                moduleWrapperRef.current.classList.add('has-cookie');
            }
        }, [introAnimationCookieExpire, showParallaxImages]);

        return (
            <div className={styles.moduleWrapper} ref={mergeRefs(moduleWrapperRef, ref)}>
                <div ref={introWrapperRef} className={styles.introWrapper}>
                    <div
                        ref={introBackgroundRef}
                        className={cx(styles.introBackground, styles.introBackgroundDesktop)}
                        style={{
                            backgroundImage: `url('${getImageSize(
                                introAnimationImage.sourceUrl,
                                introAnimationImage.mediaDetails.sizes,
                                'custom-1920x'
                            )}')`,
                        }}
                    />
                    {introAnimationImageMobile && (
                        <div
                            ref={introBackgroundMobileRef}
                            className={cx(styles.introBackground, styles.introBackgroundMobile)}
                            style={{
                                backgroundImage: `url('${introAnimationImageMobile}')`,
                            }}
                        />
                    )}
                </div>
                <Section
                    className={styles.headerWrapper}
                    ref={headerWrapperRef}
                    modifier=""
                    alignment=""
                    bgColor={layout?.backgroundColor || 'red'}
                >
                    <div
                        className={cx(styles.main, {
                            [`${styles.isBeige}`]: layout?.backgroundColor === 'beige',
                        })}
                    >
                        <div className={styles.container}>
                            {eyebrow && !logo?.sourceUrl && (
                                <p
                                    className={cx('u-b2 u-fw-400 u-uppercase', styles.eyebrow)}
                                    ref={headerEyebrowRef}
                                >
                                    {eyebrow}
                                </p>
                            )}
                            {logo?.sourceUrl && (
                                <div className={styles.logo} ref={headerEyebrowRef}>
                                    <img src={logo.sourceUrl} alt={logo?.altText} />
                                </div>
                            )}
                            <h1 className={cx(styles.title, 'u-a7 u-fw-700')} ref={headerTitleRef}>
                                {title}
                            </h1>
                            {buttons?.length > 0 && (
                                <div className={styles.cta} ref={headerCtaRef}>
                                    <DoubleCta
                                        buttons={buttons}
                                        className="u-b0 u-fw-500"
                                        color={layout?.backgroundColor === 'red' ? 'white' : 'red'}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.parallaxWrapper} ref={addToParallaxRefs}>
                            <HomepageHeaderModuleParallaxImage
                                image={parallaxImages?.imageTopLeft}
                                position="TopLeft"
                                percentage={[-30, 20]}
                            />
                        </div>
                        <div className={styles.parallaxWrapper} ref={addToParallaxRefs}>
                            <HomepageHeaderModuleParallaxImage
                                image={parallaxImages?.imageTopRight}
                                position="TopRight"
                            />
                        </div>
                        <div className={styles.parallaxWrapper} ref={addToParallaxRefs}>
                            <HomepageHeaderModuleParallaxImage
                                image={parallaxImages?.imageBottomLeft}
                                position="BottomLeft"
                            />
                        </div>
                        <div className={styles.parallaxWrapper} ref={addToParallaxRefs}>
                            <HomepageHeaderModuleParallaxImage
                                image={parallaxImages?.imageBottomRight}
                                position="BottomRight"
                                percentage={[-40, 20]}
                            />
                        </div>
                        <BottomWave color="white" />
                    </div>
                </Section>
            </div>
        );
    }
);

export const HomepageHeaderModuleParallaxImage = ({ image, position, percentage = [-50, 20] }) =>
    image?.front?.sourceUrl && image?.back?.sourceUrl && percentage?.length === 2 ? (
        <ParallaxElement
            percentage={percentage[0]}
            start="top bottom"
            className={cx(styles.parallaxElement, styles[`parallax${position}`] || '')}
        >
            <img
                src={getImageSize(image.front.sourceUrl, image.front.mediaDetails.sizes, 'large')}
                alt=""
                onLoad={imagesLoaded}
            />
            <ParallaxElement
                percentage={percentage[1]}
                start="top bottom"
                className={styles.parallaxElementBack}
                style={{
                    backgroundImage: `url(${getImageSize(
                        image.back.sourceUrl,
                        image.back.mediaDetails.sizes,
                        'large'
                    )})`,
                }}
            />
        </ParallaxElement>
    ) : null;
