import React from 'react';
import cx from 'classnames';
import { getImageSize } from 'utils/getImageSize';
import { getViewportSize } from 'utils/getViewportSize';
import { LocalizedPageCollection } from 'context/LocalizedPageCollection';
import { LocalizedUrl } from 'context/LocalizedUrl';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxElement from 'components/Layout/ParallaxElement';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './NextProduct.module.scss';

export type backgroundColors = 'white' | 'beige';

export type NextProductModuleProps = any;

export const NextProductModule = React.forwardRef<HTMLDivElement, NextProductModuleProps>(
    ({ layout, eyebrow, product, productLinkLabel }, ref) => (
        <Section ref={ref} alignment="" bgColor={layout.backgroundColor}>
            <Container>
                <LocalizedPageCollection
                    records={product}
                    render={filteredRecords => (
                        <div>
                            {filteredRecords.map((single, index) => {
                                if (index > 0) return false;
                                const singleExcerpt = single.productGroup.excerpt
                                    ? single.productGroup.excerpt.replace(/<\/?[^>]+(>|$)/g, '')
                                    : '';
                                return (
                                    <div className={styles.nextProductModule} key={index}>
                                        <div className={styles.content}>
                                            <RevealElement>
                                                <p
                                                    className={cx(
                                                        styles.eyebrow,
                                                        'u-b2 u-uppercase'
                                                    )}
                                                >
                                                    {eyebrow}
                                                </p>
                                            </RevealElement>
                                            <div className={styles.holder}>
                                                <div
                                                    className={cx(styles.shape, {
                                                        [styles.lightBeige]:
                                                            layout.backgroundColor === 'beige',
                                                    })}
                                                />
                                                <RevealElement>
                                                    <h2
                                                        className={cx(
                                                            styles.title,
                                                            'u-a5 u-fw-bold'
                                                        )}
                                                    >
                                                        {single.options.title}
                                                    </h2>
                                                </RevealElement>
                                                <RevealElement>
                                                    <p className={cx(styles.description, 'u-b0')}>
                                                        {singleExcerpt}
                                                        {/*{single.productGroup*/}
                                                        {/*    .additionalExcerptInfo && (*/}
                                                        {/*    <InfoTooltip*/}
                                                        {/*        content={*/}
                                                        {/*            single.productGroup*/}
                                                        {/*                .additionalExcerptInfo*/}
                                                        {/*        }*/}
                                                        {/*    />*/}
                                                        {/*)}*/}
                                                    </p>
                                                </RevealElement>
                                                <RevealElement>
                                                    <LocalizedUrl
                                                        countries={single.options.countries}
                                                        render={url => (
                                                            <UnderlineLink
                                                                className={cx(
                                                                    styles.link,
                                                                    'u-b1 u-fw-500'
                                                                )}
                                                                to={url}
                                                                tpLink="internal"
                                                            >
                                                                {productLinkLabel}
                                                            </UnderlineLink>
                                                        )}
                                                    />
                                                </RevealElement>
                                            </div>
                                        </div>
                                        <div className={styles.imageWrapper}>
                                            <ParallaxElement
                                                percentage={getViewportSize() <= 600 ? 20 : 30}
                                                end={'+=250%'}
                                                className={cx(styles.image, 'u-lazy js-lazy')}
                                                data-bg={getImageSize(
                                                    single.productGroup.image.sourceUrl,
                                                    single.productGroup.image.mediaDetails.sizes,
                                                    'large'
                                                )}
                                            />
                                            <div
                                                className={cx(styles.mobileShape, {
                                                    [styles.lightBeige]:
                                                        layout.backgroundColor === 'beige',
                                                })}
                                            />
                                        </div>
                                        <RevealElement>
                                            <LocalizedUrl
                                                countries={single.options.countries}
                                                render={url => (
                                                    <UnderlineLink
                                                        className={cx(
                                                            styles.mobileLink,
                                                            'u-b1 u-fw-500'
                                                        )}
                                                        to={url}
                                                        tpLink="internal"
                                                    >
                                                        {productLinkLabel}
                                                    </UnderlineLink>
                                                )}
                                            />
                                        </RevealElement>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                />
            </Container>
        </Section>
    )
);
