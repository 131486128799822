import React, { useEffect, useState } from 'react';

interface HubspotFrameProps {
    id?: string;
    portalId: string;
    formId: string;
}

const HubspotFrame = ({ id = 'hubspot-form-wrapper', portalId, formId }) => {
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, [setMounted]);

    useEffect(() => {
        if (isMounted && portalId && formId && window && typeof window !== 'undefined') {
            // @ts-ignore
            window?.hbspt?.forms.create({
                region: 'eu1',
                portalId,
                formId,
                target: '#hubspot-form-wrapper',
            });
        }
    }, [isMounted, portalId, formId]);

    return <div id={id} />;
};

export default HubspotFrame;
