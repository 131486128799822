import React, { useMemo, useCallback } from 'react';
import { useState } from 'react';
import cx from 'classnames';
import SwiperCore, { Controller, EffectFade, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LocalizedUrl } from 'context/LocalizedUrl';
import { getViewportSize } from 'utils/getViewportSize';
import { getImageSize } from 'utils/getImageSize';
import { LocalizedPageCollection } from 'context/LocalizedPageCollection';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxElement from 'components/Layout/ParallaxElement';
import UnderlineLink from 'components/common/UnderlineLink';
import ArrowButton from 'components/common/ArrowButton';
import styles from './FeaturedProductsSlider.module.scss';

SwiperCore.use([Controller, EffectFade]);

export type backgroundColors = 'white' | 'beige';

export type FeaturedProductsSliderModuleProps = any;

export const FeaturedProductsSliderModule = React.forwardRef<
    HTMLDivElement,
    FeaturedProductsSliderModuleProps
>(({ layout, contentType, title, products, productLinkLabel, productsCustom }, ref) => {
    const [contentSlider, setContentSlider] = useState<SwiperCore>();
    const [imageSlider, setImageSlider] = useState<SwiperCore>();

    const contentSliderParams: SwiperOptions = useMemo(
        () => ({
            effect: 'fade',
            loop: true,
            fadeEffect: {
                crossFade: true,
            },
        }),
        []
    );

    const imageSliderParams: SwiperOptions = useMemo(
        () => ({
            effect: 'fade',
            loop: true,
            fadeEffect: {
                crossFade: true,
            },
            shortSwipes: false,
        }),
        []
    );

    const nextSlide = useCallback(() => {
        if (contentSlider) contentSlider.slideNext();
    }, [contentSlider]);

    const prevSlide = useCallback(() => {
        if (contentSlider) contentSlider.slidePrev();
    }, [contentSlider]);

    return (
        <Section ref={ref} alignment="" bgColor={layout.backgroundColor}>
            <Container>
                {contentType === 'relationship' && products?.length > 0 && (
                    <LocalizedPageCollection
                        records={products}
                        render={filteredRecords => {
                            if (filteredRecords?.length === 0) return null;
                            return (
                                <div className={styles.featuredProductsSliderModule}>
                                    <div className={styles.content}>
                                        <RevealElement>
                                            <h2 className={cx(styles.title, 'u-a5 u-fw-bold')}>
                                                {title}
                                            </h2>
                                        </RevealElement>
                                        <div className={styles.holder}>
                                            <div
                                                className={cx(styles.shape, {
                                                    [styles.lightBeige]:
                                                        layout.backgroundColor === 'beige',
                                                })}
                                            />
                                            <RevealElement>
                                                <Swiper
                                                    {...contentSliderParams}
                                                    onSwiper={setContentSlider}
                                                    controller={{
                                                        control: imageSlider,
                                                    }}
                                                >
                                                    {filteredRecords?.map((product, index) => {
                                                        const productExcerpt = product?.productGroup
                                                            ?.excerpt
                                                            ? product.productGroup.excerpt.replace(
                                                                  /<\/?[^>]+(>|$)/g,
                                                                  ''
                                                              )
                                                            : '';
                                                        const { productName } =
                                                            product?.productGroup;
                                                        return (
                                                            <SwiperSlide
                                                                className={
                                                                    styles.contentSliderSlide
                                                                }
                                                                key={index}
                                                            >
                                                                <h3
                                                                    className={cx(
                                                                        styles.productTitle,
                                                                        'u-a4 u-fw-bold'
                                                                    )}
                                                                >
                                                                    {productName}
                                                                </h3>
                                                                <p
                                                                    className={cx(
                                                                        styles.productDescription,
                                                                        'u-b0'
                                                                    )}
                                                                >
                                                                    {productExcerpt}
                                                                    {/*{product?.productGroup*/}
                                                                    {/*    ?.additionalExcerptInfo && (*/}
                                                                    {/*    <InfoTooltip*/}
                                                                    {/*        content={*/}
                                                                    {/*            product.productGroup*/}
                                                                    {/*                .additionalExcerptInfo*/}
                                                                    {/*        }*/}
                                                                    {/*    />*/}
                                                                    {/*)}*/}
                                                                </p>
                                                                {productLinkLabel && (
                                                                    <LocalizedUrl
                                                                        countries={
                                                                            product.options
                                                                                .countries
                                                                        }
                                                                        render={url => (
                                                                            <UnderlineLink
                                                                                className={cx(
                                                                                    styles.productLink,
                                                                                    'u-b1 u-fw-500'
                                                                                )}
                                                                                to={url}
                                                                                tpLink="internal"
                                                                            >
                                                                                {productLinkLabel}
                                                                            </UnderlineLink>
                                                                        )}
                                                                    />
                                                                )}
                                                            </SwiperSlide>
                                                        );
                                                    })}
                                                </Swiper>
                                            </RevealElement>
                                        </div>
                                        {filteredRecords?.length > 1 && (
                                            <div className={styles.arrows}>
                                                <RevealElement>
                                                    <ArrowButton
                                                        type="left"
                                                        onClick={prevSlide}
                                                        className={styles.arrow}
                                                    />
                                                    <ArrowButton
                                                        type="right"
                                                        onClick={nextSlide}
                                                        className={styles.arrow}
                                                    />
                                                </RevealElement>
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.imageWrapper}>
                                        <Swiper
                                            {...imageSliderParams}
                                            onSwiper={setImageSlider}
                                            controller={{
                                                control: contentSlider,
                                            }}
                                        >
                                            {filteredRecords.map((product, index) => (
                                                <SwiperSlide
                                                    key={index}
                                                    className={styles.imageSliderSlide}
                                                >
                                                    <div>
                                                        <ParallaxElement
                                                            percentage={
                                                                getViewportSize() <= 600 ? 20 : 30
                                                            }
                                                            end="+=250%"
                                                            className={cx(
                                                                styles.image,
                                                                'u-lazy js-lazy'
                                                            )}
                                                            data-bg={getImageSize(
                                                                product?.productGroup?.image
                                                                    ?.sourceUrl,
                                                                product?.productGroup?.image
                                                                    ?.mediaDetails?.sizes,
                                                                'large'
                                                            )}
                                                        />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <div
                                            className={cx(styles.mobileShape, {
                                                [styles.lightBeige]:
                                                    layout.backgroundColor === 'beige',
                                            })}
                                        />
                                    </div>
                                </div>
                            );
                        }}
                    />
                )}
                {contentType === 'custom' && productsCustom?.length > 0 && (
                    <div className={styles.featuredProductsSliderModule}>
                        <div className={styles.content}>
                            <RevealElement>
                                <h2 className={cx(styles.title, 'u-a5 u-fw-bold')}>{title}</h2>
                            </RevealElement>
                            <div className={styles.holder}>
                                <div
                                    className={cx(styles.shape, {
                                        [styles.lightBeige]: layout.backgroundColor === 'beige',
                                    })}
                                />
                                <RevealElement>
                                    <Swiper
                                        {...contentSliderParams}
                                        onSwiper={setContentSlider}
                                        controller={{
                                            control: imageSlider,
                                        }}
                                    >
                                        {productsCustom?.map((product, index) => {
                                            const description = product?.description
                                                ? product.description.replace(/<\/?[^>]+(>|$)/g, '')
                                                : null;
                                            return (
                                                <SwiperSlide
                                                    className={styles.contentSliderSlide}
                                                    key={index}
                                                >
                                                    <h3
                                                        className={cx(
                                                            styles.productTitle,
                                                            'u-a4 u-fw-bold'
                                                        )}
                                                    >
                                                        {product.title}
                                                    </h3>
                                                    <p
                                                        className={cx(
                                                            styles.productDescription,
                                                            'u-b0'
                                                        )}
                                                    >
                                                        {description}
                                                    </p>
                                                    {product?.url && product?.label && (
                                                        <UnderlineLink
                                                            className={cx(
                                                                styles.productLink,
                                                                'u-b1 u-fw-500'
                                                            )}
                                                            to={product.url}
                                                            tpLink="internal"
                                                            target={
                                                                product.newTab ? '_blank' : '_self'
                                                            }
                                                        >
                                                            {product.label}
                                                        </UnderlineLink>
                                                    )}
                                                </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </RevealElement>
                            </div>
                            {productsCustom?.length > 1 && (
                                <div className={styles.arrows}>
                                    <RevealElement>
                                        <ArrowButton
                                            type="left"
                                            onClick={prevSlide}
                                            className={styles.arrow}
                                        />
                                        <ArrowButton
                                            type="right"
                                            onClick={nextSlide}
                                            className={styles.arrow}
                                        />
                                    </RevealElement>
                                </div>
                            )}
                        </div>
                        <div className={styles.imageWrapper}>
                            <Swiper
                                {...imageSliderParams}
                                onSwiper={setImageSlider}
                                controller={{
                                    control: contentSlider,
                                }}
                            >
                                {productsCustom?.map((product, index) => (
                                    <SwiperSlide key={index} className={styles.imageSliderSlide}>
                                        <div>
                                            <ParallaxElement
                                                percentage={getViewportSize() <= 600 ? 20 : 30}
                                                end="+=250%"
                                                className={cx(styles.image, 'u-lazy js-lazy')}
                                                data-bg={getImageSize(
                                                    product?.image?.sourceUrl,
                                                    product?.image?.mediaDetails?.sizes,
                                                    'large'
                                                )}
                                            />
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div
                                className={cx(styles.mobileShape, {
                                    [styles.lightBeige]: layout.backgroundColor === 'beige',
                                })}
                            />
                        </div>
                    </div>
                )}
            </Container>
        </Section>
    );
});
