import React from 'react';
import cx from 'classnames';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import styles from './DefaultPage.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    containerType?: 'narrow' | 'wide';
    title: string;
}

const DefaultPage = React.forwardRef<HTMLDivElement, Props>(
    ({ children, title, containerType, ...rest }, ref) => (
        <div ref={ref} className={styles.main} {...rest}>
            <Section aria-label={title} alignment="" bgColor="">
                <Container type={containerType}>
                    <div className={styles.title}>
                        <RevealElement>
                            <h1 className={cx('u-a5 u-fw-700')}>{title}</h1>
                        </RevealElement>
                    </div>
                    <RevealElement>
                        <div>{children}</div>
                    </RevealElement>
                </Container>
            </Section>
        </div>
    )
);

export default DefaultPage;
