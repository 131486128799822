import React from 'react';

// region Modules
import BenefitsModule from './Benefits';
import ClientListLogoModule from './ClientListLogo';
import FeaturedArticlesModule from './FeaturedArticles';
import FeaturedProductsModule from './FeaturedProducts';
import FeaturedProductsSliderModule from './FeaturedProductsSlider';
import FeaturedTopicCtaModule from './FeaturedTopicCta';
import FullWidthMediaModule from './FullWidthMedia';
import FullWidthMediaParallaxModule from './FullWidthMediaParallax';
import HorizontalOvalImageSliderModule from './HorizontalOvalImageSlider';
import HorizontalSliderModule from './HorizontalSlider';
import InstagramModule from './Instagram';
import NextProductModule from './NextProduct';
import RelatedAthletesModule from './RelatedAthletes';
import StatisticsModule from './Statistics';
import VerticalDoubleImageFeaturedModule from './VerticalDoubleImageFeatured';
import VerticalImageFeaturedModule from './VerticalImageFeatured';
import VerticalImageFeaturedParallaxModule from './VerticalImageFeaturedParallax';
import VerticalImageFeaturedSliderModule from './VerticalImageFeaturedSlider';
import VerticalImageTestimonialDoubleSliderModule from './VerticalImageTestimonialDoubleSlider';
import VerticalImageTestimonialSliderModule from './VerticalImageTestimonialSlider';
import HorizontalNumberedFeaturesModule from './HorizontalNumberedFeatures';
import VerticalNumberedFeaturesModule from './VerticalNumberedFeatures';
import VideoComparisonModule from './VideoComparison';
import LegalDisclaimerModule from './LegalDisclaimer';
import { GeneralModules } from './GeneralModuleBlocks.enums';
import { isAvailableInCountry } from 'utils/isAvailableInCountry';
import { useAppContext } from 'context';
// endregion

type Layout = {
    [key in GeneralModules]: React.ReactNode;
};

export type LayoutData = {
    generalSettings?: {
        moduleCountries?: string[];
    };
    [key: string]: any;
};

interface Props {
    layoutData: LayoutData;
}

export const GeneralModuleBlocks: React.FC<Props> = ({ layoutData }) => {
    const { countryCode: currentCountryCode } = useAppContext();
    const layoutType = layoutData?.__typename as GeneralModules;
    const moduleCountries = layoutData?.generalSettings?.moduleCountries;
    const moduleAvailableInCountry = isAvailableInCountry(currentCountryCode, moduleCountries);

    if (!moduleAvailableInCountry) {
        return null;
    }

    const Default = () => (
        <p>
            In <strong>GeneralModuleBlocks</strong> the mapping of component{' '}
            <strong>{layoutType || 'unknown'}</strong> is missing.
        </p>
    );

    /**
     * Mapping the fieldGroupName(s) to our components
     */
    const layouts: Layout = {
        [GeneralModules.BenefitsModule]: BenefitsModule,
        [GeneralModules.ClientListLogoModule]: ClientListLogoModule,
        [GeneralModules.FeaturedArticlesModule]: FeaturedArticlesModule,
        [GeneralModules.FeaturedProductsModule]: FeaturedProductsModule,
        [GeneralModules.FeaturedProductsSliderModule]: FeaturedProductsSliderModule,
        [GeneralModules.FeaturedTopicCtaModule]: FeaturedTopicCtaModule,
        [GeneralModules.FullWidthMediaModule]: FullWidthMediaModule,
        [GeneralModules.FullWidthMediaParallaxModule]: FullWidthMediaParallaxModule,
        [GeneralModules.HorizontalOvalImageSliderModule]: HorizontalOvalImageSliderModule,
        [GeneralModules.HorizontalSliderModule]: HorizontalSliderModule,
        [GeneralModules.NextProductModule]: NextProductModule,
        [GeneralModules.RelatedAthletesModule]: RelatedAthletesModule,
        [GeneralModules.StatisticsModule]: StatisticsModule,
        [GeneralModules.VerticalDoubleImageFeaturedModule]: VerticalDoubleImageFeaturedModule,
        [GeneralModules.VerticalImageFeaturedModule]: VerticalImageFeaturedModule,
        [GeneralModules.VerticalImageFeaturedParallaxModule]: VerticalImageFeaturedParallaxModule,
        [GeneralModules.VerticalImageFeaturedSliderModule]: VerticalImageFeaturedSliderModule,
        [GeneralModules.VerticalImageTestimonialDoubleSliderModule]:
            VerticalImageTestimonialDoubleSliderModule,
        [GeneralModules.VerticalImageTestimonialSliderModule]: VerticalImageTestimonialSliderModule,
        [GeneralModules.HorizontalNumberedFeaturesModule]: HorizontalNumberedFeaturesModule,
        [GeneralModules.VerticalNumberedFeaturesModule]: VerticalNumberedFeaturesModule,
        [GeneralModules.VideoComparisonModule]: VideoComparisonModule,
        [GeneralModules.InstagramModule]: InstagramModule,
        [GeneralModules.LegalDisclaimerModule]: LegalDisclaimerModule,
    };

    const ComponentTag = layouts[layoutType] ? (layouts[layoutType] as React.ElementType) : Default;

    return <ComponentTag {...layoutData} />;
};
