import React from 'react';
import { useLocalizedUrl } from 'context/useLocalizedUrl';
import { CountryFitline } from 'types/wordpress';

interface Props {
    countries: CountryFitline[];
    render: (url: string | undefined) => React.ReactElement;
}

export const LocalizedUrl: React.FunctionComponent<Props> = ({ countries, render }) => {
    const url = useLocalizedUrl(countries);
    return render(url);
};
