import React, { createRef, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { gsap } from 'gsap';
import cx from 'classnames';
import SwiperCore, { A11y, Parallax, Scrollbar, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppContext } from 'context';
import { LocalizedButton } from 'context/LocalizedButton';
import { imagesLoaded } from 'utils/imagesLoaded';
import { getViewportSize } from 'utils/getViewportSize';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxElement from 'components/Layout/ParallaxElement';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import UnderlineLink from 'components/common/UnderlineLink';
import { getMatchingCountryModel } from 'utils/getMatchingCountryModel';
import Athlete from 'components/common/Athlete';
import styles from './HorizontalOvalImageSlider.module.scss';

SwiperCore.use([Scrollbar, A11y, Parallax]);

type Props = any;

export type backgroundColors = 'white' | 'beige';

export type waveTopColors = 'white' | 'beige';

export const HorizontalOvalImageSliderModule = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            layout,
            eyebrow,
            title,
            description,
            button,
            contentType,
            athletes,
            slider,
            ovalImages = true,
        },
        ref
    ) => {
        const { backgroundColor } = layout;
        const swiperScrollBarRef = createRef<HTMLDivElement>();
        const [sliderParams, setSliderParams] = useState<SwiperOptions>();
        const contentContainerRef = useRef(null);
        const swiperContainerRef = useRef(null);

        useEffect(() => {
            if (swiperScrollBarRef?.current && !sliderParams) {
                setSliderParams({
                    loop: false,
                    slidesPerView: 'auto',
                    longSwipes: false,
                    watchSlidesProgress: true,
                    watchSlidesVisibility: true,
                    centeredSlides: true,
                    parallax: true,
                    speed: 700,
                    scrollbar: {
                        el: swiperScrollBarRef.current || '',
                        hide: false,
                        draggable: true,
                        snapOnRelease: true,
                    },
                });
            }
        }, [swiperScrollBarRef, sliderParams]);

        const slideController = useCallback(
            swiper => {
                if (getViewportSize() > 800 && contentContainerRef?.current) {
                    if (swiper.realIndex >= 1) {
                        gsap.to(contentContainerRef.current, {
                            duration: 0.3,
                            autoAlpha: 0,
                        });
                        gsap.to(swiperContainerRef.current, {
                            x: '-2.08333vw',
                        });
                    } else {
                        gsap.to(contentContainerRef.current, {
                            duration: 0.3,
                            autoAlpha: 1,
                            delay: 0.2,
                        });
                        gsap.to(swiperContainerRef.current, {
                            x: '4.16667vw',
                        });
                    }
                }
            },
            [contentContainerRef]
        );

        if (!athletes && !slider) return null;

        return (
            <Section ref={ref} modifier="overflow" bgColor={layout.backgroundColor}>
                <TopWave color={layout?.waveTopColor as TopWaveColors} />
                <Container>
                    <div className={cx(styles.horizontalOvalImageSlider)}>
                        <div className={styles.contentWrapper}>
                            <div className={cx(styles.leftCol)} ref={contentContainerRef}>
                                <RevealElement>
                                    <p className={cx('u-b2 u-uppercase', styles.eyebrow)}>
                                        {eyebrow}
                                    </p>
                                </RevealElement>
                                <RevealElement>
                                    <h2 className={cx('u-a5', styles.title)}>{title}</h2>
                                </RevealElement>
                                <RevealElement>
                                    <p className={cx('u-b0', styles.description)}>{description}</p>
                                </RevealElement>
                                <LocalizedButton
                                    data={button}
                                    render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                        <RevealElement>
                                            <div className={styles.btn}>
                                                <UnderlineLink
                                                    to={btnUrl}
                                                    target={btnTarget}
                                                    tpLink={dataTp}
                                                    className="u-b1 u-fw-500"
                                                >
                                                    {btnLabel}
                                                </UnderlineLink>
                                            </div>
                                        </RevealElement>
                                    )}
                                />
                            </div>
                            {contentType === 'relationship' && (
                                <HorizontalOvalImageRelationship
                                    ref={swiperContainerRef}
                                    sliderParams={sliderParams}
                                    athletes={athletes}
                                    onSlideChange={swiper => slideController(swiper)}
                                    ovalImages={ovalImages}
                                />
                            )}
                            {contentType === 'custom' && (
                                <HorizontalOvalImageCustom
                                    ref={swiperContainerRef}
                                    sliderParams={sliderParams}
                                    slides={slider}
                                    onSlideChange={swiper => slideController(swiper)}
                                />
                            )}
                        </div>
                        <div className={styles.scrollbar}>
                            <div
                                ref={swiperScrollBarRef}
                                className={cx(styles.scrollbarItem, {
                                    [styles.scrollbarItemInverted]: backgroundColor === 'white',
                                })}
                            />
                        </div>
                    </div>
                </Container>
            </Section>
        );
    }
);

type HorizontalOvalImageRelationshipProps = {
    athletes: any;
    sliderParams: SwiperOptions;
    onSlideChange?: (swiper) => void;
    ovalImages?: boolean;
};

export const HorizontalOvalImageRelationship = React.forwardRef<
    HTMLDivElement,
    HorizontalOvalImageRelationshipProps
>(({ athletes, sliderParams, onSlideChange, ovalImages }, ref) => {
    const { countryCode, language } = useAppContext();

    const filteredAthletes = useMemo(
        () =>
            athletes.reduce((acc, athlete) => {
                const sportName = athlete?.sportTypes?.nodes[0]?.name || '';
                const athleteCountry = {
                    code: athlete?.athletePostType?.nationality[0] || '',
                    name: athlete?.athletePostType?.nationality[1] || '',
                };
                const url = getMatchingCountryModel(
                    athlete.options.countries,
                    countryCode,
                    language
                )?.url;
                if (url) {
                    return [
                        ...acc,
                        {
                            id: athlete.id,
                            link: url,
                            title: athlete?.options?.title,
                            subtitle: athlete?.athletePostType?.subtitle || null,
                            sport: sportName,
                            country: athleteCountry,
                            photos: {
                                vertical: {
                                    sourceUrl: athlete?.athletePostType?.photoVertical || '',
                                    altText: athlete?.options?.title || '',
                                    mediaDetails: {
                                        sizes: [],
                                    },
                                },
                                horizontal: {
                                    sourceUrl: athlete?.athletePostType?.photoHorizontal || '',
                                    altText: athlete?.options?.title || '',
                                    mediaDetails: {
                                        sizes: [],
                                    },
                                },
                            },
                        },
                    ];
                }
                return [...acc];
            }, []),
        [athletes, countryCode, language]
    );

    if (athletes?.length > 0 && sliderParams && filteredAthletes?.length > 0) {
        return (
            <div ref={ref} className={cx(styles.rightCol)}>
                <Swiper
                    {...sliderParams}
                    className={styles.slideItemParent}
                    onSlideChange={swiper => {
                        if (onSlideChange && typeof onSlideChange === 'function')
                            onSlideChange(swiper);
                    }}
                >
                    {filteredAthletes?.map((athlete, index) => (
                        <SwiperSlide
                            key={`${index}__horizontalOvalImageItem`}
                            className={styles.slideItemWrapper}
                        >
                            <div className={cx(styles.slideItem)}>
                                <Athlete
                                    addTp
                                    align="center"
                                    lazy={false}
                                    link={athlete.link}
                                    title={athlete.title}
                                    subtitle={athlete.subtitle}
                                    sport={athlete.sport}
                                    country={athlete.country}
                                    photos={athlete.photos}
                                    imageHasBorderRadius={!ovalImages}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        );
    }
    return null;
});

type HorizontalOvalImageCustomProps = {
    slides: any;
    sliderParams: SwiperOptions;
    onSlideChange?: (swiper) => void;
    ovalImages?: boolean; // todo: non-oval images not implemented for custom image
};

export const HorizontalOvalImageCustom = React.forwardRef<
    HTMLDivElement,
    HorizontalOvalImageCustomProps
>(({ slides, sliderParams, onSlideChange, ovalImages }, ref) => {
    if (slides?.length > 0 && sliderParams) {
        return (
            <div ref={ref} className={cx(styles.rightCol)}>
                <Swiper
                    {...sliderParams}
                    className={styles.slideItemParent}
                    onSlideChange={swiper => {
                        if (onSlideChange && typeof onSlideChange === 'function')
                            onSlideChange(swiper);
                    }}
                >
                    {slides?.map((item, index) => (
                        <SwiperSlide
                            key={`${index}__horizontalOvalImageItem`}
                            className={styles.slideItemWrapper}
                        >
                            <div className={cx(styles.slideItem)}>
                                {item?.images?.horizontal && item?.images?.vertical && (
                                    <>
                                        <figure
                                            className={cx(
                                                styles.slidePicFigure,
                                                styles.slidePicFigureDesktop
                                            )}
                                        >
                                            <ParallaxElement
                                                percentage={-10}
                                                className={cx(styles.slidePic)}
                                                style={{
                                                    backgroundImage: `url('${item.images.vertical}')`,
                                                }}
                                            />
                                            <img
                                                alt=""
                                                src={item.images.vertical}
                                                onLoad={imagesLoaded}
                                            />
                                        </figure>
                                        <figure
                                            className={cx(
                                                styles.slidePicFigure,
                                                styles.slidePicFigureMobile
                                            )}
                                        >
                                            <ParallaxElement
                                                percentage={-10}
                                                className={cx(styles.slidePic)}
                                                style={{
                                                    backgroundImage: `url('${item.images.horizontal}')`,
                                                }}
                                            />
                                            <img
                                                alt=""
                                                src={item.images.horizontal}
                                                onLoad={imagesLoaded}
                                            />
                                        </figure>
                                    </>
                                )}
                                {item?.title && (
                                    <h3 className={cx('u-a3', styles.slideName)}>{item.title}</h3>
                                )}
                                {item?.description && (
                                    <span className={cx('u-b1', styles.slideSport)}>
                                        {item.description}
                                    </span>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        );
    }
    return null;
});
