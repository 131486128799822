import React, { useEffect, useRef, useState, useCallback } from 'react';
import cx from 'classnames';
import Modal from 'react-modal';
import Button from 'components/common/Button';
import Icon from 'components/Icons';
import styles from './VideoModal.module.scss';

type Props = {
    button: string;
    title?: string;
    video: string;
    attachTo?: string;
};

export const VideoModal = React.forwardRef<HTMLDivElement, Props>(
    ({ button, video, title, attachTo = 'body' }, ref) => {
        const [opened, setOpened] = useState(false);

        useEffect(() => {
            Modal.setAppElement(attachTo);
        }, [attachTo]);

        const $video = useRef<HTMLVideoElement>(null);

        const toggleVideoPlaying = useCallback(() => {
            if ($video?.current) {
                const $element = $video.current;
                $element.paused ? $element.play() : $element.pause();
            }
        }, [$video]);

        return (
            <>
                <Button
                    styleType="filled"
                    color="red"
                    icon="play"
                    onClick={() => setOpened(true)}
                    className={cx(styles.btn)}
                >
                    {button}
                </Button>
                <Modal
                    isOpen={opened}
                    closeTimeoutMS={300}
                    overlayClassName={{
                        base: styles.overlay,
                        afterOpen: styles.overlayOpened,
                        beforeClose: styles.overlayClose,
                    }}
                    className={{
                        base: styles.main,
                        afterOpen: styles.mainOpened,
                        beforeClose: styles.mainClose,
                    }}
                >
                    <div className={cx(styles.container)}>
                        <ul className={cx(styles.header)}>
                            {title ? (
                                <li>
                                    <span className={cx('u-b2', styles.title)}>{title}</span>
                                </li>
                            ) : (
                                <li>&nbsp;</li>
                            )}
                            <li>
                                <button
                                    className={cx('u-b2', styles.btnClose)}
                                    onClick={() => setOpened(false)}
                                >
                                    <span>Close</span>
                                    <Icon name="close" className={cx(styles.btnIcon)} />
                                </button>
                            </li>
                        </ul>
                        <video
                            loop
                            ref={$video}
                            preload="auto"
                            playsInline
                            muted={false}
                            autoPlay={true}
                            className={cx(styles.video)}
                            onClick={toggleVideoPlaying}
                        >
                            <source src={video} type="video/mp4" />
                        </video>
                    </div>
                </Modal>
            </>
        );
    }
);
