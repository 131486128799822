import React from 'react';
import cx from 'classnames';
import { LocalizedButton } from 'context/LocalizedButton';
import { imagesLoaded } from 'utils/imagesLoaded';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './Promo.module.scss';

type Props = any;

export const PromoModule = React.forwardRef<HTMLDivElement, Props>(
    ({ title, quote, person, viewMore }, ref) => (
        <div className={styles.box} ref={ref}>
            <h3 className={cx('u-a2', styles.title)}>{title}</h3>
            <blockquote
                className="u-content-editor"
                dangerouslySetInnerHTML={{
                    __html: quote,
                }}
            />
            <div className={styles.person}>
                {person?.image && (
                    <figure className={styles.figure}>
                        <span
                            className={cx(styles.image, 'u-lazy js-lazy')}
                            data-bg={person.image}
                        />
                        <img src={person.image} alt={person?.name || ''} onLoad={imagesLoaded} />
                    </figure>
                )}
                <div className={styles.personDesc}>
                    <div>
                        <span className={cx('u-a1', styles.personName)}>{person.name}</span>
                        {person.sport && (
                            <span className={cx('u-b2', styles.personSport)}>{person.sport}</span>
                        )}
                    </div>
                </div>
            </div>
            {viewMore && (
                <LocalizedButton
                    data={viewMore}
                    render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                        <div className={styles.btn}>
                            <UnderlineLink
                                to={btnUrl}
                                target={btnTarget}
                                tpLink={dataTp}
                                className="u-b1 u-fw-500"
                            >
                                {btnLabel}
                            </UnderlineLink>
                        </div>
                    )}
                />
            )}
        </div>
    )
);
