import React, { useMemo } from 'react';
import cx from 'classnames';
import Select from 'react-dropdown-select';
import Icon from 'components/Icons';
import styles from './SelectDropdown.module.scss';
import decodeHtmlEntity from 'utils/decodeHtmlEntity';

export type OptionsObj = {
    id?: string;
    value: string;
    label: string;
    disabled?: boolean;
    hidden?: boolean;
};

export interface SelectDropdownProps {
    options: OptionsObj[];
    label?: string;
    placeholder?: string;
    prefix?: string;
    onChange: (values: OptionsObj[]) => void;
    onDropdownClose?: () => void;
    selectedValue: string | string[] | OptionsObj[];
    prependAllOption?: boolean;
    showAllInList?: boolean;
    allOptionLabel?: string;
    showSearch?: boolean;
    hasError?: boolean;
    variation?: 'default' | 'smaller';
    multi?: boolean;
    disabled?: boolean;
    filled?: boolean;
}

export const SelectDropdown = ({
    options,
    label = null,
    placeholder,
    prefix,
    onChange,
    onDropdownClose = () => null,
    selectedValue,
    prependAllOption = true,
    showAllInList = true,
    allOptionLabel = 'All',
    showSearch = true,
    hasError = false,
    variation = 'default',
    multi = false,
    disabled = false,
    filled = false,
}: SelectDropdownProps) => {
    const optionsClone = useMemo(() => {
        let optionsClone = [...options];

        if (prependAllOption) {
            optionsClone = [
                {
                    value: '',
                    disabled: false,
                    label: allOptionLabel,
                    hidden: !showAllInList,
                },
                ...optionsClone,
            ];
        }

        return optionsClone;
    }, [options, prependAllOption, allOptionLabel, showAllInList]);

    const values = useMemo(() => {
        let values = [];
        let selectedOption = [];

        if (typeof selectedValue === 'string') {
            selectedOption = optionsClone.filter(option => selectedValue === option.value);
        } else if (typeof selectedValue === 'object') {
            selectedValue?.forEach(item => {
                const isValue = item.value || item;
                const newItem = optionsClone.find(option => isValue === option.value);
                selectedOption.push(newItem);
            });
        }

        if (selectedOption.length === 0 && prependAllOption) {
            values = [optionsClone[0]];
        } else if (selectedOption) {
            values = [...selectedOption];
        }

        return values;
    }, [optionsClone, prependAllOption, selectedValue]);

    return (
        <div
            className={cx(
                styles.dropdown,
                variation !== 'default' ? styles[`dropdown--${variation}`] : null,
                disabled ? styles['dropdown--disabled'] : null,
                filled ? styles['dropdown--filled'] : null
            )}
        >
            <Select
                className={hasError ? 'has-error' : ''}
                backspaceDelete={false}
                contentRenderer={({ props }) => {
                    const hasValue = values.length > 0 || prependAllOption;
                    return hasValue ? (
                        <span className="u-b0">
                            {label ? (
                                <>{decodeHtmlEntity(label)}</>
                            ) : (
                                <>
                                    {prefix && `${prefix}: `}
                                    {decodeHtmlEntity(props.values[0]?.label)}
                                </>
                            )}
                        </span>
                    ) : (
                        <span className={cx('u-b0', styles.placeholder)}>{placeholder}</span>
                    );
                }}
                dropdownRenderer={({ props, state, methods }) => {
                    const regexp = new RegExp(state.search, 'i');
                    const results = props.options.filter((item: OptionsObj) =>
                        regexp.test(item[props.searchBy] || item.label)
                    );
                    const allSelectedValues = state.values;
                    return (
                        <div>
                            {showSearch && (
                                <input
                                    type="text"
                                    value={state.search}
                                    onChange={methods.setSearch}
                                    placeholder={label || placeholder || ''}
                                    // autoFocus={true}
                                    className={cx('u-b0', 'react-dropdown-custom-search')}
                                />
                            )}
                            {!showSearch && label !== null && (
                                <span className={cx('u-b0', 'react-dropdown-custom-search')}>
                                    {label}
                                </span>
                            )}
                            {results?.length > 0 && (
                                <div
                                    className={cx('react-dropdown-select-scrollbox', {
                                        'react-dropdown-select-scrollbox-multi': multi,
                                    })}
                                >
                                    {results?.map((option: OptionsObj) => (
                                        // eslint-disable-next-line
                                        <div
                                            key={option?.id || option.value}
                                            onClick={() => methods.addItem(option)}
                                            className={cx('react-dropdown-select-item', 'u-b0', {
                                                'react-dropdown-select-item-multi': multi,
                                                'react-dropdown-select-item-active':
                                                    allSelectedValues[0]?.value === option.value &&
                                                    !multi,
                                                'react-dropdown-select-item-hidden': option?.hidden,
                                            })}
                                        >
                                            {multi && (
                                                <span
                                                    className={cx(
                                                        'react-dropdown-select-item-checkbox',
                                                        {
                                                            'is-checked': allSelectedValues.find(
                                                                item =>
                                                                    item?.value === option?.value
                                                            ),
                                                        }
                                                    )}
                                                />
                                            )}
                                            <span>{decodeHtmlEntity(option.label)}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    );
                }}
                dropdownHandleRenderer={({ state }) => (
                    <Icon
                        name="arrow-down"
                        className={`react-dropdown-select-arrow ${
                            state.dropdown ? 'is-active' : ''
                        }`}
                    />
                )}
                dropdownGap={0}
                dropdownHandle={true}
                dropdownPosition="auto"
                multi={multi}
                onChange={onChange}
                onDropdownClose={onDropdownClose}
                options={optionsClone}
                placeholder={placeholder || ''}
                searchable={false}
                values={values}
            />
        </div>
    );
};
