import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { CustomEase } from 'gsap/dist/CustomEase';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CustomEase);
    CustomEase.create('customDefault', 'M0,0 C0.25,0.1 0.25,1 1,1');
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    delay?: number;
    className?: string;
}

export const RevealElement = React.forwardRef<HTMLDivElement, Props>(
    ({ className, delay, children, ...rest }, ref) => {
        const mergedClasses = cx('u-reveal-element', className);

        const revealElements = useRef(null);

        useEffect(() => {
            if (revealElements?.current) {
                gsap.to(revealElements.current, {
                    scrollTrigger: {
                        trigger: revealElements.current || '',
                        start: 'top 100%',
                        end: '+=500',
                        toggleActions: 'play none play none',
                        scrub: false,
                        markers: false,
                    },
                    autoAlpha: 1,
                    y: 0,
                    duration: 1.3,
                    delay: delay || 0,
                    ease: 'customDefault',
                });
            }
        }, [delay, revealElements]);

        return (
            <span ref={revealElements} className={mergedClasses} {...rest}>
                {children}
            </span>
        );
    }
);
