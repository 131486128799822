import Icon from 'components/Icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './RecipeModal.module.scss';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import TopWave from '@root/components/Layout/TopWave';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/dist/CustomEase';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import ShareBox from '@root/components/common/ShareBox';
import { TranslationKeyString } from '@root/context/TranslationKey';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(CustomEase);
    CustomEase.create('customDefault', 'M0,0 C0.25,0.1 0.25,1 1,1');
}

interface Props {
    closeModal: Function;
    recipeId: string;
    title: string;
    instructions: string;
    imageUrl: string;
    video?: string;
    mealType: string[];
    mealTime: string[];
    nutrition: {
        totalCalories?: string;
        totalCarbohydrates?: string;
        totalProteins?: string;
        totalFat?: string;
        servingCalories?: string;
        servingCarbohydrates?: string;
        servingProteins?: string;
        servingFat?: string;
    };
}

export const RecipeModal = React.forwardRef<HTMLDivElement, Props>(
    ({ title, instructions, imageUrl, closeModal, recipeId, video, mealTime, nutrition }, ref) => {
        const $modal = useRef<HTMLDivElement | null>(null);

        useEffect(() => {
            if ($modal?.current) {
                disableBodyScroll($modal.current.firstChild as HTMLElement);
                gsap.to($modal.current, {
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'customDefault',
                });
            }

            return () => clearAllBodyScrollLocks();
        }, [$modal]);

        const { shareTitle, shareUrl } = useMemo(() => {
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                const shareTitle = `${title} - ${document.title}`;
                const shareUrl = new URL(window.location.href);
                shareUrl.searchParams.set('recipeId', recipeId);

                return { shareTitle, shareUrl: shareUrl.toString() };
            } else {
                return { shareTitle: title, shareUrl: '' };
            }
        }, [title, recipeId]);

        // region Video modal
        const $video = useRef<HTMLVideoElement>(null);
        const [videoPlaying, setVideoPlaying] = useState(false);

        const playHandler = useCallback(() => {
            setVideoPlaying(true);
        }, []);

        const pauseHandler = useCallback(() => {
            setVideoPlaying(false);
        }, []);

        useEffect(() => {
            if ($video?.current) {
                const videoElement = $video.current;
                videoPlaying ? videoElement.play() : videoElement.pause();

                videoElement.addEventListener('play', playHandler);
                videoElement.addEventListener('pause', pauseHandler);

                return () => {
                    videoElement.removeEventListener('play', playHandler);
                    videoElement.removeEventListener('pause', pauseHandler);
                };
            }
        }, [videoPlaying, $video, playHandler, pauseHandler]);

        if (video) {
            return (
                <div className={cx(styles.container, styles.isVideo)} ref={$modal}>
                    <div className={cx(styles.modal, { [styles.isVideoPlaying]: videoPlaying })}>
                        <div className={styles.header}>
                            <div>
                                <button className={styles.btnClose} onClick={ev => closeModal(ev)}>
                                    <span className={cx('u-b2 u-uppercase')}>Close</span>
                                    <Icon name="close" className={styles.btnIcon} />
                                </button>
                            </div>
                        </div>
                        <div className={styles.videoContainer}>
                            <div className={styles.videoOverlay}>
                                <div className={styles.videoText}>
                                    <p className={cx('u-b1', styles.eyebrow)}>
                                        {mealTime
                                            ?.map(time => TranslationKeyString(time))
                                            ?.join(' / ')}
                                    </p>
                                    <h2 className={cx('u-a5', styles.title)}>{title}</h2>
                                </div>
                                <button
                                    type="button"
                                    className={styles.videoPlay}
                                    onClick={() =>
                                        setVideoPlaying(wasVideoPlaying => !wasVideoPlaying)
                                    }
                                >
                                    <Icon name="video-play" className={styles.videoPlay} />
                                </button>
                                <div className={styles.share}>
                                    <ShareBox
                                        direction="horizontal"
                                        transparent
                                        title={shareTitle}
                                        url={shareUrl}
                                    />
                                </div>
                            </div>
                            <video
                                ref={$video}
                                preload="auto"
                                playsInline
                                muted={false}
                                autoPlay={true}
                                controls
                                className={cx(styles.video)}
                                onClick={() => setVideoPlaying(wasVideoPlaying => !wasVideoPlaying)}
                            >
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            );
        }
        // endregion

        return (
            <div className={styles.container} ref={$modal}>
                <div className={styles.modal}>
                    <div className={styles.header}>
                        <div>
                            <button className={styles.btnClose} onClick={ev => closeModal(ev)}>
                                <span className={cx('u-b2 u-uppercase')}>Close</span>
                                <Icon name="close" className={styles.btnIcon} />
                            </button>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <TopWave color="beige" />
                        <div className={styles.share}>
                            <ShareBox
                                direction="vertical"
                                transparent
                                title={shareTitle}
                                url={shareUrl}
                            />
                        </div>
                        <div>
                            <ParallaxOvalImage
                                lazy={false}
                                parallax={false}
                                hasBorderRadius
                                parallaxBackground={imageUrl}
                            />
                        </div>
                        <div className={styles.right}>
                            <p className={cx('u-b1', styles.eyebrow)}>
                                {mealTime?.map(time => TranslationKeyString(time))?.join(' / ')}
                            </p>
                            <h2 className={cx('u-a5', styles.title)}>{title}</h2>

                            <div className={cx('u-a2', styles.instructions)}>
                                <div dangerouslySetInnerHTML={{ __html: instructions }}></div>
                            </div>

                            <div className={styles.tableWrapper}>
                                <table className={cx('u-b1', styles.table)}>
                                    <thead className="u-b2">
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>{TranslationKeyString('calories')}</th>
                                            <th>{TranslationKeyString('carbohydrates')}</th>
                                            <th>{TranslationKeyString('proteins')}</th>
                                            <th>{TranslationKeyString('fat')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{TranslationKeyString('total')}</td>
                                            <td>{nutrition.totalCalories}</td>
                                            <td>{nutrition.totalCarbohydrates}</td>
                                            <td>{nutrition.totalProteins}</td>
                                            <td>{nutrition.totalFat}</td>
                                        </tr>
                                        <tr>
                                            <td>{TranslationKeyString('totalPerServing')}</td>
                                            <td>{nutrition.servingCalories}</td>
                                            <td>{nutrition.servingCarbohydrates}</td>
                                            <td>{nutrition.servingProteins}</td>
                                            <td>{nutrition.servingFat}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);
