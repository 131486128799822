import React from 'react';
import cx from 'classnames';
import { imagesLoaded } from 'utils/imagesLoaded';
import { getImageSize } from 'utils/getImageSize';
import Icon from 'components/Icons';
import styles from './ImageQuote.module.scss';

type Props = any;

export const ImageQuoteModule = React.forwardRef<HTMLDivElement, Props>(
    ({ image, quote, name }, ref) => (
        <div className={styles.box} ref={ref}>
            <figure className={styles.figure}>
                <img
                    src={getImageSize(image.sourceUrl, image.mediaDetails.sizes, 'large')}
                    alt={image.altText || ''}
                    onLoad={imagesLoaded}
                />
            </figure>
            <blockquote className={styles.quote}>
                <p className={cx('u-a2', styles.text)}>
                    <Icon className={styles.textQuoteSign} name="quote" />
                    {quote}
                </p>
                {name && <span className={cx('u-b0', styles.name)}>{name}</span>}
            </blockquote>
        </div>
    )
);
