import React from 'react';
import cx from 'classnames';
import { imagesLoaded } from 'utils/imagesLoaded';
import { getImageSize } from 'utils/getImageSize';
import { LocalizedButton } from 'context/LocalizedButton';
import Container from 'components/Layout/Container';
import Section from 'components/Layout/Section';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxElement from 'components/Layout/ParallaxElement';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './VerticalDoubleImageFeatured.module.scss';

export type VerticalDoubleImageFeaturedModuleProps = any;

export type backgroundColors = 'white' | 'beige';

export type alignments = 'content-left' | 'content-right';

export const VerticalDoubleImageFeaturedModule = React.forwardRef<
    HTMLDivElement,
    VerticalDoubleImageFeaturedModuleProps
>(
    (
        {
            layout,
            eyebrow,
            title,
            description,
            links,
            logo,
            bigImage,
            smallImage,
            ovalImages = true,
        },
        ref
    ) => (
        <Section ref={ref} alignment={layout?.alignment} bgColor={layout?.backgroundColor}>
            <Container>
                <div
                    className={cx(styles.verticalDoubleImageFeaturedModule, {
                        [styles.contentRight]: layout?.alignment === 'content-right',
                    })}
                >
                    <div className={styles.content}>
                        <div className={styles.contentInner}>
                            <RevealElement>
                                {logo && logo.sourceUrl && (
                                    <img
                                        src={getImageSize(
                                            logo.sourceUrl,
                                            logo.mediaDetails.sizes,
                                            'medium'
                                        )}
                                        className={styles.logo}
                                        alt={logo.altText || 'Logo'}
                                        onLoad={imagesLoaded}
                                    />
                                )}
                            </RevealElement>
                            <RevealElement>
                                <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>{eyebrow}</p>
                            </RevealElement>
                            <RevealElement>
                                <h3 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h3>
                            </RevealElement>
                            <RevealElement>
                                <div
                                    className={cx(
                                        styles.description,
                                        'u-content-editor u-content-editor--module'
                                    )}
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            </RevealElement>
                            {links?.length > 0 && (
                                <RevealElement>
                                    {links?.map((link, index) => (
                                        <LocalizedButton
                                            key={index}
                                            data={link.button}
                                            render={(
                                                btnLabel,
                                                btnUrl,
                                                btnTarget,
                                                btnType,
                                                dataTp
                                            ) => (
                                                <UnderlineLink
                                                    to={btnUrl}
                                                    target={btnTarget}
                                                    tpLink={dataTp}
                                                    className="u-b1 u-fw-500"
                                                >
                                                    {btnLabel}
                                                </UnderlineLink>
                                            )}
                                        />
                                    ))}
                                </RevealElement>
                            )}
                        </div>
                    </div>
                    <div className={styles.images}>
                        <div
                            className={cx(styles.bigImage, styles.bigImageDesktop, {
                                [styles.hasBorderRadius]: !ovalImages,
                            })}
                        >
                            <ParallaxElement
                                percentage={10}
                                className={cx(styles.imageBackground, 'u-lazy js-lazy')}
                                data-bg={bigImage?.vertical}
                            />
                        </div>
                        <div
                            className={cx(styles.bigImage, styles.bigImageMobile, {
                                [styles.hasBorderRadius]: !ovalImages,
                            })}
                        >
                            <ParallaxElement
                                percentage={10}
                                className={cx(styles.imageBackground, 'u-lazy js-lazy')}
                                data-bg={bigImage?.horizontal}
                            />
                        </div>
                        <div
                            className={cx(styles.smallImage, {
                                [styles.hasBorderRadius]: !ovalImages,
                            })}
                        >
                            <ParallaxElement
                                percentage={10}
                                className={cx(styles.imageBackground, 'u-lazy js-lazy')}
                                data-bg={smallImage?.vertical}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </Section>
    )
);
