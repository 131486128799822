import React, { ReactChild, useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import IframeResizer from 'iframe-resizer-react';
import styles from './SubscriptionModal.module.scss';

interface SubscriptionModalProps {
    label: string;
    src?: string;
    hubspot?: {
        portalId: string;
        formId: string;
    };
    render: (label, open: Dispatch<SetStateAction<boolean>>) => ReactChild;
}

const SubscriptionModal = ({ label, src, hubspot, render }: SubscriptionModalProps) => {
    const $wrapper = useRef<HTMLDivElement | null>(null);
    const [isOpened, setOpened] = useState(false);
    const [isMounted, setMounted] = useState(false);

    useEffect(() => {
        if ($wrapper?.current) {
            isOpened ? disableBodyScroll($wrapper.current) : clearAllBodyScrollLocks();
        }
    }, [$wrapper, isOpened]);

    useEffect(() => {
        setMounted(true);
        return () => {
            setMounted(false);
        };
    }, [setMounted]);

    useEffect(() => {
        if (
            isMounted &&
            hubspot?.portalId &&
            hubspot?.formId &&
            window &&
            typeof window !== 'undefined'
        ) {
            // @ts-ignore
            window?.hbspt?.forms.create({
                region: 'eu1',
                portalId: hubspot.portalId,
                formId: hubspot.formId,
                target: '#hubspot-form-wrapper',
            });
        }
    }, [isMounted, hubspot]);

    return (
        <>
            {render && typeof render === 'function' && render(label, setOpened)}
            {isMounted &&
                createPortal(
                    <div
                        ref={$wrapper}
                        className={cx(styles.main, {
                            [styles.isOpened]: isOpened,
                        })}
                    >
                        <div className={styles.inner}>
                            {src && (
                                <IframeResizer
                                    log={process.env.NODE_ENV === 'development'}
                                    className={styles.iframe}
                                    src={src}
                                />
                            )}
                            {hubspot?.portalId && hubspot?.formId && (
                                <div id="hubspot-form-wrapper" className={styles.hubspot} />
                            )}
                        </div>
                        <button
                            type="button"
                            className={styles.overlay}
                            onClick={() => setOpened(false)}
                        />
                    </div>,
                    document.querySelector('body')
                )}
        </>
    );
};

export default SubscriptionModal;
