import {
    alignments,
    backgroundColors,
    VerticalImageTestimonialDoubleSliderModule,
    waveTopColors,
} from './VerticalImageTestimonialDoubleSlider';

export type { backgroundColors, alignments, waveTopColors };

export default VerticalImageTestimonialDoubleSliderModule;
