import React from 'react';
import cx from 'classnames';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import styles from './LegalDisclaimer.module.scss';

export type LegalDisclaimerProps = any;

export const LegalDisclaimer = React.forwardRef<HTMLDivElement, LegalDisclaimerProps>(
    ({ items, layout, generalSettings }, ref) =>
        items?.length > 0 ? (
            <Section ref={ref} modifier="" alignment="" bgColor={layout?.backgroundColor}>
                <Container type="narrow">
                    <ul className={cx('u-b3', styles.list)}>
                        {items.map((item, index) => (
                            <li key={`dislacimer__${index}`}>{item?.description}</li>
                        ))}
                    </ul>
                </Container>
            </Section>
        ) : null
);
