import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context';
import DefaultPage from 'components/Layout/DefaultPage';
import Button from 'components/common/Button';
import styles from './Default.module.scss';

const Default = ({ options, defaultPage }) => {
    const { cookies } = useAppContext();

    if (!defaultPage?.content) return null;

    return (
        <DefaultPage title={options?.title} containerType="narrow">
            <div className={styles.container}>
                <div
                    className="u-content-editor"
                    dangerouslySetInnerHTML={{
                        __html: defaultPage?.content,
                    }}
                />
                {defaultPage?.cookieModalButton && cookies?.setOpened && (
                    <div className={styles.btn}>
                        <Button
                            className="u-b0"
                            onClick={() => {
                                if (typeof cookies?.setOpened === 'function') {
                                    cookies?.setOpened(true);
                                }
                            }}
                        >
                            {defaultPage.cookieModalButton}
                        </Button>
                    </div>
                )}
            </div>
        </DefaultPage>
    );
};

Default.defaultProps = {
    options: {},
    defaultPage: {},
};

Default.propTypes = {
    options: PropTypes.oneOfType([PropTypes.object]),
    defaultPage: PropTypes.oneOfType([PropTypes.object]),
};

export default Default;
