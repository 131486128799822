import { getViewportSize } from './getViewportSize';
import { MediaSize } from 'types/wordpress';

export const getImageSize = (
    sourceUrl: string,
    imageSizes: readonly Pick<MediaSize, 'name' | 'sourceUrl'>[],
    name: string
) => {
    let imageSourceUrl = sourceUrl;
    if (imageSizes !== null) {
        const imageArray = imageSizes?.filter(item => {
            if (getViewportSize() <= 800 && name === 'custom-1920x') {
                return item.name === 'custom-800x';
            } else if (getViewportSize() <= 800 && name === 'custom-2400x') {
                return item.name === 'custom-800x';
            }
            return item.name === name;
        });
        if (imageArray && imageArray.length > 0) {
            imageSourceUrl = imageArray[0].sourceUrl;
        }
    }
    return imageSourceUrl;
};
