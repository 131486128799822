import React, { useMemo } from 'react';
import cx from 'classnames';
import { LocalizedButton } from 'context/LocalizedButton';
import { getMatchingCountryModel } from 'utils/getMatchingCountryModel';
import { getViewportSize } from 'utils/getViewportSize';
import { getImageSize } from 'utils/getImageSize';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import RevealElement from 'components/Layout/RevealElement';
import ArticleItem, { generateCategories } from 'components/common/ArticleItem';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './FeaturedArticles.module.scss';
import { useAppContext } from '@root/context';

export type BackgroundColor = 'white' | 'beige';

type Props = any;

export const FeaturedArticlesModule = React.forwardRef<HTMLDivElement, Props>(
    ({ layout, eyebrow, title, button, articles }, ref) => {
        const { backgroundColor, waveTopColor } = layout;
        const { countryCode, language } = useAppContext();

        const filteredArticles = useMemo(
            () =>
                articles?.reduce((acc, article) => {
                    const { headlineImage, headlineImageCrop } = article.magazineSingleContent;
                    const url = getMatchingCountryModel(
                        article.options.countries,
                        countryCode,
                        language
                    )?.url;
                    if (url) {
                        return [
                            ...acc,
                            {
                                id: article.id,
                                url,
                                image: {
                                    sourceUrl:
                                        headlineImageCrop ||
                                        getImageSize(
                                            headlineImage.sourceUrl,
                                            headlineImage.mediaDetails.sizes,
                                            'large'
                                        ),
                                    altText:
                                        headlineImage?.altText ||
                                        article?.magazineSingleContent?.titleoverride ||
                                        article.options.title,
                                },
                                eyebrow: generateCategories(article?.magazineCategories?.nodes),
                                title:
                                    article?.magazineSingleContent?.titleoverride ||
                                    article.options.title,
                                date: article.date,
                            },
                        ];
                    }
                    return [...acc];
                }, []),
            [articles, countryCode, language]
        );

        return (
            <Section ref={ref} modifier="" alignment="" bgColor={backgroundColor}>
                <TopWave color={waveTopColor as TopWaveColors} />
                <Container>
                    <div className={styles.featuredArticlesModule}>
                        <div className={cx(styles.top)}>
                            {eyebrow && (
                                <RevealElement>
                                    <h3 className={cx(styles.eyebrow, 'u-b2')}>{eyebrow}</h3>
                                </RevealElement>
                            )}
                            <RevealElement>
                                <h2 className={cx(styles.title, 'u-a5')}>{title}</h2>
                            </RevealElement>
                            <LocalizedButton
                                data={button}
                                render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                    <RevealElement>
                                        <UnderlineLink
                                            to={btnUrl}
                                            target={btnLabel}
                                            tpLink={dataTp}
                                            className="u-b1 u-fw-500"
                                        >
                                            {btnLabel}
                                        </UnderlineLink>
                                    </RevealElement>
                                )}
                            />
                        </div>
                        {filteredArticles?.length > 0 && (
                            <div className={cx(styles.items)}>
                                <div className={cx(styles.itemrow)}>
                                    {filteredArticles.map((article, index) => (
                                        <div key={article.id} className={cx(styles.itemcol)}>
                                            <RevealElement
                                                delay={
                                                    getViewportSize() <= 600
                                                        ? 0
                                                        : Number(`0.${2 * index}`)
                                                }
                                            >
                                                <ArticleItem
                                                    addTp
                                                    link={article?.url}
                                                    image={article?.image}
                                                    eyebrow={article?.eyebrow}
                                                    title={article.title}
                                                    date={article.date}
                                                    variation="big"
                                                />
                                            </RevealElement>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </Container>
            </Section>
        );
    }
);
