import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Default,
    Modules,
    FAQ,
    Contact,
    PresentationalProductList,
    MagazinesArchive,
    AthletesArchive,
    Sport,
    MagazineSingle,
    TestimonialsArchive,
    RecipesArchive,
} from 'templates/index';

interface Props {
    data?: any;
    template?: string | null;
}

export const Template = ({ data, template }: Props) => {
    const templateName = useMemo(() => data?.template?.templateName, [data]);

    const layouts = {
        Default: Default,
        Modules: Modules,
        'Home Template': Modules,
        'Modules Template': Modules,
        'FAQ Template': FAQ,
        'Contact Template': Contact,
        'Presentational Product List Template': PresentationalProductList,
        'Magazines Template': MagazinesArchive,
        'Sports Template': AthletesArchive,
        'Testimonials Template': TestimonialsArchive,
        'Recipes Template': RecipesArchive,
        Sport: Sport,
        Magazine: MagazineSingle,
        Testimonials: TestimonialsArchive,
        'Partners Template': AthletesArchive,
    };

    const NoTemplate = useCallback(
        () => (
            <div style={{ padding: '200px 50px' }}>
                No template for <strong>{templateName}</strong>
            </div>
        ),
        [templateName]
    );

    const TemplateTag = template
        ? layouts[template] || NoTemplate
        : layouts[templateName] || NoTemplate;

    return <TemplateTag {...data} template={templateName} />;
};

Template.defaultProps = {
    data: {},
    template: null,
};

Template.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object]),
    template: PropTypes.string,
};

export default Template;
