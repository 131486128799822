import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Recipes.module.scss';
import { HeaderModuleBlocks } from '@root/components/Modules/header/HeaderModuleBlocks';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Container from '@root/components/Layout/Container';
import RevealElement from '@root/components/Layout/RevealElement';
import TranslationKey, { TranslationKeyString } from '@root/context/TranslationKey';
import { useRouter } from 'next/router';
import Testimonial from '@root/components/common/Testimonial';
import RecipeModal from '@root/components/common/RecipeModal';
import SelectDropdown from '@root/components/common/SelectDropdown';
import removeEmptyObjectKeys from '@root/utils/removeEmptyObjectKeys';

const Recipes = ({ headerGroup, recipesModulesGroup }) => {
    const recipes = useMemo(() => recipesModulesGroup.rModules, [recipesModulesGroup.rModules]);

    const [query, setQuery] = useState({
        mealType: '',
        mealTime: '',
        recipeId: undefined,
    });

    const router = useRouter();

    const [activeRecipe, setActiveRecipe] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const fetchedSharedRecipe = useRef(false);

    const recipeMapper = useCallback(
        (item, index) => ({
            id: index,
            name: item?.recipeTitle,
            instructions: item?.recipeInstructions ?? '',
            video: item?.video || '',
            photos: {
                vertical: {
                    sourceUrl: item?.verticalPhoto || '',
                    altText: item?.recipeTitle || '',
                },
                horizontal: {
                    sourceUrl: item?.horizontalPhoto || '',
                    altText: item?.recipeTitle || '',
                },
            },
            mealType: item?.mealType ?? [],
            mealTime: item?.mealTime ?? [],
            nutrition: {
                totalCalories: item?.totalCalories ?? 0,
                totalCarbohydrates: item?.totalCarbohydrates ?? 0,
                totalProteins: item?.totalProteins ?? 0,
                totalFat: item?.totalFat ?? 0,
                servingCalories: item?.servingCalories ?? 0,
                servingCarbohydrates: item?.servingCarbohydrates ?? 0,
                servingProteins: item?.servingProteins ?? 0,
                servingFat: item?.servingFat ?? 0,
            },
        }),
        []
    );

    const filteredRecipes = useMemo(
        () =>
            recipes?.map(recipeMapper)?.filter(recipe => {
                const { mealTime, mealType } = query;
                if (!mealTime && !mealType) return true;

                let filter = true;
                if (mealTime) filter = recipe.mealTime?.includes(mealTime);
                if (mealType) filter = filter && recipe.mealType?.includes(mealType);
                return filter;
            }) || [],
        [recipes, recipeMapper, query]
    );

    useEffect(() => {
        // only fetch shared recipe once
        if (fetchedSharedRecipe.current) return;
        if (router?.query?.recipeId && recipes.length) {
            fetchedSharedRecipe.current = true;
            const targetRecipelId = Number(decodeURIComponent(router.query.recipeId as string));

            // try finding recipe in currently loaded dataset
            const targetRecipe = recipes[targetRecipelId];
            if (targetRecipe) {
                setActiveRecipe(recipeMapper(targetRecipe, targetRecipelId));
                setShowModal(true);
            }
        }
    }, [router, recipes, recipeMapper]);

    const openModal = useCallback((ev, recipe) => {
        if (ev) {
            ev.preventDefault();
            setActiveRecipe(recipe);
            setShowModal(true);
        }
    }, []);

    const closeModal = useCallback(
        ev => {
            if (ev) {
                ev.preventDefault();
                setShowModal(false);
                setActiveRecipe(null);
                setQuery(existingQuery => {
                    //remove recipe id from url/query
                    const routerQuery = router.query;
                    delete routerQuery.recipeId;
                    router.push(
                        {
                            pathname: router.pathname,
                            query: { ...routerQuery },
                        },
                        undefined,
                        { shallow: true }
                    );

                    return { ...existingQuery, recipeId: null };
                });
            }
        },
        [router]
    );

    const escKey = useCallback(
        ev => {
            if (ev.key === 13 || ev.key === 'Escape') closeModal(ev);
        },
        [closeModal]
    );

    useEffect(() => {
        document.addEventListener('keydown', escKey, false);
        return () => {
            document.removeEventListener('keydown', escKey, false);
        };
    }, [escKey]);

    useEffect(() => {
        setQuery({
            mealTime: router?.query?.mealTime?.toString() ?? '',
            mealType: router?.query?.mealType?.toString() ?? '',
            recipeId: router?.query?.recipeId || null,
        });
    }, [router.query, setQuery]);

    const changeLocationParams = useCallback(
        (query, params) => {
            const paramsString = new URLSearchParams(
                removeEmptyObjectKeys({
                    ...query,
                    ...params,
                })
            ).toString();
            // push to router without page refresh
            if (location) {
                router.push(`${location.pathname}/?${paramsString}`, undefined, { shallow: true });
            }
        },
        [router]
    );

    const onMealTimeChange = useCallback(
        value => {
            const firstValue = value && value[0];
            changeLocationParams(query, {
                mealTime: firstValue?.value,
                recipeId: query.recipeId,
            });
        },
        [query, changeLocationParams]
    );

    const onMealTypeChange = useCallback(
        value => {
            const firstValue = value && value[0];
            changeLocationParams(query, {
                mealType: firstValue?.value,
                recipeId: query.recipeId,
            });
        },
        [query, changeLocationParams]
    );

    return (
        <>
            {headerGroup?.headerModules?.map((block, index) => (
                <HeaderModuleBlocks key={index} layoutData={block as unknown} />
            ))}

            <section
                className={cx(styles.main, {
                    [`${styles.mainNoHeader}`]: headerGroup?.headerModules.length < 1,
                })}
            >
                <Container>
                    <header className={styles.header}>
                        <div className={styles.headerCol}>
                            <RevealElement>
                                <h2 className="u-a4">
                                    <TranslationKey name="recipes" />
                                </h2>
                            </RevealElement>
                        </div>
                        <div className={cx(styles.headerCol, styles.headerColNoPadding)}>
                            <div className={styles.dropdownCol}>
                                <SelectDropdown
                                    options={[
                                        {
                                            value: 'breakfast',
                                            label: TranslationKeyString('breakfast'),
                                        },
                                        {
                                            value: 'lunch',
                                            label: TranslationKeyString('lunch'),
                                        },
                                        {
                                            value: 'dinner',
                                            label: TranslationKeyString('dinner'),
                                        },
                                    ]}
                                    selectedValue={query?.mealTime}
                                    placeholder={TranslationKeyString('typeToFilter')}
                                    prefix={TranslationKeyString('mealTime')}
                                    allOptionLabel={TranslationKeyString('all')}
                                    onChange={onMealTimeChange}
                                />
                            </div>
                            <div className={styles.dropdownCol}>
                                <SelectDropdown
                                    options={[
                                        {
                                            value: 'vegetarian',
                                            label: TranslationKeyString('vegetarian'),
                                        },
                                        {
                                            value: 'vegan',
                                            label: TranslationKeyString('vegan'),
                                        },
                                        {
                                            value: 'lactosefree',
                                            label: TranslationKeyString('lactosefree'),
                                        },
                                    ]}
                                    selectedValue={query?.mealType}
                                    placeholder={TranslationKeyString('typeToFilter')}
                                    prefix={TranslationKeyString('mealType')}
                                    allOptionLabel={TranslationKeyString('all')}
                                    onChange={onMealTypeChange}
                                />
                            </div>
                        </div>
                    </header>

                    {showModal ? (
                        <RecipeModal
                            closeModal={closeModal}
                            recipeId={activeRecipe?.id}
                            title={activeRecipe?.name ?? ''}
                            instructions={activeRecipe?.instructions ?? ''}
                            imageUrl={activeRecipe?.photos?.vertical?.sourceUrl}
                            video={activeRecipe?.video}
                            mealTime={activeRecipe?.mealTime}
                            mealType={activeRecipe?.mealType}
                            nutrition={activeRecipe?.nutrition}
                        />
                    ) : null}

                    {filteredRecipes?.length > 0 ? (
                        <div className={cx(styles.container)}>
                            <div className={styles.row}>
                                {filteredRecipes?.map(recipe => (
                                    <div className={styles.col} key={recipe.id}>
                                        <RevealElement>
                                            <Testimonial
                                                title={recipe.name}
                                                align="center"
                                                openModal={event => openModal(event, recipe)}
                                                images={{
                                                    vertical: recipe.photos.vertical.sourceUrl,
                                                    horizontal: recipe.photos.horizontal.sourceUrl,
                                                }}
                                                isVideo={Boolean(recipe?.video)}
                                            />
                                        </RevealElement>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p className={cx(styles.emptyTitle, 'u-a3')}>
                            <TranslationKey name="noResults" />
                        </p>
                    )}
                </Container>
            </section>
        </>
    );
};

Recipes.defaultProps = {
    headerGroup: {},
    generalModulesGroup: {},
};

Recipes.propTypes = {
    headerGroup: PropTypes.oneOfType([PropTypes.object]),
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
};
export default Recipes;
