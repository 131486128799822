import React from 'react';
import cx from 'classnames';
import Icon from 'components/Icons';
import styles from './ArrowButton.module.scss';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    type?: 'left' | 'right';
    disabled?: boolean;
}

export const ArrowButton = React.forwardRef<HTMLButtonElement, Props>(
    ({ type, className, ...rest }, ref) => (
        <button
            ref={ref}
            className={cx(
                styles.arrowButton,
                {
                    [styles.right]: type === 'right',
                },
                className
            )}
            {...rest}
        >
            <span className={styles.iconWrapper}>
                <Icon
                    name={type === 'left' ? 'arrow-left' : 'arrow-right'}
                    className={styles.icon}
                />
            </span>
            <span className={styles.iconWrapper}>
                <Icon
                    name={type === 'left' ? 'arrow-left' : 'arrow-right'}
                    className={styles.icon}
                />
            </span>
        </button>
    )
);
