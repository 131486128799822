import React, { useMemo } from 'react';
import { useAppContext } from 'context/App';
import { getMatchingCountryModel } from 'utils/getMatchingCountryModel';

interface Props {
    data: {
        addTp?: boolean;
        linktype: string;
        label: string;
        linkexternal: any;
        linkinternal: any;
    };
    render: (
        label: string,
        url: string,
        target: string,
        linkType: string,
        tpLink: false | 'internal' | 'external'
    ) => React.ReactElement;
}

export const LocalizedButton: React.FunctionComponent<Props> = ({ data, render }) => {
    const { language, countryCode } = useAppContext();
    const button = useMemo(() => {
        let label = '';
        let url = '';
        let target = '';
        let tpLink: false | 'internal' | 'external' = false;

        if (data?.linktype === 'internal' && data?.linkinternal) {
            label = data?.label || '';
            tpLink = 'internal';
            url = getMatchingCountryModel(
                data?.linkinternal?.options?.countries,
                countryCode,
                language
            )?.url;
        } else {
            tpLink = data?.addTp ? 'external' : false;
            label = data?.label || data?.linkexternal?.title || '';
            target = data?.linkexternal?.target || '';
            url = data?.linkexternal?.url;
        }

        return {
            label,
            url,
            target,
            tpLink,
        };
    }, [data, language, countryCode]);

    return button?.url
        ? render(button?.label, button?.url, button?.target, data.linktype, button?.tpLink)
        : null;
};
