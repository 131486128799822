import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import axios from 'axios';
import Cookies from 'js-cookie';
import { COOKIES } from 'components/CookiesGDPR';
import { getViewportSize } from 'utils/getViewportSize';
import { imagesLoaded } from 'utils/imagesLoaded';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import UnderlineLink from 'components/common/UnderlineLink';
import Icon from 'components/Icons';
import styles from './Instagram.module.scss';

export type InstagramModuleProps = {
    eyebrow: string;
    title: string;
    link: {
        title: string;
        url: string;
    };
};

export const InstagramModule = React.forwardRef<HTMLDivElement, InstagramModuleProps>(
    ({ eyebrow, title, link }, ref) => {
        const [data, setData] = useState([]);

        const cookieStorageKey = COOKIES.instagram.name;
        const cookieTimeout = COOKIES.instagram.expires;

        const { title: linkTitle = null, url: linkUrl = null } = { ...link };

        useEffect(() => {
            // read localStorage data
            const storageData = JSON.parse(localStorage.getItem(cookieStorageKey));

            // fetch from API only if cookie expired and no storage data
            if (Cookies.get(cookieStorageKey) !== 'true' || !storageData) {
                Cookies.set(cookieStorageKey, 'true', {
                    expires: cookieTimeout,
                });

                // fetch and set data from API
                axios
                    .get('/api/instagram')
                    .then(response => {
                        setData(response.data);
                        imagesLoaded();
                        localStorage.setItem(cookieStorageKey, JSON.stringify(response));
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
            // set from data from localStorage
            else {
                setData(storageData?.data);
                imagesLoaded();
            }
        }, [cookieTimeout, cookieStorageKey]);

        return (
            <Section ref={ref} modifier="" alignment="" bgColor="red">
                <Container type="narrow">
                    <div className={styles.instagramModule}>
                        <div className={cx(styles.header, 'u-text-center')}>
                            {eyebrow && (
                                <RevealElement>
                                    <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>
                                        {eyebrow}
                                    </p>
                                </RevealElement>
                            )}
                            <RevealElement>
                                <h2 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h2>
                            </RevealElement>
                            {linkTitle && linkUrl && (
                                <RevealElement>
                                    <UnderlineLink
                                        to={linkUrl}
                                        color="white"
                                        target="_blank"
                                        className="u-b1 u-fw-500"
                                    >
                                        {linkTitle}
                                    </UnderlineLink>
                                </RevealElement>
                            )}
                        </div>
                        {data?.length > 0 && (
                            <div className={cx(styles.content)}>
                                {data
                                    .slice(0, getViewportSize() <= 800 ? 4 : 5)
                                    .map((post, index) => (
                                        <div className={styles.post} key={index}>
                                            <RevealElement
                                                delay={
                                                    getViewportSize() <= 600
                                                        ? 0
                                                        : Number(`0.${2 * index}`)
                                                }
                                            >
                                                <a
                                                    href={post.permalink}
                                                    className={styles.postInner}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <div
                                                        className={styles.postImage}
                                                        style={{
                                                            backgroundImage: `url('${
                                                                post.media_type === 'VIDEO'
                                                                    ? post.thumbnail_url
                                                                    : post.media_url
                                                            }')`,
                                                        }}
                                                    />
                                                    <Icon
                                                        name="instagram"
                                                        className={styles.postIcon}
                                                    />
                                                </a>
                                            </RevealElement>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>
                </Container>
            </Section>
        );
    }
);
