import React, { createRef, useEffect, useState } from 'react';
import cx from 'classnames';
import SwiperCore, { A11y, Scrollbar, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BREAKPOINT_LG, BREAKPOINT_SM_MD } from 'utils/breakpoints';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import styles from './HorizontalSlider.module.scss';

SwiperCore.use([Scrollbar, A11y]);

export type backgroundColors = 'white' | 'beige';

type Props = any;

export const HorizontalSliderModule = React.forwardRef<HTMLDivElement, Props>(
    ({ layout, items }, ref) => {
        const background = layout?.backgroundColor;
        const swiperScrollBarRef = createRef<HTMLDivElement>();
        const [sliderParams, setSliderParams] = useState<SwiperOptions>();

        useEffect(() => {
            if (swiperScrollBarRef?.current && !sliderParams) {
                const breakpoints = {} as any;

                breakpoints[BREAKPOINT_SM_MD] = {
                    slidesPerView: 2,
                };

                breakpoints[BREAKPOINT_LG] = {
                    slidesPerView: 3,
                };

                setSliderParams({
                    loop: false,
                    slidesPerView: 1,
                    longSwipes: false,
                    watchSlidesProgress: true,
                    watchSlidesVisibility: true,
                    scrollbar: {
                        el: swiperScrollBarRef.current || '',
                        hide: false,
                        draggable: true,
                        snapOnRelease: true,
                    },
                    breakpoints,
                });
            }
        }, [swiperScrollBarRef, sliderParams]);

        return (
            <Section
                ref={ref}
                alignment=""
                bgColor=""
                modifier="overflow"
                className={cx(styles.section, styles[`section${background as backgroundColors}`])}
            >
                <Container>
                    {items?.length > 0 && sliderParams && (
                        <div className={styles.swiperContainer}>
                            <Swiper {...sliderParams}>
                                {items.map((item, index) => {
                                    const { eyebrow, title, text } = item;
                                    return (
                                        <SwiperSlide
                                            key={`${index}__horizontalSlide`}
                                            className={cx('swiper-slide')}
                                        >
                                            <div className={cx(styles.item)}>
                                                {item?.image && (
                                                    <span
                                                        style={{
                                                            backgroundImage: `url('${item.image}')`,
                                                        }}
                                                        className={cx(styles.pic, {
                                                            [`${styles.picFull}`]:
                                                                layout?.imageSize === 'full',
                                                        })}
                                                    />
                                                )}
                                                <span className={cx(styles.eyebrow, 'u-b2')}>
                                                    {eyebrow}
                                                </span>
                                                <h2 className={cx(styles.title, 'u-a3')}>
                                                    {title}
                                                </h2>
                                                <p className={cx('u-b0')}>{text}</p>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        </div>
                    )}

                    <div className={cx(styles.scrollbar)}>
                        <div
                            ref={swiperScrollBarRef}
                            className={cx(styles.scrollbarItem, {
                                [styles.scrollbarItemInverted]: background === 'white',
                            })}
                        />
                    </div>
                </Container>
            </Section>
        );
    }
);
