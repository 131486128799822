import React from 'react';
import cx from 'classnames';
import { getViewportSize } from 'utils/getViewportSize';
import { LocalizedButton } from 'context/LocalizedButton';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import ParallaxElement from 'components/Layout/ParallaxElement';
import RevealElement from 'components/Layout/RevealElement';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './VerticalNumberedFeatures.module.scss';

export type backgroundColors = 'white' | 'beige';

export type waveTopColors = 'white' | 'beige';

export type VerticalNumberedFeaturesModuleProps = any;

export const VerticalNumberedFeaturesModule = React.forwardRef<
    HTMLDivElement,
    VerticalNumberedFeaturesModuleProps
>(({ layout, eyebrow, title, description, features, parallax }, ref) => (
    <Section ref={ref} alignment="" bgColor={layout.backgroundColor}>
        <div className={styles.sectionBackgrounds}>
            {parallax?.left && (
                <ParallaxElement
                    percentage={-30}
                    className={cx(styles.sectionBackground, 'u-lazy js-lazy')}
                    data-bg={parallax.left}
                />
            )}
            {parallax?.right && (
                <ParallaxElement
                    percentage={30}
                    className={cx(styles.sectionBackground, 'u-lazy js-lazy')}
                    data-bg={parallax.right}
                />
            )}
        </div>
        <TopWave color={layout?.waveTopColor as TopWaveColors} />
        <Container>
            <div className={styles.verticalNumberedFeaturesModule}>
                <div className={styles.header}>
                    <RevealElement>
                        <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>{eyebrow}</p>
                    </RevealElement>
                    <RevealElement>
                        <h2 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h2>
                    </RevealElement>
                </div>
                <div className={styles.content}>
                    <div className={styles.contentColumn}>
                        <RevealElement>
                            <p className={cx(styles.description, 'u-b0')}>{description}</p>
                        </RevealElement>
                    </div>
                    <div className={styles.contentColumn}>
                        {features.map((feature, index) => (
                            <div className={styles.feature} key={index}>
                                <div className={styles.featureContent}>
                                    <RevealElement
                                        delay={
                                            getViewportSize() <= 600 ? 0 : Number(`0.${2 * index}`)
                                        }
                                    >
                                        <span className={cx(styles.featureNumber, 'u-b2')}>
                                            0{index + 1}
                                        </span>
                                        <h3 className={cx(styles.featureTitle, 'u-a3 u-fw-700')}>
                                            {feature.title}
                                        </h3>
                                        <div
                                            className="u-content-editor u-content-editor--module"
                                            dangerouslySetInnerHTML={{
                                                __html: feature.description,
                                            }}
                                        />
                                        {feature?.link && (
                                            <LocalizedButton
                                                data={feature.link}
                                                render={(
                                                    btnLabel,
                                                    btnUrl,
                                                    btnTarget,
                                                    btnType,
                                                    dataTp
                                                ) => (
                                                    <UnderlineLink
                                                        to={btnUrl}
                                                        target={btnTarget}
                                                        tpLink={dataTp}
                                                        className={cx(
                                                            styles.featureLink,
                                                            'u-b1 u-fw-500'
                                                        )}
                                                    >
                                                        {btnLabel}
                                                    </UnderlineLink>
                                                )}
                                            />
                                        )}
                                    </RevealElement>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Container>
    </Section>
));
