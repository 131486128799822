import React, { useEffect, useState, useMemo, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { LocalizedPageCollection } from 'context/LocalizedPageCollection';
import { LocalizedUrl } from 'context/LocalizedUrl';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { SwiperOptions } from 'swiper';
import Section from 'components/Layout/Section';
import TopWave from 'components/Layout/TopWave';
import Button from 'components/common/Button';
import ArrowButton from 'components/common/ArrowButton';
import Container from 'components/Layout/Container';
import { GeneralModuleBlocks } from 'components/Modules/general/GeneralModuleBlocks';
import { getImageSize } from 'utils/getImageSize';
import { lazyLoadImages } from 'utils/lazyLoad';
import styles from './PresentationalProductList.module.scss';

const PresentationalProductList = ({ generalModulesGroup, presentationalProductListPage }) => {
    const { sliderProducts } = presentationalProductListPage;

    const [activeSlide, setActiveSlide] = useState(0);
    const [contentSlider, setContentSlider] = useState<SwiperCore>();
    const [imageSlider, setImageSlider] = useState<SwiperCore>();
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const contentSliderParams: SwiperOptions = useMemo(
        () => ({
            effect: 'fade',
            loop: false,
            speed: 1700,
            fadeEffect: {
                crossFade: true,
            },
        }),
        []
    );

    const imageSliderParams: SwiperOptions = useMemo(
        () => ({
            loop: false,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
            speed: 1700,
            parallax: true,
        }),
        []
    );

    const nextSlide = useCallback(() => {
        if (contentSlider) contentSlider.slideNext();
    }, [contentSlider]);

    const prevSlide = useCallback(() => {
        if (contentSlider) contentSlider.slidePrev();
    }, [contentSlider]);

    const goToSlide = useCallback(
        index => {
            if (contentSlider) contentSlider.slideTo(index);
        },
        [contentSlider]
    );

    useEffect(() => {
        lazyLoadImages();
    }, [generalModulesGroup, presentationalProductListPage]);

    return (
        <LocalizedPageCollection
            records={sliderProducts}
            render={filteredRecords => (
                <Fragment>
                    <Section bgColor="beige" modifier="mobile-overflow">
                        <div className={cx(styles.topWave)}>
                            <div className={cx(styles.topWaveShape)}>
                                <TopWave color="smoke" />
                            </div>
                        </div>
                        <Container type="full-width">
                            <div className={styles.presentationalProductList}>
                                <div className={cx('custom-pagination', styles.customPagination)}>
                                    {filteredRecords?.map((item, index) => (
                                        <button
                                            key={`productBullet__${index}`}
                                            onClick={() => goToSlide(index)}
                                            className={cx(
                                                styles.customPaginationBullet,
                                                'u-b3',
                                                ' u-fw-500',
                                                {
                                                    'swiper-pagination-bullet-active':
                                                        index === activeSlide,
                                                }
                                            )}
                                        >
                                            {item?.options?.title}
                                        </button>
                                    ))}
                                </div>
                                <div className={styles.imageSlider}>
                                    <Swiper
                                        {...imageSliderParams}
                                        onSwiper={setImageSlider}
                                        onSlideChange={swiper =>
                                            setActiveSlide(swiper?.activeIndex || 0)
                                        }
                                        controller={{
                                            control: contentSlider,
                                        }}
                                    >
                                        <>
                                            {filteredRecords?.map((slide, index) => (
                                                <SwiperSlide
                                                    key={index}
                                                    className={cx(styles.imageSliderSlide)}
                                                >
                                                    <div className={styles.mainImage}>
                                                        <div
                                                            className={styles.mainImageFront}
                                                            style={{
                                                                backgroundImage: `url(${getImageSize(
                                                                    slide?.productGroup
                                                                        ?.layeredImageFront
                                                                        ?.sourceUrl,
                                                                    slide?.productGroup
                                                                        ?.layeredImageFront
                                                                        ?.mediaDetails?.sizes,
                                                                    'large'
                                                                )})`,
                                                            }}
                                                        />
                                                        <div
                                                            className={styles.mainImageBack}
                                                            style={{
                                                                backgroundImage: `url(${getImageSize(
                                                                    slide?.productGroup
                                                                        ?.layeredImageBack
                                                                        ?.sourceUrl,
                                                                    slide?.productGroup
                                                                        ?.layeredImageBack
                                                                        ?.mediaDetails?.sizes,
                                                                    'large'
                                                                )})`,
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={styles.secondaryImage}
                                                        style={{
                                                            backgroundImage: `url(${getImageSize(
                                                                slide?.productGroup
                                                                    ?.presentationalAdditionalImage
                                                                    ?.sourceUrl,
                                                                slide?.productGroup
                                                                    ?.presentationalAdditionalImage
                                                                    ?.mediaDetails?.sizes,
                                                                'large'
                                                            )})`,
                                                        }}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                            <span
                                                className={styles.imageSliderParallaxBg}
                                                slot="container-start"
                                                data-swiper-parallax="-50%"
                                                style={{
                                                    backgroundImage: `url(${presentationalProductListPage?.sliderBackgroundPattern.sourceUrl})`,
                                                }}
                                            />
                                        </>
                                    </Swiper>
                                </div>
                                <div className={styles.contentSlider}>
                                    <Swiper
                                        {...contentSliderParams}
                                        onSwiper={setContentSlider}
                                        controller={{
                                            control: imageSlider,
                                        }}
                                        onSlideChange={swiper =>
                                            setActiveSlideIndex(swiper.activeIndex)
                                        }
                                    >
                                        {filteredRecords?.map((slide, index) => (
                                            <SwiperSlide
                                                className={styles.contentSliderSlide}
                                                key={index}
                                            >
                                                <div className={styles.contentSliderWrapper}>
                                                    <div className={styles.contentSliderHeader}>
                                                        <p
                                                            className={cx(
                                                                styles.contentSliderFraction,
                                                                'u-b2 u-uppercase'
                                                            )}
                                                        >
                                                            {index < 9
                                                                ? `0${index + 1}`
                                                                : `${index + 1}`}
                                                            /
                                                            {filteredRecords.length < 9
                                                                ? `0${filteredRecords.length}`
                                                                : `${filteredRecords.length}`}
                                                        </p>
                                                    </div>
                                                    <h2
                                                        className={cx(
                                                            styles.contentSliderTitle,
                                                            'u-a6 u-fw-700'
                                                        )}
                                                    >
                                                        {slide.options.title}
                                                    </h2>
                                                    <p
                                                        className={cx(
                                                            styles.contentSliderDescription,
                                                            'u-b0'
                                                        )}
                                                    >
                                                        {slide.productGroup.excerpt}
                                                    </p>
                                                    <div className={styles.contentSliderButton}>
                                                        {presentationalProductListPage.sliderButtonLabel &&
                                                            slide.link && (
                                                                <LocalizedUrl
                                                                    countries={
                                                                        slide.options.countries
                                                                    }
                                                                    render={url => (
                                                                        <Button
                                                                            className={
                                                                                'u-b0 u-fw-500'
                                                                            }
                                                                            to={url}
                                                                            tpLink="internal"
                                                                        >
                                                                            {
                                                                                presentationalProductListPage.sliderButtonLabel
                                                                            }
                                                                        </Button>
                                                                    )}
                                                                />
                                                            )}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    {filteredRecords?.length > 1 && (
                                        <div className={styles.arrows}>
                                            <ArrowButton
                                                type="left"
                                                onClick={prevSlide}
                                                className={styles.arrow}
                                                disabled={activeSlideIndex === 0}
                                            />
                                            <ArrowButton
                                                type="right"
                                                onClick={nextSlide}
                                                className={styles.arrow}
                                                disabled={
                                                    activeSlideIndex === filteredRecords.length - 1
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Container>
                    </Section>
                    {generalModulesGroup?.modules?.map((block, index) => (
                        <GeneralModuleBlocks key={index} layoutData={block as unknown} />
                    ))}
                </Fragment>
            )}
        />
    );
};

PresentationalProductList.defaultProps = {
    generalModulesGroup: {},
    presentationalProductListPage: {},
};

PresentationalProductList.propTypes = {
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
    presentationalProductListPage: PropTypes.oneOfType([PropTypes.object]),
};

export default PresentationalProductList;
