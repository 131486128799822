import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { imagesLoaded } from 'utils/imagesLoaded';
import { getImageSize } from 'utils/getImageSize';
import { BottomWave, BottomWaveColors } from 'components/Layout/BottomWave/BottomWave';
import Icon from 'components/Icons';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import Section from 'components/Layout/Section';
import ParallaxElement from 'components/Layout/ParallaxElement';
import styles from './FullWidthMediaParallax.module.scss';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
}

type Props = any;

export const FullWidthMediaParallaxModule = React.forwardRef<HTMLDivElement, Props>(
    ({ layout, mediaType, video, videoPoster, image, backgrounds }, ref) => (
        <Section modifier="" alignment="" bgColor="" ref={ref} className={cx(styles.main)}>
            <TopWave color={layout?.waveTopColor as TopWaveColors} />
            {mediaType === 'image' ? (
                <FullWidthMediaParallaxImage image={image} />
            ) : (
                <FullWidthMediaParallaxVideo
                    video={video}
                    videoPoster={videoPoster}
                    color={layout?.waveTopColor}
                />
            )}
            <div className={cx(styles.background)}>
                <ParallaxElement
                    percentage={-10}
                    className={cx(styles.backgroundImage, 'u-lazy js-lazy')}
                    data-bg={getImageSize(
                        backgrounds?.main?.sourceUrl,
                        backgrounds?.main?.mediaDetails?.sizes,
                        'custom-1920x'
                    )}
                />
            </div>
            <ParallaxElement
                percentage={75}
                className={cx(styles.parallaxImageLeft, 'u-lazy js-lazy')}
                data-bg={getImageSize(
                    backgrounds?.parallaxLeft?.sourceUrl,
                    backgrounds?.parallaxLeft?.mediaDetails?.sizes,
                    'custom-500x'
                )}
            />
            <ParallaxElement
                percentage={-75}
                className={cx(styles.parallaxImageRight, 'u-lazy js-lazy')}
                data-bg={getImageSize(
                    backgrounds?.parallaxRight?.sourceUrl,
                    backgrounds?.parallaxRight?.mediaDetails?.sizes,
                    'custom-500x'
                )}
            />
            <BottomWave color={layout?.waveBottomColor as BottomWaveColors} />
        </Section>
    )
);

const FullWidthMediaParallaxImage = ({ image, alt = '' }) =>
    image ? (
        <figure className={styles.mediaWrapper}>
            <ParallaxElement percentage={10} className={styles.media}>
                <picture onLoad={imagesLoaded}>
                    <img src={image} alt={alt} onLoad={imagesLoaded} />
                </picture>
            </ParallaxElement>
        </figure>
    ) : null;

const FullWidthMediaParallaxVideo = ({ video, videoPoster = '', color = '' }) => {
    const $video = useRef<HTMLVideoElement>(null);
    const [videoPaused, setVideoPaused] = useState(false);
    const [showVideoBtn, setShowVideoBtn] = useState(false);

    useEffect(() => {
        if (video && $video?.current) {
            const { current: $element } = $video;
            gsap.to($element, {
                scrollTrigger: {
                    start: 'top bottom',
                    trigger: $element || '',
                    onEnter: () => {
                        if ($element && $element.paused) {
                            setVideoPaused(false);
                            $element.play().catch(() => {
                                setShowVideoBtn(true);
                            });
                        }
                    },
                    onEnterBack: () => {
                        if ($element && $element.paused) {
                            setVideoPaused(false);
                            $element.play().catch(() => {
                                setShowVideoBtn(true);
                            });
                        }
                    },
                    onLeaveBack: () => {
                        if ($element && !$element.paused) {
                            $element.pause();
                            setVideoPaused(true);
                        }
                    },
                    onLeave: () => {
                        if ($element && !$element.paused) {
                            $element.pause();
                            setVideoPaused(true);
                        }
                    },
                },
            });
        }
    }, [video, $video, setVideoPaused, setShowVideoBtn]);

    const toggleVideoPlaying = useCallback(() => {
        if ($video?.current) {
            if ($video?.current.paused) {
                $video?.current.play();
                setVideoPaused(false);
            } else {
                $video?.current.pause();
                setVideoPaused(true);
            }
        }
    }, [setVideoPaused, $video]);

    return video ? (
        <figure className={styles.mediaWrapper}>
            <ParallaxElement percentage={10} className={styles.media}>
                <video
                    loop
                    ref={$video}
                    preload="auto"
                    playsInline
                    muted
                    autoPlay
                    poster={videoPoster || ''}
                    onClick={toggleVideoPlaying}
                >
                    <source src={video} type="video/mp4" />
                </video>
                <div
                    className={cx(styles.playBtnWrapper, styles[`playBtnWrapper--${color}`], {
                        [`${styles.playBtnWrapperActive}`]: videoPaused && showVideoBtn,
                    })}
                >
                    <button onClick={toggleVideoPlaying}>
                        <Icon name="play" />
                    </button>
                </div>
            </ParallaxElement>
        </figure>
    ) : null;
};
