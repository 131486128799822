import React, { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { getImageSize } from 'utils/getImageSize';
import { imagesLoaded } from 'utils/imagesLoaded';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import Icon from 'components/Icons';
import styles from './VideoComparison.module.scss';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
}

export type waveTopColors = 'white' | 'beige';

export type VideoComparisonModuleProps = any;

export const VideoComparisonModule = React.forwardRef<HTMLDivElement, VideoComparisonModuleProps>(
    (
        {
            layout,
            logo,
            eyebrow,
            title,
            video,
            videoPoster,
            leftAreaTitle,
            leftAreaDescription,
            rightAreaTitle,
            rightAreaDescription,
        },
        ref
    ) => {
        const $video = useRef<HTMLVideoElement>(null);
        const [videoPaused, setVideoPaused] = useState(false);
        const [showVideoBtn, setShowVideoBtn] = useState(false);

        useEffect(() => {
            if (video && $video?.current) {
                const { current: $element } = $video;
                gsap.to($element, {
                    scrollTrigger: {
                        start: 'top bottom',
                        trigger: $element || '',
                        onEnter: () => {
                            if ($element && $element.paused) {
                                setVideoPaused(false);
                                $element.play().catch(() => {
                                    setShowVideoBtn(true);
                                });
                            }
                        },
                        onEnterBack: () => {
                            if ($element && $element.paused) {
                                setVideoPaused(false);
                                $element.play().catch(() => {
                                    setShowVideoBtn(true);
                                });
                            }
                        },
                        onLeaveBack: () => {
                            if ($element && !$element.paused) {
                                $element.pause();
                                setVideoPaused(true);
                            }
                        },
                        onLeave: () => {
                            if ($element && !$element.paused) {
                                $element.pause();
                                setVideoPaused(true);
                            }
                        },
                    },
                });
            }
        }, [video, $video, setVideoPaused, setShowVideoBtn]);

        const toggleVideoPlaying = useCallback(() => {
            if ($video?.current) {
                if ($video?.current.paused) {
                    $video?.current.play();
                    setVideoPaused(false);
                } else {
                    $video?.current.pause();
                    setVideoPaused(true);
                }
            }
        }, [setVideoPaused, $video]);

        return (
            <Section ref={ref} alignment="" bgColor="red">
                <TopWave color={layout?.waveTopColor as TopWaveColors} />
                <Container type="full-width">
                    <div className={styles.videoComparisonModule}>
                        {logo?.sourceUrl && (
                            <img
                                className={styles.logo}
                                src={getImageSize(
                                    logo.sourceUrl,
                                    logo.mediaDetails.sizes,
                                    'custom-500x'
                                )}
                                alt={logo.altText || ''}
                                onLoad={imagesLoaded}
                            />
                        )}
                        {eyebrow && (
                            <p
                                className={cx(
                                    styles.eyebrow,
                                    'u-b2 u-fw-400 u-text-center u-uppercase'
                                )}
                            >
                                {eyebrow}
                            </p>
                        )}
                        {title && (
                            <h2 className={cx(styles.title, 'u-a5 u-fw-700 u-text-center')}>
                                {title}
                            </h2>
                        )}
                        <div className={styles.row}>
                            <div className={cx(styles.colLeft)}>
                                {leftAreaTitle && (
                                    <p className={cx(styles.colTitle, 'u-a3 u-fw-700')}>
                                        {leftAreaTitle}
                                    </p>
                                )}
                                {leftAreaDescription && (
                                    <p className="u-b1 u-fw-400">{leftAreaDescription}</p>
                                )}
                                <Icon className={styles.arrowLeft} name={'rounded-arrow'} />
                            </div>
                            {video && (
                                <div className={cx(styles.colMain)}>
                                    <div className={styles.videoWrapper}>
                                        <video
                                            loop
                                            ref={$video}
                                            preload="auto"
                                            playsInline
                                            muted
                                            autoPlay={false}
                                            poster={videoPoster || ''}
                                            onClick={toggleVideoPlaying}
                                        >
                                            <source src={video} type="video/mp4" />
                                        </video>
                                        <div
                                            className={cx(
                                                styles.playBtnWrapper,
                                                styles[`playBtnWrapper--${layout?.waveTopColor}`],
                                                {
                                                    [`${styles.playBtnWrapperActive}`]:
                                                        videoPaused && showVideoBtn,
                                                }
                                            )}
                                        >
                                            <button onClick={toggleVideoPlaying}>
                                                <Icon name="play" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className={cx(styles.colRight)}>
                                {rightAreaTitle && (
                                    <p className={cx(styles.colTitle, 'u-a3 u-fw-700')}>
                                        {rightAreaTitle}
                                    </p>
                                )}
                                {rightAreaDescription && (
                                    <p className="u-b1 u-fw-400">{rightAreaDescription}</p>
                                )}
                                <Icon className={styles.arrowRight} name="rounded-arrow-flipped" />
                            </div>
                        </div>
                    </div>
                </Container>
            </Section>
        );
    }
);
