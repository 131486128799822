import {
    alignments,
    backgroundColors,
    waveTopColors,
    buttonTypes,
    VerticalImageFeaturedModule,
} from './VerticalImageFeatured';

export type { backgroundColors, alignments, waveTopColors, buttonTypes };

export default VerticalImageFeaturedModule;
