import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import DefaultPage from 'components/Layout/DefaultPage';
import Button from 'components/common/Button';
import Spinner from 'components/common/Spinner';
import styles from './Contact.module.scss';

const Contact = ({ options, contactPage }) => {
    const { formElements, formSuccess, formError, contactForm } = contactPage;

    const [formSent, setFormSent] = useState(false);
    const [formHasError, setFormHasError] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isSubmitting, isSubmitSuccessful },
    } = useForm({
        mode: 'onChange',
    });

    const onSubmit = useCallback(
        (data: any) => {
            axios({
                method: 'POST',
                url: '/api/contact',
                params: {
                    id: contactForm[0]?.databaseId,
                },
                data,
            })
                .then(results => {
                    if (results?.data?.status === 'mail_sent') {
                        setFormSent(true);
                    } else {
                        setFormHasError(true);
                    }
                })
                .catch(error => {
                    console.warn(error);
                    setFormHasError(true);
                });
        },
        [setFormSent, setFormHasError, contactForm]
    );

    const handleFormReturn = useCallback(
        ev => {
            if (ev.target.getAttribute('href') === '#reset-form') {
                ev.preventDefault();
                setFormSent(false);
                setFormHasError(false);
                reset();
            }
        },
        [reset]
    );

    return (
        <DefaultPage title={options?.title} containerType="narrow">
            <div className={styles.container}>
                {contactPage?.content && (
                    <div className={styles.contentWrapper}>
                        <div
                            className={cx(styles.content, 'u-content-editor')}
                            dangerouslySetInnerHTML={{
                                __html: contactPage.content,
                            }}
                        />
                    </div>
                )}
                <div className={styles.formWrapper}>
                    {!formSent && !formHasError && (
                        <form
                            className={cx('u-form', styles.form)}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <fieldset className="u-form__fieldset">
                                <label className="u-form__label u-b1" htmlFor="yourName">
                                    {formElements.nameAndSurnameLabel}
                                </label>
                                <input
                                    className={cx('u-form__input', {
                                        'has-error': errors.yourName,
                                    })}
                                    type="text"
                                    placeholder={formElements.nameAndSurnamePlaceholder}
                                    id="yourName"
                                    name="yourName"
                                    required
                                    {...register('yourName', { required: true })}
                                />
                                {errors?.yourName && (
                                    <p className={cx('u-form__error-msg', 'u-b2 u-uppercase')}>
                                        {formElements.nameAndSurnameRequiredErrorMessage}
                                    </p>
                                )}
                            </fieldset>
                            <fieldset className="u-form__fieldset">
                                <label className="u-form__label u-b1" htmlFor="yourEmail">
                                    {formElements.emailLabel}
                                </label>
                                <input
                                    className={cx('u-form__input', {
                                        'has-error': errors.yourEmail,
                                    })}
                                    type="email"
                                    placeholder={formElements.emailPlaceholder}
                                    id="yourEmail"
                                    name="yourEmail"
                                    required
                                    {...register('yourEmail', {
                                        required: true,
                                        pattern:
                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    })}
                                />
                                {errors?.yourEmail &&
                                    (errors?.yourEmail?.type === 'pattern' ? (
                                        <p className={cx('u-form__error-msg', 'u-b2 u-uppercase')}>
                                            {formElements.emailInvalidErrorMessage}
                                        </p>
                                    ) : (
                                        <p className={cx('u-form__error-msg', 'u-b2 u-uppercase')}>
                                            {formElements.emailRequiredErrorMessage}
                                        </p>
                                    ))}
                            </fieldset>
                            <fieldset className="u-form__fieldset">
                                <label className="u-form__label u-b1" htmlFor="yourTel">
                                    {formElements.phoneNumberLabel}
                                </label>
                                <input
                                    className={cx('u-form__input', {
                                        'has-error': errors.yourTel,
                                    })}
                                    type="tel"
                                    placeholder={formElements.phoneNumberPlaceholder}
                                    id="yourTel"
                                    name="yourTel"
                                    required
                                    {...register('yourTel', {
                                        required: true,
                                        pattern: /^[0-9]*$/,
                                    })}
                                />
                                {errors?.yourTel &&
                                    (errors?.yourTel?.type === 'pattern' ? (
                                        <p className={cx('u-form__error-msg', 'u-b2 u-uppercase')}>
                                            {formElements.phoneNumberInvalidErrorMessage}
                                        </p>
                                    ) : (
                                        <p className={cx('u-form__error-msg', 'u-b2 u-uppercase')}>
                                            {formElements.phoneNumberRequiredErrorMessage}
                                        </p>
                                    ))}
                            </fieldset>
                            <fieldset className="u-form__fieldset">
                                <label
                                    className="u-form__label u-b1"
                                    htmlFor="teamPartnerCustomerId"
                                >
                                    {formElements.teamPartnerCustomerIdLabel}
                                </label>
                                <input
                                    className="u-form__input"
                                    type="text"
                                    placeholder={formElements.teamPartnerCustomerIdPlaceholder}
                                    id="teamPartnerCustomerId"
                                    name="teamPartnerCustomerId"
                                    {...register('teamPartnerCustomerId')}
                                />
                            </fieldset>
                            <fieldset className="u-form__fieldset">
                                <label className="u-form__label u-b1" htmlFor="yourSubject">
                                    {formElements.subjectLabel}
                                </label>
                                <input
                                    className={cx('u-form__input', {
                                        'has-error': errors.yourSubject,
                                    })}
                                    type="text"
                                    placeholder={formElements.subjectPlaceholder}
                                    id="yourSubject"
                                    name="yourSubject"
                                    {...register('yourSubject', { required: true })}
                                />
                                {errors?.yourSubject && (
                                    <p className={cx('u-form__error-msg', 'u-b2 u-uppercase')}>
                                        {formElements.subjectRequiredErrorMessage}
                                    </p>
                                )}
                            </fieldset>
                            <fieldset className="u-form__fieldset">
                                <label className="u-form__label u-b1" htmlFor="yourMessage">
                                    {formElements.messageLabel}
                                </label>
                                <textarea
                                    className={cx('u-form__input u-form__input--textarea', {
                                        'has-error': errors.yourMessage,
                                    })}
                                    placeholder={formElements.messagePlaceholder}
                                    id="yourMessage"
                                    name="yourMessage"
                                    {...register('yourMessage', { required: true })}
                                />
                                {errors?.yourMessage && (
                                    <p className={cx('u-form__error-msg', 'u-b2 u-uppercase')}>
                                        {formElements.messageRequiredErrorMessage}
                                    </p>
                                )}
                            </fieldset>
                            <fieldset className="u-form__fieldset">
                                <div className="u-form__checkbox-wrapper">
                                    <input
                                        className="u-form__checkbox"
                                        type="checkbox"
                                        id="yourAcceptance"
                                        name="yourAcceptance"
                                        {...register('yourAcceptance', { required: true })}
                                    />
                                    <label
                                        className="u-form__checkbox-label u-b1"
                                        htmlFor="yourAcceptance"
                                        dangerouslySetInnerHTML={{
                                            __html: formElements.acceptanceLabel,
                                        }}
                                    />
                                </div>
                            </fieldset>
                            <fieldset className="u-form__fieldset">
                                <Button
                                    color="red"
                                    styleType="filled"
                                    className="u-b0 u-fw-500"
                                    disabled={isSubmitting || !isValid}
                                >
                                    {formElements.submitButtonLabel}
                                    {(isSubmitting || isSubmitSuccessful) && <Spinner />}
                                </Button>
                            </fieldset>
                        </form>
                    )}
                    {formSent && (
                        <div className={styles.formStatus}>
                            <h3 className={cx(styles.formStatusTitle, 'u-a4 u-fw-700')}>
                                {formSuccess.title}
                            </h3>
                            <button
                                onClick={handleFormReturn}
                                className={cx(styles.formStatusMessage, 'u-a2')}
                                dangerouslySetInnerHTML={{
                                    __html: formSuccess.message,
                                }}
                            />
                        </div>
                    )}
                    {formHasError && (
                        <div className={styles.formStatus}>
                            <h3 className={cx(styles.formStatusTitle, 'u-a4 u-fw-700')}>
                                {formError.title}
                            </h3>
                            <button
                                onClick={handleFormReturn}
                                className={cx(styles.formStatusMessage, 'u-a2')}
                                dangerouslySetInnerHTML={{
                                    __html: formError.message,
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </DefaultPage>
    );
};

Contact.defaultProps = {
    options: {},
    contactPage: {},
};

Contact.propTypes = {
    options: PropTypes.oneOfType([PropTypes.object]),
    contactPage: PropTypes.oneOfType([PropTypes.object]),
};

export default Contact;
