import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Acf_Link } from 'types/wordpress';
import { LocalizedButton } from 'context/LocalizedButton';
import Button from 'components/common/Button';
import styles from './DoubleCta.module.scss';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    buttons: readonly {
        addTp?: boolean;
        linktype: string;
        label: string;
        linkexternal: Acf_Link;
        linkinternal: any;
    }[];
    className?: string;
    disabled?: boolean;
    color?: 'red' | 'white';
}

export const DoubleCta = React.forwardRef<HTMLButtonElement, Props>(
    ({ children, buttons, color, className, ...rest }, ref) => {
        const wrapper = useRef(null);
        const [elementsCount, setElementsCount] = useState(0);

        useEffect(() => {
            if (wrapper?.current && typeof document !== 'undefined') {
                setElementsCount(wrapper.current.children.length);
            }
        }, [wrapper]);

        return (
            <div ref={wrapper} className={cx(styles.wrapper, styles[`wrapperHas${elementsCount}`])}>
                {buttons?.map((button, index) => (
                    <LocalizedButton
                        key={`homepageHeaderCtaBtn__${index}`}
                        data={button}
                        render={(btnLabel, btnUrl, btnTarget, btnType, tpLink) => (
                            <Button
                                to={btnUrl}
                                target={btnTarget}
                                tpLink={tpLink}
                                styleType={index === 0 ? 'transparent' : 'filled'}
                                color={color || 'white'}
                                className={cx(styles.button, className)}
                            >
                                {btnLabel}
                            </Button>
                        )}
                    />
                ))}
            </div>
        );
    }
);
