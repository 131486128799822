import {
    alignments,
    backgroundColors,
    imageTypes,
    VerticalImageTestimonialSliderModule,
    waveTopColors,
} from './VerticalImageTestimonialSlider';

export type { backgroundColors, alignments, waveTopColors, imageTypes };

export default VerticalImageTestimonialSliderModule;
