import React, { createRef, useEffect, useState } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Scrollbar, SwiperOptions } from 'swiper';
import { getImageSize } from 'utils/getImageSize';
import { BREAKPOINT_LG, BREAKPOINT_SM_MD } from 'utils/breakpoints';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import RevealElement from 'components/Layout/RevealElement';
import styles from './ClientListLogo.module.scss';

SwiperCore.use([Scrollbar, A11y]);

export type backgroundColors = 'white' | 'beige';

export type waveTopColors = 'white' | 'beige';

export type ClientListLogoModuleProps = any;

export const ClientListLogoModule = React.forwardRef<HTMLDivElement, ClientListLogoModuleProps>(
    ({ layout, title, clients }, ref) => {
        const swiperScrollBarRef = createRef<HTMLDivElement>();
        const [sliderParams, setSliderParams] = useState<SwiperOptions>();

        useEffect(() => {
            if (swiperScrollBarRef?.current && !sliderParams) {
                const breakpoints = {} as any;

                breakpoints[BREAKPOINT_SM_MD] = {
                    slidesPerView: 2,
                };

                breakpoints[BREAKPOINT_LG] = {
                    slidesPerView: 4,
                };

                setSliderParams({
                    slidesPerView: 1,
                    slidesPerColumn: 2,
                    spaceBetween: 32,
                    scrollbar: {
                        el: swiperScrollBarRef.current || '',
                        hide: false,
                        draggable: true,
                        snapOnRelease: true,
                    },
                    breakpoints,
                });
            }
        }, [swiperScrollBarRef, sliderParams]);

        return (
            <Section ref={ref} modifier="overflow" alignment="" bgColor={layout.backgroundColor}>
                <TopWave color={layout?.waveTopColor as TopWaveColors} />
                <Container>
                    <div className={styles.clientListLogo}>
                        <div className={cx(styles.header, 'u-text-center')}>
                            <RevealElement>
                                <h2 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h2>
                            </RevealElement>
                        </div>
                        <div className={cx(styles.content)}>
                            {clients && clients.length > 0 && sliderParams && (
                                <RevealElement>
                                    <Swiper {...sliderParams}>
                                        {clients?.map((client, index) => (
                                            <SwiperSlide key={index}>
                                                <div
                                                    className={cx(styles.client)}
                                                    style={{
                                                        backgroundImage: `url(${getImageSize(
                                                            client.sourceUrl,
                                                            client.mediaDetails.sizes,
                                                            'medium'
                                                        )})`,
                                                    }}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </RevealElement>
                            )}
                        </div>
                        <div className={cx(styles.scrollbar)}>
                            <RevealElement>
                                <div
                                    ref={swiperScrollBarRef}
                                    className={cx(styles.scrollbarItem, {
                                        [styles.scrollbarItemInverted]:
                                            layout.backgroundColor === 'white',
                                    })}
                                />
                            </RevealElement>
                        </div>
                    </div>
                </Container>
            </Section>
        );
    }
);
