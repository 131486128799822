import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import React from 'react';
import cx from 'classnames';
import styles from './Testimonial.module.scss';
import Icon from '@root/components/Icons';

interface Props {
    title: string;
    align: string;
    openModal: Function;
    isVideo?: boolean;
    images: {
        vertical: string;
        horizontal: string;
    };
    product?: {
        name: string;
        images?: {
            vertical: string;
            horizontal: string;
        };
    };
}

export const Testimonial = React.forwardRef<HTMLDivElement, Props>(
    ({ title, images, product, align, openModal, isVideo }, ref) => (
        <div className={cx(styles[align] || null, styles.testimonial)} ref={ref}>
            <div
                className={styles.figures}
                onClick={ev => openModal(ev)}
                role="button"
                tabIndex={-1}
            >
                <div className={styles.figuresUnderlay}>
                    <div className={styles.figureDesktop}>
                        <ParallaxOvalImage
                            lazy={false}
                            parallax={true}
                            className={styles.figure}
                            parallaxBackground={images?.vertical}
                        />
                    </div>
                    <div className={styles.figureMobile}>
                        <ParallaxOvalImage
                            lazy={false}
                            parallax={false}
                            className={styles.figure}
                            parallaxBackground={images?.horizontal}
                        />
                    </div>
                </div>

                {product?.images?.vertical && product?.images?.horizontal && (
                    <div className={styles.figuresOverlay}>
                        <div className={styles.figureDesktop}>
                            <ParallaxOvalImage
                                lazy={false}
                                parallax={false}
                                className={styles.figure}
                                parallaxBackground={product?.images.vertical}
                            />
                        </div>
                        <div className={styles.figureMobile}>
                            <ParallaxOvalImage
                                lazy={false}
                                parallax={false}
                                className={styles.figure}
                                parallaxBackground={product?.images.horizontal}
                            />
                        </div>
                    </div>
                )}
            </div>

            <h3 className={cx('u-a3', styles.title)} onClick={ev => openModal(ev)}>
                {title}
                {isVideo && <Icon name="video-indicator" />}
            </h3>

            {product?.name && <p className={cx('u-b1', styles.productTitle)}>{product?.name}</p>}
        </div>
    )
);
