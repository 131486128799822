import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import Button from 'components/common/Button';
import CountryFlag from 'components/common/CountryFlag';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import VideoModal from 'components/common/VideoModal';
import { ContentModuleBlocks } from 'components/Modules/content/ContentModuleBlocks';
import { ContentModule } from 'components/Modules/content/ContentModuleBlocks.enums';
import { GeneralModuleBlocks } from 'components/Modules/general/GeneralModuleBlocks';
import { lazyLoadImages } from 'utils/lazyLoad';
import styles from './Sport.module.scss';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollToPlugin);
}

const SportPage = ({
    contentModulesGroup,
    generalModulesGroup,
    athletePostType,
    options,
    sportTypes,
}) => {
    const {
        photoVertical,
        photoHorizontal,
        nationality,
        storybutton,
        intro,
        video,
        videoButtonLabel,
        subtitle,
    } = athletePostType;

    const $section = useRef(null);

    const sport = useMemo(
        () => (sportTypes && sportTypes?.nodes?.length > 0 ? sportTypes.nodes[0].name : ''),
        [sportTypes]
    );

    const scrollToSection = useCallback(
        ev => {
            ev?.preventDefault();
            if ($section?.current) {
                gsap.to(window, {
                    duration: 0.6,
                    ease: 'none',
                    scrollTo: $section?.current,
                });
            }
        },
        [$section]
    );

    useEffect(() => {
        lazyLoadImages();
    }, [athletePostType]);

    return (
        <>
            <header className={styles.header}>
                <Container>
                    <div className={styles.headerWrapper}>
                        <div className={cx(styles.headerPhoto, styles.headerPhotoDesktop)}>
                            <ParallaxOvalImage parallaxBackground={photoVertical} />
                            {video && (
                                <div className={styles.video}>
                                    <VideoModal button={videoButtonLabel} video={video} />
                                </div>
                            )}
                        </div>
                        <div className={cx(styles.headerPhoto, styles.headerPhotoMobile)}>
                            <ParallaxOvalImage parallaxBackground={photoHorizontal} />
                            {video && (
                                <div className={styles.video}>
                                    <VideoModal button={videoButtonLabel} video={video} />
                                </div>
                            )}
                        </div>
                        <div className={styles.headerContent}>
                            <RevealElement>
                                <h1 className={cx('u-a6', styles.headerTitle)}>{options?.title}</h1>
                            </RevealElement>
                            {subtitle && (
                                <RevealElement delay={0.2}>
                                    <h2 className={cx('u-b2', styles.headerSubtitle)}>
                                        {subtitle}
                                    </h2>
                                </RevealElement>
                            )}
                            {nationality?.length > 0 && (
                                <RevealElement delay={0.3}>
                                    <CountryFlag
                                        code={nationality[0]}
                                        name={nationality[1]}
                                        sport={sport}
                                    />
                                </RevealElement>
                            )}
                            {intro && (
                                <RevealElement delay={0.4}>
                                    <p className={cx('u-b0', styles.headerText)}>{intro}</p>
                                </RevealElement>
                            )}
                            {contentModulesGroup?.contentModules?.length > 0 && storybutton && (
                                <div className={styles.headerBtn}>
                                    <RevealElement delay={0.6}>
                                        <Button
                                            styleType="transparent"
                                            icon="arrow-down"
                                            onClick={scrollToSection}
                                            aria-label={storybutton}
                                            className="u-b0 u-fw-500"
                                        >
                                            {storybutton}
                                        </Button>
                                    </RevealElement>
                                </div>
                            )}
                        </div>
                    </div>
                </Container>
            </header>

            {contentModulesGroup?.contentModules?.length > 0 && (
                <section ref={$section} className={styles.section}>
                    <div className={styles.sectionInner}>
                        <article className={styles.article}>
                            {contentModulesGroup?.contentModules?.map((block, index) => {
                                let fullsize = false;
                                if (block && ContentModule.MediaModule === block.__typename)
                                    fullsize = true;
                                return (
                                    <div
                                        key={index}
                                        className={cx(styles.articleBlock, {
                                            [`${styles.articleBlockFull}`]: fullsize,
                                        })}
                                    >
                                        <RevealElement>
                                            <ContentModuleBlocks layoutData={block as unknown} />
                                        </RevealElement>
                                    </div>
                                );
                            })}
                        </article>
                    </div>
                </section>
            )}

            {generalModulesGroup?.modules?.map((block, index) => (
                <RevealElement key={index}>
                    <GeneralModuleBlocks layoutData={block as unknown} />
                </RevealElement>
            ))}
        </>
    );
};

SportPage.defaultProps = {
    contentModulesGroup: {},
    generalModulesGroup: {},
    athletePostType: {},
    options: {},
    sportTypes: {},
    intro: null,
};

SportPage.propTypes = {
    contentModulesGroup: PropTypes.oneOfType([PropTypes.object]),
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
    athletePostType: PropTypes.oneOfType([PropTypes.object]),
    options: PropTypes.oneOfType([PropTypes.object]),
    sportTypes: PropTypes.oneOfType([PropTypes.object]),
    intro: PropTypes.string,
};

export default SportPage;
