import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { lazyLoadImages } from 'utils/lazyLoad';
import { HeaderModuleBlocks } from 'components/Modules/header/HeaderModuleBlocks';
import { GeneralModuleBlocks } from 'components/Modules/general/GeneralModuleBlocks';

const Modules = ({ headerGroup, generalModulesGroup }) => {
    useEffect(() => {
        lazyLoadImages();
    }, [headerGroup, generalModulesGroup]);

    return (
        <>
            {headerGroup?.headerModules?.map((block, index) => (
                <HeaderModuleBlocks key={index} layoutData={block as unknown} />
            ))}

            {generalModulesGroup?.modules?.map((block, index) => (
                <GeneralModuleBlocks key={index} layoutData={block as unknown} />
            ))}
        </>
    );
};

Modules.defaultProps = {
    headerGroup: {},
    generalModulesGroup: {},
};

Modules.propTypes = {
    headerGroup: PropTypes.oneOfType([PropTypes.object]),
    generalModulesGroup: PropTypes.oneOfType([PropTypes.object]),
};

export default Modules;
