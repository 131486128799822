import React, { useState, useCallback, useMemo } from 'react';
import cx from 'classnames';
import SwiperCore, { A11y, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getImageSize } from 'utils/getImageSize';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxOvalImage from 'components/Layout/ParallaxOvalImage';
import ParallaxOvalImageGroup from 'components/Layout/ParallaxOvalImageGroup';
import ArrowButton from 'components/common/ArrowButton';
import Icon from 'components/Icons';
import styles from './VerticalImageTestimonialSlider.module.scss';

SwiperCore.use([A11y]);

export type backgroundColors = 'white' | 'beige';

export type alignments = 'content-left' | 'content-right';

export type waveTopColors = 'white' | 'beige';

export type imageTypes = 'normal' | 'parallax';

export type VerticalImageTestimonialSliderModuleProps = any;

export const VerticalImageTestimonialSliderModule = React.forwardRef<
    HTMLDivElement,
    VerticalImageTestimonialSliderModuleProps
>(({ layout, eyebrow, title, testimonials, images }, ref) => {
    const { imageType, normalImage, visual, background, parallax } = images;

    const [testimonialSlider, setTestimonialSlider] = useState<SwiperCore>();

    const testimonialSliderParams: SwiperOptions = useMemo(
        () => ({
            loop: true,
            watchSlidesProgress: true,
            watchSlidesVisibility: true,
        }),
        []
    );

    const nextSlide = useCallback(() => {
        if (testimonialSlider) testimonialSlider.slideNext();
    }, [testimonialSlider]);

    const prevSlide = useCallback(() => {
        if (testimonialSlider) testimonialSlider.slidePrev();
    }, [testimonialSlider]);

    const renderNormalImage = useCallback(
        () =>
            imageType === 'normal' && normalImage?.horizontal && normalImage?.vertical ? (
                <>
                    <ParallaxOvalImage
                        className={styles.normalImageDesktop}
                        parallaxBackground={normalImage.vertical}
                    />
                    <ParallaxOvalImage
                        className={styles.normalImageMobile}
                        parallaxBackground={normalImage.horizontal}
                    />
                </>
            ) : null,
        [imageType, normalImage]
    );

    const renderParallaxImage = useCallback(
        () =>
            imageType === 'parallax' &&
            visual &&
            background?.horizontal &&
            background?.vertical &&
            parallax?.topLeft &&
            parallax?.bottomRight ? (
                <ParallaxOvalImageGroup
                    parallaxBackground={background.vertical}
                    parallaxBackgroundMobile={background.horizontal}
                    parallaxInnerImage={getImageSize(
                        visual.sourceUrl,
                        visual.mediaDetails.sizes,
                        'custom-800x'
                    )}
                    parallaxImageTopLeft={getImageSize(
                        parallax.topLeft.sourceUrl,
                        parallax.topLeft.mediaDetails.sizes,
                        'medium'
                    )}
                    parallaxImageBottomRight={getImageSize(
                        parallax.bottomRight.sourceUrl,
                        parallax.bottomRight.mediaDetails.sizes,
                        'medium'
                    )}
                />
            ) : null,
        [imageType, visual, background, parallax]
    );

    return (
        <Section ref={ref} alignment="" bgColor={layout.backgroundColor}>
            <TopWave color={layout?.waveTopColor as TopWaveColors} />
            <Container>
                <div
                    className={cx(styles.verticalImageTestimonialSliderModule, {
                        [styles.contentRight]: layout?.alignment === 'content-right',
                    })}
                >
                    <div className={styles.content}>
                        <div className={styles.contentInner}>
                            <div className={styles.desktopTitle}>
                                <RevealElement>
                                    {eyebrow && (
                                        <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>
                                            {eyebrow}
                                        </p>
                                    )}
                                    {title && (
                                        <h3 className={cx(styles.title, 'u-a5 u-fw-700')}>
                                            {title}
                                        </h3>
                                    )}
                                </RevealElement>
                            </div>
                            <div className={styles.testimonialSliderWrapper}>
                                <RevealElement>
                                    <Icon name="quote" className={styles.icon} />
                                    {testimonials?.length > 1 ? (
                                        <Swiper
                                            {...testimonialSliderParams}
                                            onSwiper={setTestimonialSlider}
                                        >
                                            {testimonials.map((testimonial, index) => (
                                                <SwiperSlide
                                                    key={index}
                                                    className={styles.testimonialSliderSlide}
                                                >
                                                    <h4
                                                        className={cx(
                                                            styles.testimonialSliderTitle,
                                                            'u-a3 u-fw-700'
                                                        )}
                                                    >
                                                        {testimonial.title}
                                                    </h4>
                                                    <div className={styles.testimonialSliderHeader}>
                                                        <p className={'u-b2 u-uppercase'}>
                                                            {testimonial.subtitle}
                                                        </p>
                                                        <p className={'u-b2 u-uppercase'}>
                                                            {index < 9
                                                                ? `0${index + 1}`
                                                                : `${index + 1}`}
                                                            /
                                                            {testimonials.length < 9
                                                                ? `0${testimonials.length}`
                                                                : `${testimonials.length}`}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="u-content-editor u-content-editor--module"
                                                        dangerouslySetInnerHTML={{
                                                            __html: testimonial.testimonial,
                                                        }}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    ) : (
                                        <>
                                            {testimonials?.map((testimonial, index) => (
                                                <div key={index}>
                                                    <h4
                                                        className={cx(
                                                            styles.testimonialSliderTitle,
                                                            'u-a3 u-fw-700'
                                                        )}
                                                    >
                                                        {testimonial.title}
                                                    </h4>
                                                    <div className={styles.testimonialSliderHeader}>
                                                        <p className={'u-b2 u-uppercase'}>
                                                            {testimonial.subtitle}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="u-content-editor u-content-editor--module"
                                                        dangerouslySetInnerHTML={{
                                                            __html: testimonial.testimonial,
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </RevealElement>
                            </div>
                            {testimonials?.length > 1 && (
                                <div className={styles.arrows}>
                                    <RevealElement>
                                        <ArrowButton
                                            type="left"
                                            onClick={prevSlide}
                                            className={styles.arrow}
                                        />
                                        <ArrowButton
                                            type="right"
                                            onClick={nextSlide}
                                            className={styles.arrow}
                                        />
                                    </RevealElement>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.images}>
                        <div className={styles.mobileTitle}>
                            <RevealElement>
                                {eyebrow && (
                                    <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>
                                        {eyebrow}
                                    </p>
                                )}
                                {title && (
                                    <h3 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h3>
                                )}
                            </RevealElement>
                        </div>
                        {imageType === 'normal' ? renderNormalImage() : renderParallaxImage()}
                    </div>
                </div>
            </Container>
        </Section>
    );
});
