export default function removeEmptyObjectKeys(object: { [key: string]: unknown }) {
    const entries = Object.entries(object);
    const nonNullEntries = entries.filter(
        ([key, value]) => value !== undefined && value !== null && value !== ''
    );

    const obj = {};
    for (const [key, value] of nonNullEntries) {
        obj[key] = value;
    }

    return obj;
}
