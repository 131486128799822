import React from 'react';
import cx from 'classnames';
import ReactPaginate from 'react-paginate';
import { TranslationKeyString } from 'context/TranslationKey';
import styles from './Pagination.module.scss';

export interface Props {
    disabled?: boolean;
    current?: number;
    total?: number;
    pageSize?: number;
    onPageChange: (pageNumber: number) => void;
    nextLabel?: string;
    prevLabel?: string;
}

const Pagination: React.FunctionComponent<Props> = ({
    disabled = false,
    current,
    total = 0,
    pageSize = 10,
    onPageChange,
    nextLabel,
    prevLabel,
}) => {
    if (pageSize === 0 || total === 0) {
        return null;
    }

    const forcePage = current ? Math.max(current - 1, 0) : undefined;

    const pageCount = Math.ceil(total / pageSize);

    return (
        <ReactPaginate
            containerClassName={cx('u-b0', styles.main, {
                [styles.disabled]: disabled,
            })}
            activeClassName={styles.numberActive}
            previousClassName={styles.label}
            nextClassName={styles.label}
            disabledClassName={styles.labelDisabled}
            pageClassName={cx('u-b0', styles.number)}
            pageCount={pageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            forcePage={forcePage}
            onPageChange={selectedItem => {
                onPageChange(selectedItem.selected + 1);
            }}
            previousLabel={prevLabel || TranslationKeyString('previous')}
            nextLabel={nextLabel || TranslationKeyString('next')}
        />
    );
};

export default Pagination;
