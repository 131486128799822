import React from 'react';
import { ContentModule } from './ContentModuleBlocks.enums';

// region Modules
import TextEditorModule from './TextEditor';
import PromoModule from './Promo';
import MediaModule from './Media';
import ImageQuoteModule from './ImageQuote';
// endregion

type Layout = {
    [key in ContentModule]: React.ReactNode;
};

export type LayoutData = any;

interface Props {
    layoutData: LayoutData;
}

export const ContentModuleBlocks: React.FC<Props> = ({ layoutData }) => {
    const layoutType = layoutData?.__typename as ContentModule;

    const Default = () => (
        <p>
            In <strong>ContentModuleBlocks</strong> the mapping of component{' '}
            <strong>{layoutType || 'unknown'}</strong> is missing.
        </p>
    );

    /**
     * Mapping the fieldGroupName(s) to our components
     */
    const layouts: Layout = {
        [ContentModule.TextEditorModule]: TextEditorModule,
        [ContentModule.PromoModule]: PromoModule,
        [ContentModule.MediaModule]: MediaModule,
        [ContentModule.ImageQuoteModule]: ImageQuoteModule,
    };

    const ComponentTag = layouts[layoutType] ? (layouts[layoutType] as React.ElementType) : Default;

    return <ComponentTag {...layoutData} />;
};
