import React from 'react';
import cx from 'classnames';
import Section from 'components/Layout/Section';
import ParallaxElement from '@root/components/Layout/ParallaxElement';
import RevealElement from '@root/components/Layout/RevealElement';
import Button from '@root/components/common/Button';
import { LocalizedButton } from '@root/context/LocalizedButton';
import styles from './CustomBackgroundTwoColumnModule.module.scss';

type Props = any;

export const CustomBackgroundTwoColumnModule = React.forwardRef<HTMLDivElement, Props>(
    ({ layout, backgroundImages, button }, ref) => {
        const { backgroundImage, leftImage, rightImage } = backgroundImages;

        return (
            <Section
                ref={ref}
                bgColor=""
                style={{
                    backgroundImage: `url(${backgroundImage.sourceUrl})`,
                }}
                className={styles.section}
            >
                <div className={styles.container}>
                    <div className={styles.columns}>
                        <div className={styles.columnsItem}>
                            <ParallaxElement percentage={15}>
                                <img src={leftImage.sourceUrl} alt="" />
                            </ParallaxElement>
                        </div>
                        <div className={cx(styles.columnsItem, styles.rightColumn)}>
                            <ParallaxElement percentage={-15}>
                                <img src={rightImage.sourceUrl} alt="" />
                            </ParallaxElement>
                            <LocalizedButton
                                data={button}
                                render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                    <RevealElement>
                                        <Button
                                            to={btnUrl}
                                            target={btnTarget}
                                            styleType="filled"
                                            color="red"
                                            className="u-b0 u-fw-500"
                                            tpLink={dataTp}
                                        >
                                            {btnLabel}
                                        </Button>
                                    </RevealElement>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </Section>
        );
    }
);
