import React, { createRef, useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Scrollbar, SwiperOptions } from 'swiper';
import { getViewportSize } from 'utils/getViewportSize';
import { getMatchingCountryModel } from 'utils/getMatchingCountryModel';
import Container from 'components/Layout/Container';
import Section from 'components/Layout/Section';
import RevealElement from 'components/Layout/RevealElement';
import Athlete from 'components/common/Athlete';
import styles from './RelatedAthletes.module.scss';
import { useAppContext } from '@root/context';

SwiperCore.use([Scrollbar, A11y]);

type Props = any;

export const RelatedAthletesModule = React.forwardRef<HTMLDivElement, Props>(
    ({ title, athletes, ovalImages = true }, ref) => {
        const swiperScrollBarRef = createRef<HTMLDivElement>();
        const [sliderParams, setSliderParams] = useState<SwiperOptions>();

        const { countryCode, language } = useAppContext();

        useEffect(() => {
            if (swiperScrollBarRef?.current && !sliderParams) {
                setSliderParams({
                    loop: false,
                    slidesPerView: 1.5,
                    longSwipes: false,
                    watchSlidesProgress: true,
                    watchSlidesVisibility: true,
                    spaceBetween: 32,
                    scrollbar: {
                        el: swiperScrollBarRef.current || '',
                        hide: false,
                        draggable: true,
                        snapOnRelease: true,
                    },
                });
            }
        }, [swiperScrollBarRef, sliderParams]);

        const filteredAthletes = useMemo(
            () =>
                athletes.reduce((acc, athlete) => {
                    const sportName = athlete?.sportTypes?.nodes[0]?.name || '';
                    const athleteCountry = {
                        code: athlete?.athletePostType?.nationality[0] || '',
                        name: athlete?.athletePostType?.nationality[1] || '',
                    };
                    const url = getMatchingCountryModel(
                        athlete.options.countries,
                        countryCode,
                        language
                    )?.url;
                    if (url) {
                        return [
                            ...acc,
                            {
                                id: athlete.id,
                                link: url,
                                title: athlete?.options?.title,
                                sport: sportName,
                                country: athleteCountry,
                                photos: {
                                    vertical: {
                                        sourceUrl: athlete?.athletePostType?.photoVertical || '',
                                        altText: athlete?.options?.title || '',
                                        mediaDetails: {
                                            sizes: [],
                                        },
                                    },
                                    horizontal: {
                                        sourceUrl: athlete?.athletePostType?.photoHorizontal || '',
                                        altText: athlete?.options?.title || '',
                                        mediaDetails: {
                                            sizes: [],
                                        },
                                    },
                                },
                            },
                        ];
                    }
                    return [...acc];
                }, []),
            [athletes, countryCode, language]
        );

        if (filteredAthletes?.length === 0) {
            return null;
        }

        return (
            <Section alignment="" bgColor="white" ref={ref} modifier="mobile-overflow">
                <Container>
                    <div className={cx(styles.section)}>
                        {title && (
                            <RevealElement>
                                <h2 className={cx(styles.title, 'u-a4 u-text-center')}>{title}</h2>
                            </RevealElement>
                        )}
                        {filteredAthletes?.length > 0 && (
                            <div className={cx(styles.wrapper)}>
                                {getViewportSize() <= 800 ? (
                                    <div className={cx(styles.athletesSliders)}>
                                        {sliderParams && (
                                            <Swiper {...sliderParams}>
                                                {filteredAthletes.map((athlete, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div
                                                            key={athlete.id}
                                                            className={cx(styles.athlete)}
                                                        >
                                                            <RevealElement
                                                                delay={
                                                                    getViewportSize() <= 800
                                                                        ? 0
                                                                        : Number(`0.${2 * index}`)
                                                                }
                                                            >
                                                                <Athlete
                                                                    addTp
                                                                    align="center"
                                                                    lazy={false}
                                                                    link={athlete.link}
                                                                    title={athlete.title}
                                                                    sport={athlete.sport}
                                                                    country={athlete.country}
                                                                    photos={athlete.photos}
                                                                    imageHasBorderRadius={
                                                                        !ovalImages
                                                                    }
                                                                />
                                                            </RevealElement>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </Swiper>
                                        )}
                                        <RevealElement>
                                            <div className={cx(styles.scrollbar)}>
                                                <div
                                                    ref={swiperScrollBarRef}
                                                    className={cx(styles.scrollbarItem)}
                                                />
                                            </div>
                                        </RevealElement>
                                    </div>
                                ) : (
                                    <div className={cx(styles.athletes)}>
                                        {filteredAthletes?.map((athlete, index) => (
                                            <div key={athlete.id} className={cx(styles.athlete)}>
                                                <RevealElement
                                                    delay={
                                                        getViewportSize() <= 800
                                                            ? 0
                                                            : Number(`0.${2 * index}`)
                                                    }
                                                >
                                                    <Athlete
                                                        addTp
                                                        align="center"
                                                        lazy={false}
                                                        link={athlete.link}
                                                        title={athlete.title}
                                                        sport={athlete.sport}
                                                        country={athlete.country}
                                                        photos={athlete.photos}
                                                        imageHasBorderRadius={!ovalImages}
                                                    />
                                                </RevealElement>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </Container>
            </Section>
        );
    }
);
