import React, { createRef, useEffect, useState } from 'react';
import cx from 'classnames';
import SwiperCore, { A11y, Scrollbar, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LocalizedPageCollection } from 'context/LocalizedPageCollection';
import { LocalizedUrl } from 'context/LocalizedUrl';
import { LocalizedButton } from 'context/LocalizedButton';
import TranslationKey from 'context/TranslationKey';
import { getViewportSize } from 'utils/getViewportSize';
import { getImageSize } from 'utils/getImageSize';
import { imagesLoaded } from 'utils/imagesLoaded';
import Section from 'components/Layout/Section';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import RevealElement from 'components/Layout/RevealElement';
import Button from 'components/common/Button';
import UnderlineLink from 'components/common/UnderlineLink';
import ParallaxElement from 'components/Layout/ParallaxElement';
import styles from './FeaturedProducts.module.scss';

SwiperCore.use([Scrollbar, A11y]);

export type BackgroundColor = 'white' | 'beige';

type Props = any;

export const FeaturedProductsModule = React.forwardRef<HTMLDivElement, Props>(
    ({ layout, title, products, button, learnMore }, ref) => {
        const { background, topWaveColor } = layout;
        const productsLength = products?.length || 0;
        const itemSize = productsLength === 2 || productsLength === 4 ? 'big' : 'small';
        const swiperScrollBarRef = createRef<HTMLDivElement>();
        const [sliderParams, setSliderParams] = useState<SwiperOptions>();

        useEffect(() => {
            if (swiperScrollBarRef?.current && !sliderParams) {
                setSliderParams({
                    loop: false,
                    slidesPerView: 1,
                    longSwipes: false,
                    watchSlidesProgress: true,
                    watchSlidesVisibility: true,
                    scrollbar: {
                        el: swiperScrollBarRef.current || '',
                        hide: false,
                        draggable: true,
                        snapOnRelease: true,
                    },
                });
            }
        }, [swiperScrollBarRef, sliderParams]);

        return (
            <LocalizedPageCollection
                records={products}
                render={filteredRecords =>
                    filteredRecords?.length > 0 && (
                        <Section
                            alignment=""
                            bgColor=""
                            ref={ref}
                            className={cx(
                                styles.section,
                                styles[`section${background as BackgroundColor}`]
                            )}
                        >
                            {title && (
                                <div
                                    className={cx(
                                        styles.sectionTitleWrapper,
                                        styles[
                                            `sectionTitleWrapper${topWaveColor as BackgroundColor}`
                                        ]
                                    )}
                                >
                                    <RevealElement>
                                        <h2 className={cx('u-a5', styles.sectionTitle)}>{title}</h2>
                                    </RevealElement>
                                </div>
                            )}
                            <div className={cx(styles.sectionInner)}>
                                <div
                                    className={cx(
                                        styles.topwave,
                                        styles[`topwave${topWaveColor as TopWaveColors}`]
                                    )}
                                >
                                    <div className={cx(styles.topwaveshape)}>
                                        <TopWave color={topWaveColor as TopWaveColors} />
                                    </div>
                                </div>
                                <div className={cx(styles.row, styles[`row--${itemSize}`])}>
                                    {getViewportSize() <= 800 ? (
                                        <>
                                            {sliderParams && (
                                                <Swiper {...sliderParams}>
                                                    {filteredRecords.map((product, index) => {
                                                        const { image, excerpt, featuredEyebrow } =
                                                            product.productGroup;
                                                        return (
                                                            <SwiperSlide key={index}>
                                                                <div
                                                                    key={`featured_item_${product.id}`}
                                                                    className={cx(
                                                                        styles.column,
                                                                        styles[
                                                                            `column--${itemSize}`
                                                                        ]
                                                                    )}
                                                                >
                                                                    <div
                                                                        className={cx(styles.item)}
                                                                    >
                                                                        {image?.sourceUrl && (
                                                                            <RevealElement>
                                                                                <div
                                                                                    className={cx(
                                                                                        styles.figure
                                                                                    )}
                                                                                >
                                                                                    <img
                                                                                        src={getImageSize(
                                                                                            image.sourceUrl,
                                                                                            image
                                                                                                .mediaDetails
                                                                                                .sizes,
                                                                                            'large'
                                                                                        )}
                                                                                        alt={
                                                                                            image.altText ||
                                                                                            product
                                                                                                .options
                                                                                                .title
                                                                                        }
                                                                                        onLoad={
                                                                                            imagesLoaded
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </RevealElement>
                                                                        )}
                                                                        {featuredEyebrow && (
                                                                            <RevealElement>
                                                                                <h3
                                                                                    className={cx(
                                                                                        styles.eyebrow,
                                                                                        'u-b2'
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        featuredEyebrow
                                                                                    }
                                                                                </h3>
                                                                            </RevealElement>
                                                                        )}
                                                                        <RevealElement>
                                                                            <h2
                                                                                className={cx(
                                                                                    styles.title,
                                                                                    'u-a3'
                                                                                )}
                                                                            >
                                                                                {
                                                                                    product.options
                                                                                        .title
                                                                                }
                                                                            </h2>
                                                                        </RevealElement>
                                                                        {excerpt && (
                                                                            <RevealElement>
                                                                                <p
                                                                                    className={cx(
                                                                                        styles.text,
                                                                                        'u-b1'
                                                                                    )}
                                                                                >
                                                                                    {excerpt}
                                                                                </p>
                                                                            </RevealElement>
                                                                        )}
                                                                        {learnMore && (
                                                                            <RevealElement>
                                                                                <div
                                                                                    className={cx(
                                                                                        styles.link,
                                                                                        'u-b1 u-fw-500'
                                                                                    )}
                                                                                >
                                                                                    <LocalizedUrl
                                                                                        countries={
                                                                                            product
                                                                                                .options
                                                                                                .countries
                                                                                        }
                                                                                        render={url => (
                                                                                            <UnderlineLink
                                                                                                tpLink="internal"
                                                                                                to={
                                                                                                    url
                                                                                                }
                                                                                            >
                                                                                                <TranslationKey name="learnMore" />
                                                                                            </UnderlineLink>
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                            </RevealElement>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                        );
                                                    })}
                                                </Swiper>
                                            )}
                                            <div className={cx(styles.scrollbar)}>
                                                <div
                                                    ref={swiperScrollBarRef}
                                                    className={cx(styles.scrollbarItem, {
                                                        [styles.scrollbarItemInverted]:
                                                            background === 'white',
                                                    })}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        filteredRecords.map((product, index) => {
                                            const { image, excerpt, featuredEyebrow } =
                                                product.productGroup;
                                            return (
                                                <div
                                                    key={`featured_item_${product.id}`}
                                                    className={cx(
                                                        styles.column,
                                                        styles[`column--${itemSize}`]
                                                    )}
                                                >
                                                    <RevealElement
                                                        delay={
                                                            getViewportSize() <= 600
                                                                ? 0
                                                                : Number(`0.${2 * index}`)
                                                        }
                                                    >
                                                        <div className={cx(styles.item)}>
                                                            {image?.sourceUrl && (
                                                                <ParallaxElement
                                                                    percentage={-15 - Number(index)}
                                                                    className={cx(styles.figure)}
                                                                >
                                                                    <img
                                                                        src={getImageSize(
                                                                            image.sourceUrl,
                                                                            image.mediaDetails
                                                                                .sizes,
                                                                            'large'
                                                                        )}
                                                                        alt={
                                                                            image.altText ||
                                                                            product.options.title
                                                                        }
                                                                        onLoad={imagesLoaded}
                                                                    />
                                                                </ParallaxElement>
                                                            )}
                                                            {featuredEyebrow && (
                                                                <h3
                                                                    className={cx(
                                                                        styles.eyebrow,
                                                                        'u-b2'
                                                                    )}
                                                                >
                                                                    {featuredEyebrow}
                                                                </h3>
                                                            )}
                                                            <h2
                                                                className={cx(styles.title, 'u-a3')}
                                                            >
                                                                {product.options.title}
                                                            </h2>
                                                            {excerpt && (
                                                                <p
                                                                    className={cx(
                                                                        styles.text,
                                                                        'u-b1'
                                                                    )}
                                                                >
                                                                    {excerpt}
                                                                </p>
                                                            )}
                                                            {learnMore && (
                                                                <div
                                                                    className={cx(
                                                                        styles.link,
                                                                        'u-b1 u-fw-500'
                                                                    )}
                                                                >
                                                                    <LocalizedUrl
                                                                        countries={
                                                                            product.options
                                                                                .countries
                                                                        }
                                                                        render={url => (
                                                                            <UnderlineLink
                                                                                tpLink="internal"
                                                                                to={url}
                                                                            >
                                                                                <TranslationKey name="learnMore" />
                                                                            </UnderlineLink>
                                                                        )}
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    </RevealElement>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                                <LocalizedButton
                                    data={button}
                                    render={(btnLabel, btnUrl, btnTarget, btnType, dataTp) => (
                                        <div className={cx(styles.btn)}>
                                            <Button
                                                to={btnUrl}
                                                tpLink={dataTp}
                                                target={btnTarget}
                                                styleType="transparent"
                                                className="u-b0 u-fw-500"
                                            >
                                                {btnLabel}
                                            </Button>
                                        </div>
                                    )}
                                />
                            </div>
                        </Section>
                    )
                }
            />
        );
    }
);
