import React from 'react';
import cx from 'classnames';
import { LocalizedButton } from 'context/LocalizedButton';
import { getViewportSize } from 'utils/getViewportSize';
import { getImageSize } from 'utils/getImageSize';
import Section from 'components/Layout/Section';
import Container from 'components/Layout/Container';
import TopWave, { TopWaveColors } from 'components/Layout/TopWave';
import RevealElement from 'components/Layout/RevealElement';
import ParallaxElement from 'components/Layout/ParallaxElement';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './HorizontalNumberedFeatures.module.scss';

export type backgroundColors = 'white' | 'beige';

export type waveTopColors = 'white' | 'beige';

export type HorizontalNumberedFeaturesModuleProps = any;

export const HorizontalNumberedFeaturesModule = React.forwardRef<
    HTMLDivElement,
    HorizontalNumberedFeaturesModuleProps
>(({ layout, eyebrow, title, description, features, parallaxImages }, ref) => {
    const { left: imageLeft, right: imageRight } = parallaxImages;
    return (
        <Section ref={ref} alignment="" bgColor={layout.backgroundColor}>
            <div className={styles.sectionBackgrounds}>
                <ParallaxElement
                    percentage={-40}
                    className={cx(styles.sectionBackground, 'u-lazy js-lazy')}
                    data-bg={imageLeft}
                />
                <ParallaxElement
                    percentage={40}
                    className={cx(styles.sectionBackground, 'u-lazy js-lazy')}
                    data-bg={imageRight}
                />
            </div>
            <TopWave color={layout?.waveTopColor as TopWaveColors} />
            <Container>
                <div className={styles.horizontalNumberedFeaturesModule}>
                    <div className={styles.header}>
                        <RevealElement>
                            <p className={cx(styles.eyebrow, 'u-b2 u-uppercase')}>{eyebrow}</p>
                        </RevealElement>
                        <RevealElement>
                            <h2 className={cx(styles.title, 'u-a5 u-fw-700')}>{title}</h2>
                        </RevealElement>
                        <RevealElement>
                            <p className={cx(styles.description, 'u-b0')}>{description}</p>
                        </RevealElement>
                    </div>
                    <div className={styles.content}>
                        {features?.map((feature, index) => (
                            <div className={styles.feature} key={index}>
                                <RevealElement
                                    delay={getViewportSize() <= 600 ? 0 : Number(`0.${2 * index}`)}
                                >
                                    {feature?.logo?.sourceUrl && (
                                        <div
                                            className={cx(styles.featureLogo, 'u-lazy js-lazy')}
                                            data-bg={getImageSize(
                                                feature.logo.sourceUrl,
                                                feature?.logo?.mediaDetails?.sizes,
                                                'medium'
                                            )}
                                        />
                                    )}
                                    <div className={styles.featureContent}>
                                        <span className={cx(styles.featureNumber, 'u-b2')}>
                                            0{index + 1}
                                        </span>
                                        <h3 className={cx(styles.featureTitle, 'u-a3 u-fw-700')}>
                                            {feature.title}
                                        </h3>
                                        <div
                                            className="u-content-editor u-content-editor--module"
                                            dangerouslySetInnerHTML={{
                                                __html: feature.description,
                                            }}
                                        />
                                        <LocalizedButton
                                            data={feature.link}
                                            render={(
                                                btnLabel,
                                                btnUrl,
                                                btnTarget,
                                                btnType,
                                                dataTp
                                            ) => (
                                                <UnderlineLink
                                                    to={btnUrl}
                                                    target={btnTarget}
                                                    tpLink={dataTp}
                                                    className={cx(
                                                        styles.featureLink,
                                                        'u-b1 u-fw-500'
                                                    )}
                                                >
                                                    {btnLabel}
                                                </UnderlineLink>
                                            )}
                                        />
                                    </div>
                                </RevealElement>
                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </Section>
    );
});
