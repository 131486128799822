import React from 'react';
import cx from 'classnames';
import styles from './Spinner.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
}

const Spinner = React.forwardRef<HTMLDivElement, Props>(({ className, ...rest }, ref) => (
    <div ref={ref} className={cx(styles.spinner, className)} {...rest} />
));

export default Spinner;
