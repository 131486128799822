export enum GeneralModules {
    BenefitsModule = 'Page_Generalmodulesgroup_Modules_BenefitsModule',
    ClientListLogoModule = 'Page_Generalmodulesgroup_Modules_ClientListLogoModule',
    FeaturedArticlesModule = 'Page_Generalmodulesgroup_Modules_FeaturedArticlesModule',
    FeaturedProductsModule = 'Page_Generalmodulesgroup_Modules_FeaturedProductsModule',
    FeaturedProductsSliderModule = 'Page_Generalmodulesgroup_Modules_FeaturedProductsSliderModule',
    FeaturedTopicCtaModule = 'Page_Generalmodulesgroup_Modules_FeaturedTopicCtaModule',
    FullWidthMediaModule = 'Page_Generalmodulesgroup_Modules_FullWidthMediaModule',
    FullWidthMediaParallaxModule = 'Page_Generalmodulesgroup_Modules_FullWidthMediaParallaxModule',
    HorizontalOvalImageSliderModule = 'Page_Generalmodulesgroup_Modules_HorizontalOvalImageSliderModule',
    HorizontalSliderModule = 'Page_Generalmodulesgroup_Modules_HorizontalSliderModule',
    NextProductModule = 'Page_Generalmodulesgroup_Modules_NextProductModule',
    RelatedAthletesModule = 'Page_Generalmodulesgroup_Modules_RelatedAthletesModule',
    StatisticsModule = 'Page_Generalmodulesgroup_Modules_StatisticsModule',
    VerticalDoubleImageFeaturedModule = 'Page_Generalmodulesgroup_Modules_VerticalDoubleImageFeaturedModule',
    VerticalImageFeaturedModule = 'Page_Generalmodulesgroup_Modules_VerticalImageFeaturedModule',
    VerticalImageFeaturedParallaxModule = 'Page_Generalmodulesgroup_Modules_VerticalImageFeaturedParallaxModule',
    VerticalImageFeaturedSliderModule = 'Page_Generalmodulesgroup_Modules_VerticalImageFeaturedSliderModule',
    VerticalImageTestimonialDoubleSliderModule = 'Page_Generalmodulesgroup_Modules_VerticalImageTestimonialDoubleSliderModule',
    VerticalImageTestimonialSliderModule = 'Page_Generalmodulesgroup_Modules_VerticalImageTestimonialSliderModule',
    HorizontalNumberedFeaturesModule = 'Page_Generalmodulesgroup_Modules_HorizontalNumberedFeaturesModule',
    VerticalNumberedFeaturesModule = 'Page_Generalmodulesgroup_Modules_VerticalNumberedFeaturesModule',
    VideoComparisonModule = 'Page_Generalmodulesgroup_Modules_VideoComparisonModule',
    InstagramModule = 'Page_Generalmodulesgroup_Modules_InstagramModule',
    LegalDisclaimerModule = 'Page_Generalmodulesgroup_Modules_LegalDisclaimerModule',
}
