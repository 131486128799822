import React, { useMemo } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { AccordionGroup } from 'components/common/Accordion';
import DefaultPage from 'components/Layout/DefaultPage';
import LinkUniversal from 'components/LinkUniversal';
import UnderlineLink from 'components/common/UnderlineLink';
import styles from './FAQ.module.scss';

const FAQ = ({ options, faqPageContent }) => {
    const { excerpt, items, box } = faqPageContent;

    const accordionItems = useMemo(
        () =>
            items?.reduce(
                (acc, item) => [...acc, { title: item.question, description: item.answer }],
                []
            ),
        [items]
    );

    return (
        <DefaultPage title={options?.title} containerType="narrow">
            {excerpt && <p className={cx(styles.excerpt, 'u-a2')}>{excerpt}</p>}
            <div className={styles.cols}>
                {accordionItems?.length > 0 && (
                    <div className={styles.leftCol}>
                        <AccordionGroup
                            isMono={false}
                            content={accordionItems}
                            headerFont="u-a3 u-fw-bold"
                            panelFont="u-a2"
                        />
                    </div>
                )}
                {box && box?.link?.title && box?.link?.url && (
                    <div className={styles.rightCol}>
                        <div className={styles.box}>
                            <LinkUniversal
                                to={box.link.url}
                                className={styles.boxLink}
                                tpLink={box?.addTp ? 'external' : false}
                                target={box.link.target ? '_blank' : '_self'}
                            />
                            <h3
                                className={cx('u-a4', styles.boxTitle, {
                                    [styles.space]: box.textFirstLine || box.textSecondLine,
                                })}
                            >
                                {box.link.title}
                            </h3>
                            {box.textFirstLine && <p className="u-a2">{box.textFirstLine}</p>}
                            {box.textSecondLine && (
                                <UnderlineLink className={cx('u-b1 u-fw-500', styles.fakeLink)}>
                                    {box.textSecondLine}
                                </UnderlineLink>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </DefaultPage>
    );
};

FAQ.defaultProps = {
    options: {},
    faqPageContent: {},
};

FAQ.propTypes = {
    options: PropTypes.oneOfType([PropTypes.object]),
    faqPageContent: PropTypes.oneOfType([PropTypes.object]),
};

export default FAQ;
